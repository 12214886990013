import { Component } from '@angular/core';

@Component({
  selector: 'page-reset-password',
  templateUrl: './page-reset-password.component.html',
  styleUrl: './page-reset-password.component.less'
})
export class PageResetPasswordComponent {

}
