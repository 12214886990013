import { Component } from '@angular/core';

@Component({
  selector: 'page-maintenance',
  templateUrl: './page-maintenance.component.html',
  styleUrl: './page-maintenance.component.less'
})
export class PageMaintenanceComponent {

}
