import { Injector } from "@angular/core";
import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { cloneDeep, isBoolean, isEmpty } from "lodash";
import * as moment from 'moment';
import * as numeral from 'numeral';
import { filter, forkJoin, Observable, tap } from "rxjs";
import { PjAction, PjKeyValue, PjSelectionItem } from "src/component/component.type";
import { PjTableHeadConfig } from "src/component/pj-table/pj-table.type";
import { DataResourceOperation, DataResourceOperationType, DataResourceOperationTypeString } from "src/model/authentication.model";
import {
  BackendSearchOptionEnum, DataTypeEnum, FormatterType, FormatterTypeConst, FrontFieldMetaData, PjTabItemDataForMD, SearchTypeEnum
} from "src/model/front-field-metadata";
import { FrontModelMetaData } from "src/model/front-model-metadata";
import { EntityAction, FieldsRemovedForDetail, FieldsRemovedForForm, PersistenceEntity } from "src/model/persistence-entity";
import { ComponentConfigData, MetaDataComponentConfig } from "src/page/page-component.type";
import { DomainModelConstant } from "./app.config.service";
import { DomainModelServiceHttpImpl } from "./domainmodel.service.httpimpl";
import { HelperService } from "./helper.service";
import { StorageService } from "./storage.service";
import { PagingData } from "./httpcall.type";

// export interface PjTabItemDataForMD extends PjTabItemData {
//   title: any;
//   fieldName: string;
//   fieldSpecs: FrontFieldMetaData[];
// }

// 集中所有与元数据相关的静态工具方法
export class MetaDataHelper {

  private static _localStorageService = new StorageService();

  static TemplateNameForFieldValueShown = {
    DETAIL_TEXT: 'FIELD_TEXT',             // 用于显示一般的数据值
    DETAIL_LINK: 'FIELD_LINK',             // 用于显示数据类型是独立管理的领域模型的属性，单击该链接可以路由到该数据的详情页面
    DETAIL_LIST_LINK: 'FIELD_LIST_LINK',   // 用于显示数据类型是独立管理的领域模型数组的属性，单击该组件中的任意链接可以路由到被点击数据的详情页面
    DETAIL_SWITCH: 'FIELD_SWITCH',
    DETAIL_FILE: 'FIELD_FILE',             // 用于显示文件数据类型，单击下载或者新页面显示
    DETAIL_IMAGE: 'FIELD_IMAGE'            // 用于显示文件数据类型中的图片文件，单击放大或者新页面显示
  }

  static TemplateNameForFieldValueForm = {
    FORM_TEXT: 'FIELD_TEXT',
    FORM_BOOLEAN: 'FIELD_BOOLEAN',                      // 
    FORM_PASSWORD: 'FIELD_PASSWORD',
    FORM_RADIO_GROUP: 'FIELD_RADIO_GROUP',
    FORM_CHECKBOX_GROUP: 'FIELD_CHECKBOX_GROUP',
    FORM_SWITCH: 'FIELD_SWITCH',
    FORM_SELECTION: 'FIELD_SELECTION',
    FORM_MULTIPLE_SELECTION: 'FIELD_MUL_SELECTION',
    FORM_AUTOCOMPLETE: 'FIELD_AUTOCOMPLETE',
    FORM_DATE: 'FIELD_DATE',
    FORM_DATETIME: 'FORM_DATETIME',
  }

  static TemplateNameForSearchingFieldValueForm = {
    SEARCH_TEXT: 'FIELD_TEXT',
    SEARCH_BOOLEAN: 'FIELD_BOOLEAN',
    SEARCH_DATE: 'FIELD_DATE',
    SEARCH_DATE_RANGE: 'FIELD_DATE_RANGE',
    SEARCH_DATETIME_RANGE: 'FIELD_DATETIME_RANGE',
    SEARCH_NUMBER_RANGE: 'FIELD_NUMBER_RANGE',
    SEARCH_SELECTION: 'FIELD_SELECTION',
    SEARCH_MULTIPLE_SELECTION: 'FIELD_MUL_SELECTION',
    SEARCH_AUTOCOMPLETE: 'FIELD_AUTOCOMPLETE',
  }

  static getMetaDataAndAllModelData<T extends PersistenceEntity>(
    injector: Injector, cfgData: ComponentConfigData, modelName: string, criteria?: T
  ): Observable<{ modelMD: FrontFieldMetaData | null, modelList: T[] }> {
    cfgData.mdService = DomainModelServiceHttpImpl.generateDomainModelService(injector, modelName);
    let ob1$ = cfgData.mdService.getMetaData().pipe(
      filter(md => md != null),
      tap(md => cfgData.mdMetaData = md),
    );
    let ob2$ = cfgData.mdService.findAll(criteria);
    return forkJoin({ modelMD: ob1$, modelList: ob2$ });
  }

  static getMetaDataAndModelPageQueryData<T extends PersistenceEntity>(
    injector: Injector, cfgData: ComponentConfigData, modelName: string, criteria?: T
  ): Observable<{ modelMD: FrontFieldMetaData | null, pagingData: PagingData<T> }> {
    cfgData.mdService = DomainModelServiceHttpImpl.generateDomainModelService(injector, modelName);
    let ob1$ = cfgData.mdService.getMetaData().pipe(
      filter(md => md != null),
      tap(md => cfgData.mdMetaData = md),
    );
    let ob2$ = cfgData.mdService.pageQuery(criteria);
    return forkJoin({ modelMD: ob1$, pagingData: ob2$ });
  }

  static getMetaDataAndModelData<T extends PersistenceEntity>(
    injector: Injector, cfgData: ComponentConfigData, modelName: string, modelId: number
  ): Observable<{ modelMD: FrontFieldMetaData | null, modelData: T }> {
    cfgData.mdService = DomainModelServiceHttpImpl.generateDomainModelService(injector, modelName);
    let ob1$ = cfgData.mdService.getMetaData().pipe(
      filter(md => md != null),
      tap(md => cfgData.mdMetaData = md),
    );
    let ob2$ = cfgData.mdService.findById(modelId);
    return forkJoin({ modelMD: ob1$, modelData: ob2$ });
  }

  static generateMetaData(injector: Injector, cfgData: ComponentConfigData, modelName: string): Observable<FrontModelMetaData | null> {
    cfgData.mdService = DomainModelServiceHttpImpl.generateDomainModelService(injector, modelName);
    return cfgData.mdService.getMetaData().pipe(
      filter(md => md != null),
      tap(md => cfgData.mdMetaData = md),
    );
  }

  static generateMetaDataComponentConfigMap(
    cmpNames: object, mdCmpCfgMap: PjKeyValue<MetaDataComponentConfig>, modelMD: FrontModelMetaData, modelData?: PersistenceEntity
  ): PjKeyValue<MetaDataComponentConfig> {
    let _mdCmpCfgMap: PjKeyValue<MetaDataComponentConfig> = {};
    let data = cloneDeep(modelData);
    for (let compName in cmpNames) {
      const compValue = (cmpNames as any)[compName];
      _mdCmpCfgMap[compValue] = MetaDataHelper.generateMetaDataComponentConfig(
        compValue, modelMD, mdCmpCfgMap[compValue].fieldNameList, data
      );
    }
    return _mdCmpCfgMap;
  }

  private static generateMetaDataComponentConfig(
    compValue: string, modelMD: FrontModelMetaData, fieldNames: string[], modelData?: PersistenceEntity
  ): MetaDataComponentConfig {
    let mdComponentConfig: MetaDataComponentConfig = { fieldNameList: fieldNames };

    let fieldMD = MetaDataHelper.getFieldMetaDataListFromModelMD(modelMD, [compValue]);
    if (fieldMD != null && fieldMD.length == 1) {
      mdComponentConfig.fieldMD = fieldMD[0];
      if (!isEmpty(mdComponentConfig.fieldMD?.genericType)) {
        // 如果直接使用属性名称且是领域模型类型的属性，单独配置fieldMD, modelMD, 和 formControl
        mdComponentConfig.modelMD = MetaDataHelper.getModelMetaDataForField(fieldMD[0]);
        const fieldValue = (modelData != null ? (modelData as any)[mdComponentConfig.fieldMD.fieldName || ''] : undefined);
        mdComponentConfig.formControl = new FormControl(fieldValue);
      } else {
        if (!isEmpty(mdComponentConfig.fieldMD?.selectionClassName)) {
          mdComponentConfig.optionItems =
            MetaDataHelper.generateOptionListForField(mdComponentConfig.fieldMD!);
        }
      }
    }
    mdComponentConfig.fieldMetaDataList = MetaDataHelper.getFieldMetaDataListFromModelMD(
      modelMD, mdComponentConfig.fieldNameList
    ) || [];
    mdComponentConfig.formControlMap = MetaDataHelper.generateFormMapForModelUsingModelMD(
      modelMD, modelData, mdComponentConfig.fieldNameList
    ) || {};
    if (modelData != null) {
      mdComponentConfig.fieldStringValueMap = MetaDataHelper.generateStringValueForFieldsUnderModel(
        modelData, modelMD, mdComponentConfig.fieldNameList
      ) || {};
      mdComponentConfig.fieldValueMap = MetaDataHelper.generateValueMapForModel(
        modelData, modelMD, mdComponentConfig.fieldNameList
      ) || {};
    }
    if (mdComponentConfig.formControlMap != null && Object.keys(mdComponentConfig.formControlMap || {}).length > 0) {
      // 为了满足单个form control中是 object 的情况
      let value: any = {};
      for (let key in mdComponentConfig.formControlMap) {
        value[key] = mdComponentConfig.formControlMap[key].value;
      }
      mdComponentConfig.formControl = new FormControl(value);
    }
    return mdComponentConfig;
  }

  static getDomainCodeForModel(modelName?: string): string {
    let domainCode = 'main';
    const dmc: any = DomainModelConstant;
    if (DomainModelConstant != null && modelName != null) {
      let modelSimpleName = modelName.split('.').reverse()[0];
      if (dmc[modelSimpleName] != null) {
        domainCode = dmc[modelSimpleName].domainCode;
      }
    }
    return domainCode;
  }

  /**
   * 从模型元数据中找到所有需要的属性元数据
   * fieldNames的格式为： fieldName.subFieldName
  */
  static getFieldMetaDataListFromModelMD(modelMD: FrontModelMetaData, fieldNames?: string[]): FrontFieldMetaData[] {
    let fieldMDList: FrontFieldMetaData[] = [];
    modelMD.fieldSpecs?.forEach(fieldMD => {
      if (!isEmpty(fieldMD.fieldName)) {
        if (fieldNames == null || fieldNames.includes(fieldMD.fieldName!)) {
          fieldMDList.push(fieldMD);
        } else {
          if (!isEmpty(fieldMD.genericType)) {
            // 这个属性的数据类型是模型，需要获取该模型的元数据，同时循环它的属性，看看在不在需要的属性列表中
            let fieldName = fieldMD.fieldName;
            const subModelMD = MetaDataHelper.getModelMetaDataLocally(fieldMD.genericType);
            subModelMD?.fieldSpecs?.forEach(subFieldMD => {
              if (!isEmpty(subFieldMD.fieldName) && fieldNames.includes(fieldName + '.' + subFieldMD.fieldName!)) {
                subFieldMD.parentFieldName = fieldName;
                fieldMDList.push(subFieldMD);
              }
            });
          }
        }
      }
    });
    return fieldMDList;
  }

  static hasAdvanceSearch(modelMD: FrontModelMetaData): boolean {
    let hasSearchField: boolean = false;
    modelMD.fieldSpecs?.forEach(fmd => {
      if ((fmd.searchable || false) && fmd.searchType != null) {
        hasSearchField = true;
      }
    });
    return hasSearchField;
  }

  static generateSearchCriteria(fieldMDList: FrontFieldMetaData[], searchValue: any): PersistenceEntity {
    let cri: PersistenceEntity = {};
    if (isEmpty(fieldMDList) || isEmpty(searchValue)) {
      return cri;
    }
    fieldMDList.forEach(fmd => {
      if (fmd.fieldName != null && searchValue[fmd.fieldName] != null) {
        let fv = searchValue[fmd.fieldName];
        if (fmd.searchType == SearchTypeEnum.RANGE) {
          if (Array.isArray(fv) && fv.length == 2) {
            if (cri.rangeValues == null) {
              cri.rangeValues = [];
            }
            if (fmd.dataType == DataTypeEnum.date || fmd.dataType == DataTypeEnum.dateTime) {
              // FIXME: 暂时给后端的时间搜索参数用long
              cri.rangeValues.push({
                fieldName: fmd.fieldName,
                fromValue: ((new Date(fv[0])).getTime() + ''),
                toValue: ((new Date(fv[1])).getTime() + ''),
              });
            } else {
              cri.rangeValues.push({
                fieldName: fmd.fieldName,
                fromValue: fv[0],
                toValue: fv[1]
              });
            }
          }
        } else {
          if (fmd.selectionClassName != null) {
            if (fmd.searchOption == BackendSearchOptionEnum.IN) {
              fv = (fv as []).map(v => v[fmd.optionValueField!]);
            } else {
              fv = fv[fmd.optionValueField!];
            }
          }
          if (fmd.searchOption == BackendSearchOptionEnum.IN) {
            if (Array.isArray(fv) && fv.length > 0) {
              if (cri.enumValuesForSearching == null) {
                cri.enumValuesForSearching = [];
              }
              cri.enumValuesForSearching.push({
                fieldName: fmd.fieldName,
                values: fv.join(',')
              })
            }
          } else {
            if (typeof (fv) == 'string') {
              if (fv.length != 0) {
                cri = { ...cri, [fmd.fieldName]: fv };
              }
            } else {
              cri = { ...cri, [fmd.fieldName]: fv };
            }
          }
        }
      }
    });
    return cri;
  }
  static getFieldMetaDataListForSearching(modelMD: FrontModelMetaData): FrontFieldMetaData[] {
    let fieldMDList: FrontFieldMetaData[] = [];
    modelMD.fieldSpecs?.forEach(fmd => {
      if ((fmd.searchable || false) && fmd.searchType != null) {
        if (fmd.searchType == SearchTypeEnum.RANGE) {
          fmd.searchInputComponent = MetaDataHelper._generateRangeSearchComponentNameForFieldMetaData(fmd);
        } else {
          fmd.searchInputComponent = MetaDataHelper._generateValueSearchComponentNameForFieldMetaData(fmd);
        }
        fieldMDList.push(fmd);
      }
    });
    return fieldMDList;
  }

  private static _generateRangeSearchComponentNameForFieldMetaData(fmd: FrontFieldMetaData): string {
    if (fmd.dataType == DataTypeEnum.date) {
      return MetaDataHelper.TemplateNameForSearchingFieldValueForm.SEARCH_DATE_RANGE;
    } else if (fmd.dataType == DataTypeEnum.dateTime) {
      return MetaDataHelper.TemplateNameForSearchingFieldValueForm.SEARCH_DATETIME_RANGE;
    }
    return MetaDataHelper.TemplateNameForSearchingFieldValueForm.SEARCH_NUMBER_RANGE;
  }

  private static _generateValueSearchComponentNameForFieldMetaData(fmd: FrontFieldMetaData): string {
    if (fmd.dataType == DataTypeEnum.boolean) {
      return MetaDataHelper.TemplateNameForSearchingFieldValueForm.SEARCH_BOOLEAN;
    } else if (fmd.dataType == DataTypeEnum.date) {
      return MetaDataHelper.TemplateNameForSearchingFieldValueForm.SEARCH_DATE;
    } else {
      if (!isEmpty(fmd.selectionClassName)) {
        let optionList = MetaDataHelper._localStorageService.getSelectionDataByName(fmd.selectionClassName!);
        if (optionList != null) {
          if (fmd.searchOption == BackendSearchOptionEnum.EQUALS) {
            return MetaDataHelper.TemplateNameForSearchingFieldValueForm.SEARCH_SELECTION;
          } else {
            return MetaDataHelper.TemplateNameForSearchingFieldValueForm.SEARCH_MULTIPLE_SELECTION;
          }
        } else {
          return MetaDataHelper.TemplateNameForSearchingFieldValueForm.SEARCH_AUTOCOMPLETE;
        }
      }
    }
    return MetaDataHelper.TemplateNameForSearchingFieldValueForm.SEARCH_TEXT;
  }

  static generateSearchFormControlMapForFields(fieldMDList: FrontFieldMetaData[]): PjKeyValue<FormControl> | undefined {
    if (isEmpty(fieldMDList)) {
      return;
    }
    let fcMap: PjKeyValue<FormControl> = {};
    fieldMDList.forEach(fmd => {

    });
    return fcMap;
  }
  // 使用属性元数据，根据某些条件获取显示数据的组件
  // 参照 MetaDataHelper.TemplateNameForFieldValueShown 的说明
  static getTemplateNameForFieldValueShown(fieldMD: FrontFieldMetaData): string {
    let compName: string = MetaDataHelper.TemplateNameForFieldValueShown.DETAIL_TEXT;
    switch (fieldMD.dataType) {
      case DataTypeEnum.array:
        if (fieldMD.isManagedSeparently) {
          compName = MetaDataHelper.TemplateNameForFieldValueShown.DETAIL_LIST_LINK;
        }
        break;
      case DataTypeEnum.object:
        if (fieldMD.isManagedSeparently) {
          compName = MetaDataHelper.TemplateNameForFieldValueShown.DETAIL_LINK;
        }
        break;
      case DataTypeEnum.file:
        compName = MetaDataHelper.TemplateNameForFieldValueShown.DETAIL_FILE;
        break;
      case DataTypeEnum.image:
        compName = MetaDataHelper.TemplateNameForFieldValueShown.DETAIL_IMAGE;
        break;
    }
    return compName;
  }

  static generateAsyncOptionListForField(fieldMD: FrontFieldMetaData, entities?: PersistenceEntity[]): PjSelectionItem[] {
    let optionItems: PjSelectionItem[] = [];
    entities?.forEach(entity => {
      let option: PjSelectionItem = {
        label: MetaDataHelper._generateStringValueUsingFormatter(entity, fieldMD.formatter || '') || (entity as any)['name'] || 'No label',
        value: entity
      };
      optionItems.push(option);
    })
    return optionItems;
  }

  static generateOptionListForField(fieldMD: FrontFieldMetaData): PjSelectionItem[] {
    if (fieldMD == null || fieldMD.selectionClassName == null) {
      return [];
    }
    let optionList = MetaDataHelper._localStorageService.getSelectionDataByName(fieldMD.selectionClassName);
    return (optionList || []) as PjSelectionItem[];
  }

  // 使用属性元数据，根据某些条件获取编辑数据的组件
  // 参照 MetaDataHelper.TemplateNameForFieldValueForm 的说明
  // FIXME: inputComponent 如果有值，优先级最高，但是如果其值是前端没有实现的，继续使用缺省的方式获取输入组件
  static getTemplateNameForFieldValueForm(fieldMD: FrontFieldMetaData): string {
    let compName: string = MetaDataHelper.TemplateNameForFieldValueForm.FORM_TEXT;
    if (!isEmpty(fieldMD.selectionClassName)) {
      // 如果是选项数据，可能有两种情况：
      // 1. SelectionData的子类，使用 selectionClassName 到本地存储的 SELECTION_DATA 中能够找到
      // 2. FIXME: 不是SelectionData的子类，使用 selectionClassName 到本地存储的 SELECTION_DATA 中“不能”找到
      //    需要使用 selectionClassName 构造后端数据访问方法获取（应该使用自动完成异步获取数据的方式）
      let optionList = MetaDataHelper._localStorageService.getSelectionDataByName(fieldMD.selectionClassName!);
      if (optionList != null) {
        if (fieldMD.dataType == DataTypeEnum.array) {
          compName = MetaDataHelper.TemplateNameForFieldValueForm.FORM_MULTIPLE_SELECTION;
        } else {
          compName = MetaDataHelper.TemplateNameForFieldValueForm.FORM_SELECTION;
        }
      } else {
        compName = MetaDataHelper.TemplateNameForFieldValueForm.FORM_AUTOCOMPLETE;
      }
    }
    // if (!isEmpty(fieldMD.genericType)) {
    //   compName = MetaDataHelper.TemplateNameForFieldValueForm.FORM_AUTOCOMPLETE;
    // }
    if (compName != null) {
      switch (fieldMD.dataType) {
        case DataTypeEnum.boolean: compName = MetaDataHelper.TemplateNameForFieldValueForm.FORM_BOOLEAN; break;
        case DataTypeEnum.password: compName = MetaDataHelper.TemplateNameForFieldValueForm.FORM_PASSWORD; break;
        case DataTypeEnum.date: compName = MetaDataHelper.TemplateNameForFieldValueForm.FORM_DATE; break;
        case DataTypeEnum.dateTime: compName = MetaDataHelper.TemplateNameForFieldValueForm.FORM_DATETIME; break;
      }
    }
    return compName;
  }

  // 使用元数据，领域模型数据作为初始值，以及需要编辑的属性列表（fieldNames）作为参数
  // 生成以属性名（格式为：fieldName.fieldName）为键值的FormControl列表
  static generateFormMapForModelUsingModelMD(
    modelMD: FrontModelMetaData, modelValue: any, fieldNames: string[]
  ): PjKeyValue<FormControl> {
    let formCtlMap: PjKeyValue<FormControl> = {};

    modelMD?.fieldSpecs?.forEach(fieldMD => {
      if (!isEmpty(fieldMD.fieldName)) {
        if (fieldNames.includes(fieldMD.fieldName!)) {
          let fmCtl = MetaDataHelper.generateFormForField(fieldMD, modelValue != null ? modelValue[fieldMD.fieldName!] : undefined);
          formCtlMap[fieldMD.fieldName!] = fmCtl as FormControl;
        } else {
          if (!isEmpty(fieldMD.genericType)) {
            // 这个属性的数据类型是模型，需要获取该模型的元数据，同时循环它的属性，看看在不在需要的属性列表中
            let fieldName = fieldMD.fieldName + '.';
            const subModelMD = MetaDataHelper.getModelMetaDataLocally(fieldMD.genericType);
            subModelMD?.fieldSpecs?.forEach(subFieldMD => {
              if (!isEmpty(subFieldMD.fieldName) && fieldNames.includes(fieldName + subFieldMD.fieldName!)) {
                let fmCtl;
                if (modelValue != null && modelValue[fieldMD.fieldName!] != null) {
                  fmCtl = MetaDataHelper.generateFormForField(subFieldMD, modelValue[fieldMD.fieldName!][subFieldMD.fieldName!]);
                } else {
                  fmCtl = MetaDataHelper.generateFormForField(subFieldMD);
                }
                formCtlMap[fieldName + subFieldMD.fieldName!] = fmCtl as FormControl;
              }
            });
          }
        }
      }
    });
    return formCtlMap;
  }

  // 把form control map中的值转换为模型值
  // 该映射中的key是fieldName.fieldName形式的
  static getValueFromFormMap<T extends PersistenceEntity>(formCtlMap?: PjKeyValue<FormControl>): T {
    let modelValue: T = {} as T;
    if (formCtlMap == null) {
      return modelValue;
    }
    for (let fieldName in formCtlMap) {
      let dotPos = fieldName.indexOf('.');
      if (dotPos == -1) {
        (modelValue as any)[fieldName] = formCtlMap[fieldName]?.value;
      } else {
        let l1FieldName = fieldName.substring(0, dotPos);
        let l2FieldName = fieldName.substring(dotPos + 1);
        if ((modelValue as any)[l1FieldName] == null) {
          (modelValue as any)[l1FieldName] = {};
        }
        (modelValue as any)[l1FieldName][l2FieldName] = formCtlMap[fieldName]?.value;
      }
    }
    return modelValue;
  }
  static getValueFromFormControlWithMultipleField<T extends PersistenceEntity>(fc: FormControl<any>): T {
    let modelValue: T = {} as T;
    if (fc == null) {
      return modelValue;
    }
    const value = fc.value;
    for (let fieldName in value) {
      let dotPos = fieldName.indexOf('.');
      if (dotPos == -1) {
        (modelValue as any)[fieldName] = value[fieldName];
      } else {
        let l1FieldName = fieldName.substring(0, dotPos);
        let l2FieldName = fieldName.substring(dotPos + 1);
        if ((modelValue as any)[l1FieldName] == null) {
          (modelValue as any)[l1FieldName] = {};
        }
        (modelValue as any)[l1FieldName][l2FieldName] = value[fieldName];
      }
    }
    return modelValue;
  }
  // 使用元数据，领域模型数据作为初始值，以及需要编辑的属性列表（fieldNames）作为参数生成保存数据的FormGroup
  static generateFormGroupForModelUsingModelMD(
    modelMD: FrontModelMetaData, modelValue?: any, fieldNames?: string[]
  ): FormGroup {
    const formGroup: FormGroup = new FormGroup({});
    modelMD.fieldSpecs?.forEach(fieldMD => {
      if ((!fieldMD.formViewHidden || fieldMD.fieldName === 'id') && fieldMD.fieldName != null) {
        let willGenerateFieldForm: boolean = true;
        if (!isEmpty(fieldNames) && !fieldNames!.includes(fieldMD.fieldName)) {
          if (fieldMD.fieldName != 'id') {
            willGenerateFieldForm = false;
          }
        }
        if (isBoolean(fieldMD.readonly) && fieldMD.readonly) {
          willGenerateFieldForm = false;
        }
        if (willGenerateFieldForm) {
          const fieldValue = modelValue != null ? modelValue[fieldMD.fieldName] : undefined;
          const control = MetaDataHelper.generateFormForField(fieldMD, fieldValue);
          if (control !== undefined) {
            formGroup.addControl(fieldMD.fieldName!, control);
          }
        }
      }
    });
    return formGroup;
  }

  // 处理属性也是模型的情况下，生成FormGroup
  private static generateFormGroupForModelField(modelMD: FrontModelMetaData, modelValue?: any): FormGroup {
    const formGroup: FormGroup = new FormGroup({});
    modelMD.fieldSpecs?.forEach(fieldMD => {
      if ((!fieldMD.formViewHidden || fieldMD.fieldName === 'id') && fieldMD.fieldName != null) {
        const fieldValue = modelValue != null ? modelValue[fieldMD.fieldName] : undefined;
        // const control = MetaDataHelper.generateFormForField(fieldMD, fieldValue);
        let control: FormControl;
        if ((fieldMD.dataType == DataTypeEnum.object || fieldMD.dataType == DataTypeEnum.array)
          && !MetaDataHelper._isFieldShownOnTabPage(fieldMD)) {
          // 如果属性的属性还是模型，按照基础系统的限制，处理独立管理或者值来自独立管理的模型这两种情况
          // FIXME: 还没有处理数组的情况
          control = new FormControl();
          control.setValue(fieldValue);
          // control.setValue(fieldValue?.id);
        } else {
          control = MetaDataHelper.generateFormControlForNormalField(fieldMD, fieldValue);
        }
        if (control !== undefined) {
          formGroup.addControl(fieldMD.fieldName!, control);
        }
      }
    });
    return formGroup;
  }
  // FIXME: 使用属性元数据生成Form的基础逻辑：
  // 1. 基础数据生成FormControl：纯文本，文本值选项，格式化数字，格式化电话，邮件，真假值，。。。
  // 2. 对象数据生成FormGroup：独立管理的，非独立管理的，嵌入式的（货币，重量，尺寸）
  // 3. 数组数据生成FormArray：独立管理的，非独立管理的
  private static generateFormForField(fieldMD: FrontFieldMetaData, fieldValue?: any | any[]): FormControl | FormArray | FormGroup | void {
    let formControl: FormControl | FormArray | FormGroup | void;
    switch (fieldMD.dataType) {
      case DataTypeEnum.object:
        if (MetaDataHelper._isFieldShownOnTabPage(fieldMD)) {
          formControl = MetaDataHelper.generateFormGroupForObjectField(fieldMD, fieldValue);
        } else {
          // 如果是独立管理的领域模型属性，直接生成一个FormControl用于保存相应的value，并生成搜索选择框需要的label
          formControl = new FormControl();
          if (fieldValue != null) {
            fieldValue['autoLabel'] = MetaDataHelper.generateStringValueForFieldWithFieldValue(fieldValue, fieldMD);
          }
          formControl.setValue(fieldValue);
          // formControl.setValue(fieldValue?.id);
        }
        break;
      case DataTypeEnum.array: // 按照目前的设计，array的可能也有基础类型（目前只有String），然后在后端使用JPA的手段转换成字符串存储
        if (MetaDataHelper._isFieldShownOnTabPage(fieldMD)) {
          formControl = MetaDataHelper.generateFormArrayForArrayField(fieldMD, fieldValue);
        } else {

          if (fieldMD.genericType == null) {
            // 目前看没有genericType值的是基本类型
            formControl = new FormControl(fieldValue); // 创建的是值为数组的Form Control
          } else {
            // FIXME: 这地方应该也是不正确的，等到有相应的场景之后再修改。
            // 如果是独立管理的领域模型属性，需要生成一个 FormArray 用于保存相应的value
            let fcList: FormControl[] = [];
            fieldValue?.forEach((fv: any) => {
              let fc = new FormControl();
              if (fv != null) {
                fv['autoLabel'] = MetaDataHelper.generateStringValueForFieldWithFieldValue(fv, fieldMD);
              }
              fc.setValue(fv);
              fcList.push(fc);
            });
            formControl = new FormArray(fcList);
          }
        }
        break;
      default:
        formControl = MetaDataHelper.generateFormControlForNormalField(fieldMD, fieldValue);
    }
    return formControl;
  }

  private static generateFormControlForNormalField(fieldMD: FrontFieldMetaData, fieldValue?: any): FormControl {
    let fc: FormControl<any>;
    if (fieldMD.defaultValue != null || fieldValue != null) {
      switch (fieldMD.dataType) {
        case DataTypeEnum.boolean:
          fc = new FormControl<boolean>({
            value: isBoolean(fieldValue) ? fieldValue : isBoolean(fieldMD.defaultValue) ?? fieldMD.defaultValue,
            disabled: false
          }, MetaDataHelper.generateValidatorForField(fieldMD));
          break;
        case DataTypeEnum.date:
        case DataTypeEnum.dateTime:
          fc = new FormControl<Date>({
            value: new Date(fieldValue || fieldMD.defaultValue),
            disabled: false
          }, MetaDataHelper.generateValidatorForField(fieldMD));
          break;
        default:
          fc = new FormControl({
            value: fieldValue || fieldMD.defaultValue,
            disabled: false
          }, MetaDataHelper.generateValidatorForField(fieldMD));
      }
    } else {
      fc = new FormControl({
        value: fieldValue || fieldMD.defaultValue,
        disabled: false
      }, MetaDataHelper.generateValidatorForField(fieldMD));
    }
    return fc;
  }

  private static generateFormGroupForObjectField(fieldMD: FrontFieldMetaData, fieldValue?: any): FormGroup | undefined {
    const modelMD = MetaDataHelper.getModelMetaDataLocally(fieldMD.genericType);
    if (modelMD != null) {
      return MetaDataHelper.generateFormGroupForModelField(modelMD, fieldValue);
    }
    return;
  }

  private static generateFormArrayForArrayField(fieldMD: FrontFieldMetaData, fieldValues?: any[]): FormArray | undefined {
    const modelMD = MetaDataHelper.getModelMetaDataLocally(fieldMD.genericType);
    let formArray: FormArray<FormGroup> | undefined = undefined;
    if (modelMD != null) {
      if (fieldValues != null && Array.isArray(fieldValues)) {
        formArray = new FormArray<FormGroup>([], MetaDataHelper.generateValidatorForField(fieldMD));
        fieldValues.forEach(fv => {
          let fg = MetaDataHelper.generateFormGroupForModelField(modelMD, fv);
          if (fg != null) {
            formArray?.push(fg);
          }
        });
      } else {
        formArray = new FormArray([MetaDataHelper.generateFormGroupForModelField(modelMD)], MetaDataHelper.generateValidatorForField(fieldMD));
      }
    }
    return formArray;
  }

  private static generateValidatorForField(meta: FrontFieldMetaData): ValidatorFn[] {
    const validators: ValidatorFn[] = [];
    if (meta.required === true) {
      validators.push(Validators.required);
    }

    // FIXME: 需要根据数据属性来选择不同的 Validator
    if (meta.minVal != undefined) {
      validators.push(Validators.min(meta.minVal as unknown as number)); // 判断时间大小也必须用数字
    }

    if (meta.maxVal != undefined) {
      validators.push(Validators.max(meta.maxVal as unknown as number));
    }

    if (meta.minLength != undefined) {
      validators.push(Validators.minLength(meta.minLength as number));
    }

    if (meta.maxLength != undefined) {
      validators.push(Validators.maxLength(meta.maxLength as number));
    }

    if (meta.pattern != undefined) {
      validators.push(Validators.pattern(meta.pattern));
    }

    // fileType 目前应该写在 文件组件内做判断
    if (meta.fileType != undefined) {
    }

    // FIXME? 问一下这个事情
    // if (meta.uniqueField === true) {
    //   validators.push(MetaDataHelper.generateUniqueFieldValidator(meta.fieldName!));
    // }

    return validators;
  }

  static getModelMetaDataLocally(modelCode?: string): FrontModelMetaData | undefined {
    if (!!isEmpty(modelCode)) {
      return;
    }
    return MetaDataHelper._localStorageService.getModelMeta(MetaDataHelper.getModelNameByGenericType(modelCode!, 's'));
  }

  static getModelMetaDataForField(fieldMD?: FrontFieldMetaData): FrontModelMetaData | undefined {
    return MetaDataHelper.getModelMetaDataLocally(fieldMD?.genericType);
  }

  private static getModelNameByGenericType(genericType: string, suffix?: string): string {
    if (genericType?.length) {
      let modelName = genericType.split('.').reverse()[0].toLowerCase();
      if (suffix != undefined) {
        modelName += suffix;
      }
      return modelName;
    }
    throw new Error('No genericType');
  }

  private static generateUniqueFieldValidator(fieldName: string): ValidatorFn {
    const validator: ValidatorFn = (
      control: AbstractControl,
    ): ValidationErrors | null => {
      const rowsForm = control.value;
      let datas = control?.parent;
      while (datas != undefined && !(datas instanceof FormArray)) {
        datas = control?.parent;
      }
      if (datas == undefined) {
        return { uniqueField: "上级没有数组, 无法判断数据是否重复..." };
      } else if (Array.isArray(datas?.value)) {
        for (const row of datas?.value) {
          if (row[fieldName] === rowsForm) {
            return { uniqueField: "该数据已重复..." };
          }
        }
      }
      return null;
    };
    return validator;
  }

  static generateTabItemsDataForDetail(modelMD: FrontModelMetaData, fieldNames?: string[]): PjTabItemDataForMD[] {
    return MetaDataHelper.generateTabItemsData(modelMD, true, fieldNames);
  }

  static generateTabItemsDataForForm(modelMD: FrontModelMetaData, fieldNames?: string[]): PjTabItemDataForMD[] {
    return MetaDataHelper.generateTabItemsData(modelMD, false, fieldNames);
  }

  // FIXME: 本文件中有一些需要国际化的文本文字
  private static generateTabItemsData(modelMD: FrontModelMetaData, forDetail: boolean, fieldNames?: string[]): PjTabItemDataForMD[] {
    // value中保存该tab所有需要显示的fieldmetadata
    let _tabItemDataMap: PjKeyValue<PjTabItemDataForMD> = {
      'Base Info': {
        title: 'Base Info', isActived: true, fieldName: '', fieldSpecs: []
      }
    };
    modelMD.fieldSpecs?.forEach(fieldSpec => {
      // 数组和对象类型的属性，如果不是独立管理的，也不是嵌入式数据
      if (MetaDataHelper._isFieldShownOnTabPage(fieldSpec)) {
        let tabItem = MetaDataHelper._generatePjTabItemDataFromFieldMetaData(fieldSpec, forDetail, fieldNames);
        _tabItemDataMap[tabItem.title] = tabItem;
      } else {
        if (MetaDataHelper._isRequiredField(fieldSpec, forDetail, fieldNames)) {
          _tabItemDataMap['Base Info'].fieldSpecs.push(fieldSpec);
        }
      }
    });
    let _tabItemDataList: PjTabItemDataForMD[] = [];
    for (let key in _tabItemDataMap) {
      _tabItemDataList.push(_tabItemDataMap[key])
    }
    return _tabItemDataList;
  }

  private static _isRequiredField(fieldMD: FrontFieldMetaData, forDetail: boolean, fieldNames?: string[]): boolean {
    let persistenceEntityFields = forDetail ? FieldsRemovedForDetail : FieldsRemovedForForm;
    let requiredField: boolean = false;
    if (!isEmpty(fieldNames)) {
      if (fieldMD.fieldName != null) {
        requiredField = fieldNames!.includes(fieldMD.fieldName);
      }
    } else {
      if (fieldMD.fieldName != null) {
        requiredField = !persistenceEntityFields.includes(fieldMD.fieldName);
        if (isBoolean(fieldMD.readonly) && fieldMD.readonly && !forDetail) {
          requiredField = false;
        }
        if (forDetail && fieldMD.detailViewHidden) {
          requiredField = false;
        }
        if (!forDetail && fieldMD.formViewHidden) {
          requiredField = false;
        }
      }
    }
    return requiredField;
  }

  private static _generatePjTabItemDataFromFieldMetaData(
    fieldMD: FrontFieldMetaData, forDetail: boolean, fieldNames?: string[]
  ): PjTabItemDataForMD {
    // 如果是一个需要显示在tab页的属性，应该是有子FrontFieldMetaData的
    let subFieldSpecs: FrontFieldMetaData[] = [];
    let modelMD: FrontModelMetaData | undefined = undefined;
    if (fieldMD.genericType != null) {
      modelMD = MetaDataHelper.getModelMetaDataLocally(fieldMD.genericType);
      if (modelMD != null && modelMD.fieldSpecs != null) {
        modelMD.fieldSpecs.forEach(fieldSpec => {
          if (MetaDataHelper._isRequiredField(fieldSpec, forDetail, fieldNames)) {
            subFieldSpecs.push(fieldSpec);
          }
        });
        // subFieldSpecs = modelMD?.fieldSpecs;
      }
    }
    let tabItem: PjTabItemDataForMD = {
      title: fieldMD.label || '',
      contentTemplateName: fieldMD.fieldName!,
      isActived: false,
      fieldName: fieldMD.fieldName!,
      fieldMD: fieldMD,
      modelMD: modelMD,
      fieldSpecs: subFieldSpecs
    };
    return tabItem;
  }

  // 判断某个属性是不是需要单独显示在tab页面上。
  private static _isFieldShownOnTabPage(fieldSpec: FrontFieldMetaData): boolean {
    if (fieldSpec.dataType != 'array' && fieldSpec.dataType != 'object') {
      return false;
    }
    if (isBoolean(fieldSpec.isManagedSeparently) && fieldSpec.isManagedSeparently) {
      return false;
    }
    if (fieldSpec.dataType == 'object' && isBoolean(fieldSpec.embbedable) && fieldSpec.embbedable) {
      return false;
    }
    if (!isEmpty(fieldSpec.selectionClassName)) {
      // 该属性的值来自某模型（比如收货地址来自地址本），是使用值拷贝
      return false;
    }
    if (fieldSpec.genericType == null || fieldSpec.genericType.length <= 0) {
      return false;
    }
    return true;
  }

  // 使用模型元数据中获取该模型实例可以有的操作
  // operationsRequired：使用者需要的领域模型实例操作
  static generateActionListForInstance(modelMD: FrontModelMetaData, operationsRequired?: DataResourceOperation[]): PjAction[] {
    return MetaDataHelper._generateActionList(modelMD,
      DataResourceOperationTypeString.INSTANCE as DataResourceOperationType, operationsRequired);
  }

  // 使用模型元数据中获取该模型类级别可以有的操作
  // operationsRequired：使用者需要的领域模型类操作
  static generateActionListForClass(modelMD: FrontModelMetaData, operationsRequired?: DataResourceOperation[]): PjAction[] {
    return MetaDataHelper._generateActionList(modelMD,
      DataResourceOperationTypeString.CLASS as DataResourceOperationType, operationsRequired);
  }

  private static _generateActionList(
    modelMD: FrontModelMetaData, operationType: DataResourceOperationType,
    operationsRequired?: DataResourceOperation[]
  ): PjAction[] {
    let actionList: PjAction[] = [];
    // NOTE: 领域元数据中的instanceOperators和classOperators保存的是后端Controller中的方法名，
    //       至少包括EntityController中的class/instance操作需要的方法，
    //       也会包括领域模型自定义的Controller中的class/instance需要的方法
    // 1. 如果该操作是系统预定义的操作，使用在（persistence-entity.ts）中预定义的 EntityAction
    // 2. 不是系统预定义的领域模型操作，按照如下规则生成PjAction
    //    label => operationName（如果没有，使用apiCode的方法名）
    //    iconName => iconName（如果没有，就空着）
    //    actionString => 'action-'+apiCode的方法名
    let opReqList = operationsRequired?.filter(
      op => op.operationType == null || op.operationType == (operationType as string)
    );
    let modelPredifinedOps: string[] | undefined = undefined;
    if (operationType == DataResourceOperationTypeString.INSTANCE) {
      modelPredifinedOps = modelMD.instanceOperators;
    } else if (operationType == DataResourceOperationTypeString.CLASS) {
      modelPredifinedOps = modelMD.classOperators;
    }
    modelPredifinedOps?.forEach(operation => {
      let opNeeded: boolean = false;
      let op = opReqList?.find(op => {
        let pos = op.apiCode?.lastIndexOf('.') || -1;
        let requiredOp = op.apiCode?.substring(pos == -1 ? 0 : (pos + 1));
        return requiredOp == operation;
      });
      if (opReqList == null || op != null) {
        opNeeded = true;
      }
      if (opNeeded) {
        let action: PjAction | undefined = MetaDataHelper._fromOperationStringToAction(operation);
        if (action == null) {
          if (op != null && !Object.keys(BackendEntityOperation).includes(operation)) {
            action = { actionString: `action-${operation}`, label: op.operationName || '', iconName: op.iconName || '' };
            actionList.push(action);
          }
        } else {
          actionList.push(action);
        }
      }
    });
    return actionList;
  }
  static generateTableHeadsConfigByModelMD(modelMD: FrontModelMetaData, fieldNames?: string[]): PjTableHeadConfig[] {
    let _headConfigs: PjTableHeadConfig[] = [];
    if (modelMD.fieldSpecs == null) {
      return [];
    }
    // TODO: 目前只是配置了领域模型的第一层属性的表格显示！
    if (HelperService.arrayIsEmpty(fieldNames)) {
      modelMD.fieldSpecs?.forEach(fieldMD => {
        const _fieldName = fieldMD.fieldName!;
        if (isEmpty(fieldNames) || fieldNames?.includes(_fieldName)) {
          let headCfg = MetaDataHelper.generateTableHeadConfigByFieldMD(fieldMD);
          if (headCfg != null) {
            _headConfigs.push(headCfg);
          }
        }
      });
    } else {
      let fieldMDList = MetaDataHelper.getFieldMetaDataListFromModelMD(modelMD, fieldNames);
      fieldMDList.forEach(fieldMD => {
        let headCfg = MetaDataHelper.generateTableHeadConfigByFieldMD(fieldMD);
        if (headCfg != null) {
          _headConfigs.push(headCfg);
        }
      });
    }
    return _headConfigs;
  }

  private static _tblColCfg4Fld: any = {};
  static setTableColumnConfigForFieldData(tableColCfg: object): void {
    MetaDataHelper._tblColCfg4Fld = { ...MetaDataHelper._tblColCfg4Fld, ...tableColCfg };
  }

  static generateTableHeadConfigByFieldMD(fieldMD: FrontFieldMetaData): PjTableHeadConfig | undefined {
    let _headCfg: PjTableHeadConfig | undefined = undefined;
    if (fieldMD.tableViewHidden != null && fieldMD.tableViewHidden) {
      return _headCfg;
    }
    _headCfg = new PjTableHeadConfig();
    _headCfg.header = fieldMD.header || fieldMD.label;
    _headCfg.fieldName = fieldMD.fieldName || '';
    if (
      fieldMD.fieldName != null && fieldMD.modelName != null
      && MetaDataHelper._tblColCfg4Fld[fieldMD.modelName] != null
      && MetaDataHelper._tblColCfg4Fld[fieldMD.modelName][fieldMD.fieldName]
    ) {
      if (MetaDataHelper._tblColCfg4Fld[fieldMD.modelName][fieldMD.fieldName].width != null) {
        _headCfg.width = MetaDataHelper._tblColCfg4Fld[fieldMD.modelName][fieldMD.fieldName].width + 'px';
      }
      if (MetaDataHelper._tblColCfg4Fld[fieldMD.modelName][fieldMD.fieldName].align != null) {
        _headCfg.colAlign = MetaDataHelper._tblColCfg4Fld[fieldMD.modelName][fieldMD.fieldName].align;
      }
    }
    return _headCfg;
  }

  // 根据属性名称（按照fieldName.fieldName组织的）组织模型的属性值映射（field value map）
  static generateValueMapForModel(modelData: object, modelMD: FrontModelMetaData, fieldNames?: string[]): PjKeyValue<any> {
    let valueMap: PjKeyValue<any> = {};
    modelMD.fieldSpecs?.forEach(fieldMD => {
      if (!isEmpty(fieldMD.fieldName)) {
        if (fieldNames == null || fieldNames.includes(fieldMD.fieldName!)) {
          valueMap[fieldMD.fieldName!] = (modelData as any)[fieldMD.fieldName!];
        } else {
          if (!isEmpty(fieldMD.genericType)) {
            // 这个属性的数据类型是模型，需要获取该模型的元数据，同时循环它的属性，看看在不在需要的属性列表中
            let fieldName = fieldMD.fieldName + '.';
            const subModelMD = MetaDataHelper.getModelMetaDataLocally(fieldMD.genericType);
            subModelMD?.fieldSpecs?.forEach(subFieldMD => {
              if ((modelData as any)[fieldMD.fieldName!] == null) {
                (modelData as any)[fieldMD.fieldName!] = {};
              }
              if (!isEmpty(subFieldMD.fieldName) && fieldNames.includes(fieldName + subFieldMD.fieldName!)) {
                valueMap[fieldName + subFieldMD.fieldName!] = (modelData as any)[fieldMD.fieldName!][subFieldMD.fieldName!];
              }
            });
          }
        }
      }
    });
    return valueMap;
  }

  // 获取模型所有属性对应的字符串值，
  // 如果属性是领域模型或者领域模型数组，要使用对应元数据的formatter属性生成属性的字符串值
  private static generateStringValueForModelUsingModelMD(modelData: object, modelMD: FrontModelMetaData): PjKeyValue<string> {
    let stringValueForFields: PjKeyValue<any> = {};
    modelMD.fieldSpecs?.filter(fieldMD => !isEmpty(fieldMD.fieldName)).forEach(fieldMD => {
      let sv = MetaDataHelper.generateStringValueForFieldWithFieldValue((modelData as any)[fieldMD.fieldName!], fieldMD);
      if (sv != null) {
        stringValueForFields[fieldMD.fieldName!] = sv;
      }
    });
    return stringValueForFields;
  }

  // 领域模型的值根据元数据转换成字符串的值
  // FIXME: 
  // 1. 对象属性使用对象的元数据格式化
  // 2. 数组的值：数组元素的值使用‘,’分隔
  // 需要使用某种处理方式，形成如下排序结果：
  // 1. 有值的放在前面，无值的放在后面
  // 2. 两部分再根据属性元数据中的position确定先后顺序
  // 解决方案：
  // 1. 此方法只返回有值属性的属性名与值的映射
  // 2. 显示的时候，先根据属性名与值的映射找出所有有值的属性元数据用position排序
  // 根据属性名称（按照fieldName.fieldName组织的）获取属性的字符串化的值
  // 返回值的内容为：
  // 一级属性为基础类型数据: 一级属性名：值
  // 一级属性为领域模型： 一级属性名：{二级属性名：值}
  // 一级属性为领域模型数组：一级属性名：{二级属性名：值}[]
  static generateStringValueForFieldsUnderModel(modelData: object, modelMD: FrontModelMetaData, fieldNames: string[]): PjKeyValue<any> {
    let stringValueForFields: PjKeyValue<any> = {};
    modelMD.fieldSpecs?.filter(fieldMD => !isEmpty(fieldMD.fieldName)).forEach(fieldMD => {
      // 按照 fieldNames 来获取领域模型第一级属性和第二级属性对应的字符串值
      let fieldName = fieldNames.find(fn => (fn == fieldMD.fieldName || fn.startsWith(fieldMD.fieldName + '.')));
      if (fieldName != null) {
        if (fieldName.includes('.')) {
          const subModelMD = MetaDataHelper.getModelMetaDataLocally(fieldMD.genericType);
          if (subModelMD != null) {
            let fns = fieldNames.filter(fn => fn.startsWith(fieldMD.fieldName + '.')).map(fn => fn.substring(fn.indexOf('.') + 1));
            if (fieldMD.dataType == DataTypeEnum.object) {
              if ((modelData as any)[fieldMD.fieldName!] != null) {
                stringValueForFields[fieldMD.fieldName!] =
                  MetaDataHelper.generateStringValueForFieldsUnderModel((modelData as any)[fieldMD.fieldName!], subModelMD, fns);
              }
            } else {
              let res: PjKeyValue<any>[] = [];
              let arrayData = (modelData as any)[fieldMD.fieldName!] as [];
              arrayData?.forEach(elmValue => {
                let v = MetaDataHelper.generateStringValueForFieldsUnderModel(elmValue, subModelMD, fns);
                res.push(v);
              });
              stringValueForFields[fieldMD.fieldName!] = res;
            }
          }
        } else {
          let sv = MetaDataHelper.generateStringValueForFieldWithFieldValue((modelData as any)[fieldMD.fieldName!], fieldMD);
          if (sv != null) {
            stringValueForFields[fieldMD.fieldName!] = sv;
          }
        }
      }
    });
    return stringValueForFields;
  }

  // 获取领域模型内某个属性的字符串值
  // 属性（参数fieldName）格式：fieldName<.subFieldName>
  static generateStringValueForFieldUnderModel(modelData: any, modelMD: FrontModelMetaData, fieldName: string): string {
    let v = '';
    let fmd = MetaDataHelper.getFieldMetaDataForFieldUnderModel(modelMD, fieldName);
    if (fmd == null) {
      return v;
    }
    let fieldValue: any;
    if (fieldName.includes('.')) {
      let fns = fieldName.split('.');
      if(modelData[fns[0]]!=null) {
        fieldValue = modelData[fns[0]][fns[1]];
      }
    } else {
      fieldValue = modelData[fieldName];
    }
    v = MetaDataHelper.generateStringValueForFieldWithFieldValue(fieldValue, fmd);
    return v;
  }

  // 获取领域模型内某个属性的属性元数据
  // 属性（参数fieldName）格式：fieldName<.subFieldName>
  static getFieldMetaDataForFieldUnderModel(modelMD: FrontModelMetaData, fieldName: string): FrontFieldMetaData | undefined {
    let fMD: FrontFieldMetaData | undefined = undefined;
    if (modelMD.fieldSpecs == null || fieldName == null || fieldName.length <= 0) {
      return;
    }
    for (let fieldMD of modelMD.fieldSpecs) {
      if (fieldMD.fieldName == fieldName) {
        fMD = fieldMD;
      } else {
        if (fieldName.startsWith((fieldMD.fieldName || '') + '.') && HelperService.hasStringValue(fieldMD.genericType)) {
          const subModelMD = MetaDataHelper.getModelMetaDataLocally(fieldMD.genericType);
          if (subModelMD != null && subModelMD.fieldSpecs != null) {
            for (let subFieldMD of subModelMD.fieldSpecs) {
              let subFieldName = (fieldMD.fieldName || '') + '.' + subFieldMD.fieldName;
              if (subFieldName == fieldName) {
                fMD = subFieldMD;
              }
            }
          }
        }
      }
    }
    return fMD;
  }
  // 使用 generateStringValueForFieldsUnderModel 生成的数据结构再生成表格需要的数据
  static generateRowsForFieldsUnderModel(
    modelData: object, modelMD: FrontModelMetaData, fieldNames: string[], fieldMDList: FrontFieldMetaData[]
  ): any[] {
    let _fieldMetaDataList: FrontFieldMetaData[];
    if (fieldMDList == null) {
      _fieldMetaDataList = MetaDataHelper.getFieldMetaDataListFromModelMD(modelMD, fieldNames);
    } else {
      _fieldMetaDataList = cloneDeep(fieldMDList);
    }
    let sv = MetaDataHelper.generateStringValueForFieldsUnderModel(modelData, modelMD, fieldNames);
    let arrayData: any[] | undefined = undefined;
    for (let field in sv) {
      if (Array.isArray(sv[field])) {
        arrayData = sv[field];
      }
    }
    let _rows: any[] = [];
    if (arrayData != null) {
      arrayData.forEach(data => {
        let row: any = {};
        _fieldMetaDataList!.forEach(fmd => {
          let fieldName = fieldNames?.find(fn => (fn == fmd.fieldName || fn.endsWith('.' + fmd.fieldName)));
          if (fieldName?.includes('.')) {
            let firstLevelFieldName = fieldName.substring(0, fieldName.indexOf('.'));
            if (Array.isArray(sv[firstLevelFieldName])) {
              row[fmd.fieldName!] = data[fmd.fieldName!];
            } else {
              row[fmd.fieldName!] = sv[firstLevelFieldName][fmd.fieldName!];
            }
          } else {
            row[fmd.fieldName!] = sv[fmd.fieldName!];
          }
        });
        _rows?.push(row);
      })
    } else {
      let row: any = {};
      _fieldMetaDataList.forEach(fmd => {
        let fieldName = fieldNames?.find(fn => (fn == fmd.fieldName || fn.endsWith('.' + fmd.fieldName)));
        if (fieldName?.includes('.')) {
          if (sv[fieldName.substring(0, fieldName.indexOf('.'))] != null) {
            row[fmd.fieldName!] = sv[fieldName.substring(0, fieldName.indexOf('.'))][fmd.fieldName!];
          }
        } else {
          row[fmd.fieldName!] = sv[fmd.fieldName!];
        }
      });
      _rows = [row];
    }
    return _rows;
  }

  // 使用元数据生成领域模型的用字符串表示的详细信息
  static generateStringValueForModelDetail(modelData: object, modelMD: FrontModelMetaData): PjKeyValue<any> {
    let stringValueForFields: PjKeyValue<any> = {};
    modelMD.fieldSpecs?.forEach(fieldMD => {
      let sv = MetaDataHelper.generateStringValueForFieldWithModelData(modelData, fieldMD);
      if (sv != null) {
        stringValueForFields[fieldMD.fieldName!] = sv;
      }
    })
    return stringValueForFields;
  }

  // 使用元数据，从领域模型中找到对应的属性，并返回该属性的字符串值
  private static generateStringValueForFieldWithModelData(
    modelData?: object, fieldMD?: FrontFieldMetaData
  ): string | PjKeyValue<any> | PjKeyValue<any>[] | undefined {
    if (fieldMD == null || !!isEmpty(fieldMD.fieldName)) {
      return;
    }
    if (modelData == null || (modelData as any)[fieldMD.fieldName!] == null) {
      return;
    }
    if (MetaDataHelper._isFieldShownOnTabPage(fieldMD)) {
      let subModelMD = MetaDataHelper.getModelMetaDataLocally(fieldMD.genericType);
      if (subModelMD != null) {
        if (fieldMD.dataType == DataTypeEnum.array) {
          let res: PjKeyValue<any>[] = [];
          let arrayData = (modelData as any)[fieldMD.fieldName!] as [];
          arrayData.forEach(elmValue => {
            let v = MetaDataHelper.generateStringValueForModelUsingModelMD(elmValue, subModelMD!);
            res.push(v);
          });
          return res;
        } else {
          return MetaDataHelper.generateStringValueForModelUsingModelMD((modelData as any)[fieldMD.fieldName!], subModelMD);
        }
      }
      return;
    }
    return MetaDataHelper.generateStringValueForFieldWithFieldValue((modelData as any)[fieldMD.fieldName!], fieldMD);
  }

  // FIXME: 如果传入的是非独立管理的数据类型是领域模型的属性元数据，怎么处理？
  // 解决方案：非独立管理的数据类型是领域模型的属性也需要有 formatter ？
  // FIXME: 需要使用属性元数据中的format格式化属性值
  // 如果属性还是领域模型，应该还需要从本地存储中读取相应的模型元数据，并使用该模型元数据中的format格式化属性值
  // formatter：
  // 1 formatter: 'pluck: ${name}-${label}' 表示值是对象，仅展示对象中的值替换`${}`
  // 2 formatter: 'mask: 8, *, @%, 10'  表示格式化文本前8个字符正常显示，中间的以*代替，遇到 @ 或者 % 则正常显示， 后 10 个字符正常显示
  // 3 formatter: 'datetime: YYYY-MM-DD' 表示格式化日期时间， 使用moment.js 
  // 4 formatter: 'number: 0,0' 表示格式化数字， 使用numeral.js
  static generateStringValueForFieldWithFieldValue(fieldValue: any, fieldMD: FrontFieldMetaData): string {
    let res: string | undefined = undefined;
    // FIXME: 还需要考虑属性是领域模型或者其他嵌入式对象的情况，需要使用模型元数据的formatter，
    if (!isEmpty(fieldMD.formatter)) {
      // 属性元数据优先
      res = MetaDataHelper._generateStringValueUsingFormatter(fieldValue, fieldMD.formatter!);
    } else if (!isEmpty(fieldMD.genericType)) {
      // 如果有基础类型，则使用模型元数据
      let subModelMD = MetaDataHelper.getModelMetaDataLocally(fieldMD.genericType);
      if (!isEmpty(subModelMD?.formatter)) {
        res = MetaDataHelper._generateStringValueUsingFormatter(fieldValue, subModelMD?.formatter!);
      }
    } else if (!isEmpty(fieldMD.selectionClassName)) {
      res = MetaDataHelper._generateStringValueFromSelectionData(fieldMD, fieldValue);
    }
    if (res == null) {
      switch (fieldMD.dataType as DataTypeEnum) {
        case DataTypeEnum.array: res = 'missing formatter.'; break;
        case DataTypeEnum.object: res = 'missing formatter.'; break;
        case DataTypeEnum.boolean: res = (fieldValue as boolean) ? '是' : '否'; break;
        case DataTypeEnum.dateTime: res = HelperService.formatDateTime(fieldValue as string, 'YYYY-MM-DD HH:mm:ss'); break;
        case DataTypeEnum.date: res = HelperService.formatDateTime(fieldValue as string, 'YYYY-MM-DD'); break;
        case DataTypeEnum.number: res = fieldValue as string; break;
        default: res = fieldValue as string; break;
      }
    }
    return res;
  }

  private static _generateStringValueFromSelectionData(fieldMD: FrontFieldMetaData, fieldValue: string): string {
    let optionItems = MetaDataHelper.generateOptionListForField(fieldMD);
    // FIXME: 对于枚举数据来自模型的属性，目前暂时直接使用属性，
    // 应该的处理模式是：当label和value是模型的不同属性时，需要访问该模型使用value值获取模型数据
    return optionItems.find(oi => oi.value == fieldValue)?.label || fieldValue;
  }

  private static _generateStringValueUsingFormatter(fieldValue: any, formatter: string): string | undefined {
    let res: string | undefined;
    let formatterParts: string[] = formatter?.split(':');
    if (formatterParts == null || formatterParts.length !== 2) {
      return res;
    }
    let formatterType = formatterParts[0] as FormatterType;
    let formatterContent: string = formatterParts[1];
    switch (formatterType) {
      case FormatterType.datetime:
        res = MetaDataHelper._generateDateTimeToFormattedString(fieldValue, formatterContent);
        break;
      case FormatterType.pluck:
        res = MetaDataHelper._generateValueToPluckString(fieldValue, formatterContent);
        break;
      case FormatterType.number:
        res = MetaDataHelper._generateNumberToFormattedString(fieldValue, formatterContent);
        break;
      case FormatterType.mask:
        res = MetaDataHelper._generateValueToMaskString(fieldValue, formatterContent);
        break;
    }
    return res;
  }

  // 3 formatter: 'datetime: YYYY-MM-DD' 表示格式化日期时间， 使用moment.js 
  private static _generateDateTimeToFormattedString(fieldValue: any, datetimeFormatter: string): string {
    if (moment(fieldValue).isValid()) {
      return moment(fieldValue).format(datetimeFormatter);
    }
    return JSON.stringify(fieldValue);
  }

  // 1 formatter: 'pluck: ${name}-${label}' 表示值是对象，仅展示对象中的值替换`${}`
  private static _generateValueToPluckString(fieldValue: any, pluckFormatter: string): string | undefined {
    let formatter: string | undefined = pluckFormatter;
    if (!!isEmpty(formatter)) {
      return undefined
    }
    let fieldList = MetaDataHelper._getFieldsInPluck(formatter!);
    let result: string = new String(formatter) as string;
    if (Array.isArray(fieldValue)) {
      // 处理 fieldValue 是数组的情况
      let res: string[] = [];
      fieldValue.forEach(elmValue => {
        result = new String(formatter) as string;
        fieldList.forEach(fieldName => {
          let v = elmValue[fieldName] || '';
          result = result.replaceAll('\${' + fieldName + '}', v);
        });
        res.push(result);
      });
      return res.join('; '); // FIXME: 数组值的间隔符号是不是换成系统配置？
    } else {
      fieldList.forEach(fieldName => {
        let v = fieldValue != null ? (fieldValue as any)[fieldName] || '' : '';
        result = result.replaceAll('\${' + fieldName + '}', v);
      });
    }
    return result;
  }

  // 4 formatter: 'number: 0,0' 表示格式化数字， 使用numeral.js
  private static _generateNumberToFormattedString(fieldValue: any, numberFormatter: string): string {
    let a = numeral(fieldValue);
    if (a.value !== null) {
      return a.format(numberFormatter);
    }
    return JSON.stringify(fieldValue);
  }

  // 2 formatter: 'mask: 8, *, @%, 10'  表示格式化文本前8个字符正常显示，中间的以*代替，遇到 @ 或者 % 则正常显示， 后 10 个字符正常显示
  private static _generateValueToMaskString(fieldValue: any, maskFormatter: string): string | undefined {
    let maskParts: string[] = maskFormatter?.split(',');
    if (maskParts == null || maskParts.length !== 4) {
      return undefined;
    }
    let prefixLen: number = numeral(maskParts[0].trim()).value() || 1;
    let maskLetter: string = maskParts[1].trim()[0];
    let keptLetter: string = maskParts[2].trim();
    let suffixLen: number = numeral(maskParts[3].trim()).value() || 1;
    if ((typeof fieldValue) !== 'string') {
      return undefined;
    }
    let v: string = fieldValue as string;
    if (v.length <= 2) {
      return maskLetter;
    }
    if (v.length <= (prefixLen + suffixLen)) {
      prefixLen = 1;
      suffixLen = 1;
    }
    // FIXME: 暂时没有处理字符串值中有太多需要保留的字符这种情况
    let prefixString = v.substring(0, prefixLen);
    let suffixString = v.substring(v.length - suffixLen);
    v = v.substring(prefixLen, v.length - suffixLen);
    let res = maskLetter;
    let maskNeeded: boolean = false;
    for (let i = 0; i < v.length; i++) {
      if (keptLetter.includes(v.charAt(i))) {
        res = res + v.charAt(i);
        maskNeeded = true;
      } else {
        if (maskNeeded) {
          res = res + maskLetter;
          maskNeeded = false;
        }
      }
    }
    return prefixString + res + suffixString;
  }

  private static _isValidFormatter(formatter: string): boolean {
    let formatterParts: string[] = formatter?.split(':');
    if (formatterParts != null && formatterParts.length == 2) {
      return false;
    }
    if (!FormatterTypeConst.includes(formatterParts[0])) {
      // 格式化类型不在预定义类型中
      return false;
    }
    return true;
  }

  private static _getFieldsInPluck(pluckString: string): string[] {
    let tempArr = pluckString.split('${');
    let fieldList: string[] = [];
    for (let i = 0; i < tempArr.length; i++) {
      const element = tempArr[i];
      if (i > 0) {
        let arr = element.split('}');
        let fieldName = arr[0];
        fieldList.push(fieldName);
      }
    }
    return fieldList;
  }

  private static _fromOperationStringToAction(opString: string): PjAction | undefined {
    switch (opString) {
      case BackendEntityOperation.create: return EntityAction.CREATE;
      case BackendEntityOperation.update: return EntityAction.UPDATE;
      case BackendEntityOperation.delete: return EntityAction.DELETE;
      case BackendEntityOperation.deleteBatch: return EntityAction.DELETE_SELECTED;
      case BackendEntityOperation.getById: return EntityAction.VIEW;
      case BackendEntityOperation.downloadTemplate: return EntityAction.DOWNLOAD_TEMPLATE;
      case BackendEntityOperation.downloadAllData: return EntityAction.DOWNLOAD_ALL;
      case BackendEntityOperation.downloadSelectedData: return EntityAction.DOWNLOAD_SELECTED;
      case BackendEntityOperation.reassignedToNewOwner: return EntityAction.REASSIGN_OWNER;
      case BackendEntityOperation.uploadModel: return EntityAction.UPLOAD;
      case BackendEntityOperation.print: return EntityAction.PRINT;
      case BackendEntityOperation.printBatch: return EntityAction.PRINT_SELECTED;
      default: return undefined;
    }
  }
}

// 按照跟后端的约定， DataResourceOperation中的operationType有三种类型：
// ‘CLASS’： 可以出现在表格数据显示中的表头位置的操作
// ‘INSTANCE’，可以出现在表格数据显示中的每行数据位置的操作
// ‘NORMAL’，其他操作
// 以下是系统中领域模型缺省的操作
export enum BackendEntityOperation {
  'getModelMetadata' = 'getModelMetadata',         // NORMAL
  'create' = 'create',                             // CLASS     1
  'createBatch' = 'createBatch',                   // NORMAL
  'update' = 'update',                             // INSTANCE  1
  'updateBatch' = 'updateBatch',                   // NORMAL
  'delete' = 'delete',                             // INSTANCE  1
  'deleteById' = 'deleteById',                     // NORMAL  
  'deleteBatch' = 'deleteBatch',                   // CLASS     1
  'getById' = 'getById',                           // INSTANCE  1
  'listByIds' = 'listByIds',                       // NORMAL    
  'query' = 'query',                               // NORMAL
  'pageQuery' = 'pageQuery',                       // NORMAL
  'queryGroupBy' = 'queryGroupBy',                 // NORMAL
  'downloadTemplate' = 'downloadTemplate',         // CLASS     1
  'print' = 'print',                               // INSTANCE  1
  'printBatch' = 'printBatch',                     // CLASS     1
  'reassignedToNewOwner' = 'reassignedToNewOwner', // INSTANCE  1
  'downloadAllData' = 'downloadAllData',           // CLASS     1
  'downloadSelectedData' = 'downloadSelectedData', // CLASS     1
  'uploadModel' = 'uploadModel'                    // CLASS     1
}
