import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { NzButtonType } from 'ng-zorro-antd/button';
import { PjAction, PjSize } from '../component.type';
import { PjButtonType } from './pj-button.type';

@Component({ template: `` })
export abstract class PjButtonComponent {

  @Input()
  dropdownMenus: PjAction[] = [];

  @Input()
  size: PjSize = 'default';

  @Input()
  disabled: boolean = false;

  @Input()
  btnType: PjButtonType = 'outlined';

  @Output()
  actionClick: EventEmitter<PjAction> = new EventEmitter();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['btnType'] != null) {
      switch (this.btnType) {
        case 'filled': this._nzType = 'primary'; break;
        case 'outlined': this._nzType = 'default'; break;
        case 'text': this._nzType = 'text'; break;
        case 'link': this._nzType = 'link'; break;
      }
    }
  }

  getLabelClass(): string {
    return `${this.size}Label`;
  }
  getIconClass(): string {
    return `${this.size}IconSize`;
  }

  private _nzType: NzButtonType = 'default';
  getNzType(): NzButtonType {
    return this._nzType;
  }

  hasDropdownMenu(): boolean {
    return this.dropdownMenus.length > 0;
  }

  onDropdownMenuClick(action: PjAction): void {
    this.actionClick.emit(action);
  }

  onWithMenuBtnClick(mouseEvent: MouseEvent): void {
    mouseEvent.stopPropagation();
  }
}
