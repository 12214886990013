import { Component, Injector, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute, NavigationSkipped, Params, Router, Scroll } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subject, distinctUntilChanged, filter, map, takeUntil, throttleTime } from 'rxjs';
import { APPCONSTANT } from 'src/app/app.constant';
import { environment } from 'src/environments/environment';
import { CasUserDetail } from 'src/model/authentication.model';
import { AppConfigService } from 'src/service/app.config.service';
import { AuthService } from 'src/service/auth.service';
import { HelperService } from 'src/service/helper.service';
import { RoutingService } from 'src/service/routing.service';

@Component({ template: `` })
export abstract class PjPageComponent implements OnInit, OnDestroy {

  protected _translateService: TranslateService = inject(TranslateService); // 重构后改名回translateService
  protected _routeInfo: ActivatedRoute = inject(ActivatedRoute);
  protected _modalWinService: NzModalService = inject(NzModalService);
  protected _messageService: NzMessageService = inject(NzMessageService);
  protected _authService: AuthService = inject(AuthService);
  protected _routingService: RoutingService = inject(RoutingService);
  protected _injector: Injector = inject(Injector);
  private _router: Router = inject(Router);

  private _backUrl: string = '';
  private static _currentActivedPath: string = '';

  protected _unsubscribeAll: Subject<unknown> = new Subject<unknown>();


  protected _curLoginUser: CasUserDetail | null = null;

  private _appConfig: AppConfigService = inject(AppConfigService);

  ngOnInit(): void {
    this._routeInfo.queryParams.subscribe(param => {
      this._backUrl = HelperService.decodeFromBase64(
        param[APPCONSTANT.URL_PARAMETER.BACK_URL] || ''
      );
      this.getMoreRoutingQueryParam(param);
    });
    this._appConfig.loginUser().pipe(
      throttleTime(200),
      takeUntil(this._unsubscribeAll)
    ).subscribe(loginUser => {
      if (loginUser != null) {
        this._curLoginUser = loginUser;
        this.pageInitForLoginUser();
      } else {
        if (environment.enableAnonymous) {
          this._curLoginUser = null;
          this.pageInitForAnonymous();
        } else {
          this._routingService.gotoLoginPage();
        }
      }
    });
    this._getCurrentLoginUser();
  }

  protected getMoreRoutingQueryParam(params: Params): void { }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
    // 未找到监听_unsubscribeAll的位置，在此处清除非page页面的breadcrumbs
    // this._layoutService.setCurrentBreadcrumbs([]);
  }

  protected isFirstRoutingPage(): boolean {
    return true;
  }

  onBackToHomeBtnClick(): void {
    this._routingService.gotoHomePage();
  }

  onBackBtnClick(): void {
    this.gotoPreviousPage();
  }

  protected gotoPreviousPage(): void {
    if (this._backUrl != null && this._backUrl.length > 0) {
      this._routingService.gotoUrl(this._backUrl);
    }
  }

  private static _inGetLoginUser: boolean = false;
  private _getCurrentLoginUser(): void {
    if (PjPageComponent._inGetLoginUser) {
      return;
    }
    PjPageComponent._inGetLoginUser = true;
    if (this.isFirstRoutingPage()) {
      // this._layoutService.disable([]);
      // this._layoutService.detachHeaderComponent();
      // this._layoutService.detachFooterComponent();
      // this._layoutService.detachToolbarComponent();
      // this._layoutService.detachSidemenuComponent();
    }
    this._authService.currentLogin().subscribe(loginUser => {
      this._appConfig.updateLoginUser(loginUser);
      PjPageComponent._inGetLoginUser = false;
    });
  }

  protected pageInitForLoginUser(): void {
    if (this.isFirstRoutingPage()) {
      if (this.hasHeader()) {
        this.setHeader();
      }
      if (this.hasFooter()) {
        this.setFooter();
      }
      if (this.hasToolbar()) {
        this.setToolbar();
      }
      if (this.hasSideMenu()) {
        this.setSideMenu();
      }
    }
  }

  protected pageInitForAnonymous(): void {
    if (this.isFirstRoutingPage()) {
      if (this.hasHeader()) {
        this.setHeader();
      }
      if (this.hasFooter()) {
        this.setFooter();
      }
      if (this.hasToolbar()) {
        this.setToolbar();
      }
      if (this.hasSideMenu()) {
        this.setSideMenu();
      }
    }
  }

  protected hasSideMenu(): boolean {
    return false;
  }

  protected setSideMenu(): void { }

  protected hasHeader(): boolean {
    return true;
  }

  protected setHeader(): void {
    // if (environment.headerComponent == null) {
    //   return;
    // }
    // this._projectService.generateHeaderMenus().subscribe((menuItems) => {
    //   const _usedMenuItems = menuItems.filter(
    //     (item) =>
    //       !isEmpty(item.subMenus) && !item.value?.includes('menu.anon')
    //   );
    //   _usedMenuItems.forEach((item) =>
    //     item.subMenus?.sort(PjDropdownModule.compare)
    //   );
    //   const headerRef = this._layoutService.attachHeaderComponent(
    //     environment.headerComponent!,
    //     { menuItems: _usedMenuItems }
    //   ) as ComponentRef<unknown>;
    //   this._projectService.handleHeaderRef(headerRef, this._routingService);
    // });
  }

  protected hasFooter(): boolean {
    return false;
  }
  protected setFooter(): void { }

  protected hasBreadcrumb(): boolean {
    return true;
  }

  protected hasToolbar(): boolean {
    return false;
  }

  protected setToolbar(): void { }

  protected logout(clearLocalData?: boolean): void {
    this._modalWinService.confirm({
      nzTitle: '<b>确认</b>',
      nzContent: '确实要退出系统？',
      nzOkText: '是',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => this._authService.logout(clearLocalData),
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    })
  }
  private _handleBreadcrumbs(): void {
    this._router.events.pipe(
      filter(event => event != null),
      map(event => {
        if ((event as Scroll).routerEvent) {
          return (event as Scroll).routerEvent.url;
        } else if ((event as NavigationSkipped).url) {
          return (event as NavigationSkipped).url;
        }
        return '';
      }),
      filter(url => (url != null) && (url.length > 0)),
      distinctUntilChanged(),
    ).subscribe(url => {
      let _currentActivedPath = url;
      // this._appCfgService.resetFocusedMenuItem(_currentActivedPath, true);
      // this.toolbarBreadcrumbsData.subscribe((crumbs) =>
      //   this._layoutService.setCurrentBreadcrumbs(crumbs)
      // );


      // this._routeInfo.data.forEach((data) => {
      //   if (hasStringValue(data['breadcrumb'])) {
      //     const breadcrumbs = new Array<PjBreadcrumbItem>();
      //     if (environment.pathLabel != null) {
      //       data['breadcrumb'].forEach(
      //         (bcItem: { name: string; path: string; breadName: string }) =>
      //           breadcrumbs.push({
      //             label: environment.pathLabel![bcItem.name]!.breadName,
      //             path: bcItem.path,
      //           })
      //       );
      //     }
      //     if (hasStringValue(PjPageComponent._currentActivedPath)) {
      //       let lastItemLabel = '';
      //       if (environment.pathLabel != null) {
      //         lastItemLabel =
      //           environment.pathLabel[
      //             PjPageComponent._currentActivedPath.slice(
      //               0,
      //               PjPageComponent._currentActivedPath.length - 1
      //             )
      //           ]!.breadName;
      //       }
      //       if (hasStringValue(lastItemLabel)) {
      //         breadcrumbs.push({ label: lastItemLabel, path: '' });
      //       }
      //     }
      //     this._layoutService.setCurrentBreadcrumbs(breadcrumbs);
      //     this.toolbarBreadcrumbsData.next(breadcrumbs);
      //   }
      // });
    });
  }
}
