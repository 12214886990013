import { Component, Input } from '@angular/core';
import { PjButtonComponent } from '../pj-button.component';

@Component({
  selector: 'pj-icon-button',
  templateUrl: './pj-icon-button.component.html',
  styleUrl: './pj-icon-button.component.less'
})
export class PjIconButtonComponent extends PjButtonComponent {

  @Input()
  iconName: string = 'question_mark';

  @Input()
  tooltip: string = '';

  @Input()
  rounded: boolean = false;
}
