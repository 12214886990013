import { PersistenceEntity } from 'src/model/persistence-entity';



export class BackendFieldFrontendSpecification extends PersistenceEntity {
  modelName?: string;
  fieldName?: string;
  genericType?: string;
  readonly?: boolean;
  position?: number;
  defaultValue?: string;
  label?: string;
  tip?: string;
  dataType?: string;
  inputComponent?: string;
  displayComponent?: string;
  labelPosition?: string;
  tipPosition?: string;
  tipIcon?: string;
  tipIconPosition?: string;
  placeholder?: string;
  header?: string;
  pageSelection?: boolean;
  selectionClassName?: string;
  /** This field is transient on the backend. */
  selectionClass?: any[];
  optionValueField?: string;
  optionLabelField?: string;
  asyncSourceUrl?: string;
  cascadeGroup?: string;
  cascadePositionNo?: number;
  formViewHidden?: boolean;
  formula?: string[];
  fileType?: string;
  i18nable?: boolean;
  searchable?: boolean;
  searchType?: string;
  searchOption?: string;
  isFrozenLeft?: boolean;
  isFrozenRight?: boolean;
  isManagedSeparently?: boolean;
  embbedable?: boolean;
  formatter?: string;
  editableInline?: boolean;
  tableViewHidden?: boolean;
  detailViewHidden?: boolean;
  sortable?: boolean;
  editForCreationOnly?: boolean;
  required?: boolean;
  pattern?: string;
  minVal?: string;
  maxVal?: string;
  minLength?: number;
  maxLength?: number;
}
