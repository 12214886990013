import { Component, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { languageConfig } from 'src/environments/language';
import { version } from 'src/environments/version';
import { ThemeService } from './theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {

  private _translateService: TranslateService = inject(TranslateService);
  constructor(private _titleService: Title, private _themeService: ThemeService) {
    moment.locale(languageConfig.defaultLanguage);
    let coreLang = languageConfig.defaultLanguage + '.core';
    let projectLang = languageConfig.defaultLanguage + '.project';
    this._translateService.addLangs([coreLang, projectLang]);
    this._translateService.setDefaultLang(coreLang);
    this._translateService.use(projectLang);
    this._translateService.onLangChange.subscribe(() =>
      this._titleService.setTitle(this._translateService.instant("app.browserTitle") + ": " + version.buildSn)
    );
  }
  ngOnInit(): void {
    this._themeService.loadTheme().then();
  }
}
