import { EventEmitter,Injectable } from "@angular/core";
import { Html5Qrcode,Html5QrcodeCameraScanConfig } from "html5-qrcode";
import { Html5QrcodeError,Html5QrcodeErrorTypes,Html5QrcodeResult } from 'html5-qrcode/esm/core';
import { Observable,timer } from "rxjs";

@Injectable({ providedIn: 'root' })
export class BarcodeAndQrScannerService {

  private _hasCamera: boolean = false;
  private _html5Qrcode?: Html5Qrcode;
  private _scanArea?: HTMLElement;
  private _scanConfig = { fps: 10,qrbox: { width: 200,height: 200 } };
  // private _imageFileElm?: HTMLElement;

  private _scanResultChange$: EventEmitter<{ scanResult: string,errMessage: string; }> = new EventEmitter();

  constructor() {
    this._hasCamera = false;
    Html5Qrcode.getCameras().then(devices => {
      if (devices && devices.length > 0) {
        // this._statusMessage = `${devices[0].id} --- ${devices[0].label}`;
        this._scanResultChange$.emit({ scanResult: '',errMessage: `${devices[0].id} --- ${devices[0].label}` });
        this._hasCamera = true;
      } else {
        // this._statusMessage = '没有摄像设备，扫描图片文件识别。';
        this._scanResultChange$.emit({ scanResult: '',errMessage: '没有摄像设备，扫描图片文件识别。' });
      }
    }).catch(err => {
      timer(200).subscribe(() => this._scanResultChange$.emit({ scanResult: '',errMessage: '没有摄像设备，扫描图片文件识别。' }));//this._statusMessage = '');
    });
  }

  scanResult (): Observable<{ scanResult: string,errMessage: string; }> {
    return this._scanResultChange$;
  }
  cancelScan (): void {
    if (this._html5Qrcode != null && this._hasCamera) {
      this._html5Qrcode.stop().then(ignore => {
        // QR Code scanning is stopped.
        // this._statusMessage = 'Ignored';
        this._scanResultChange$.emit({ scanResult: '',errMessage: 'Ignored' });
      }).catch(err => {
        // Stop failed, handle it.
        // this._statusMessage = err;
        this._scanResultChange$.emit({ scanResult: '',errMessage: err });
      });
      if (this._scanArea != null) {
        document.body.removeChild(this._scanArea);
      }
      this._html5Qrcode = undefined;
    }
  }
  hasCamera (): boolean {
    return this._hasCamera;
  }
  scan (imageFile?: any): void {
    this._initHtml5Qrcode();
    // timer(100).subscribe(() => {
    if (this._html5Qrcode == null) {
      return;
    }
    if (imageFile != null) {
      this._html5Qrcode.scanFile(imageFile,false).then(decodedText => {
        // this._scanResult = decodedText;
        this._scanResultChange$.emit({ scanResult: decodedText,errMessage: '' });
      }).catch(err => {
        // this._statusMessage = err;
        this._scanResultChange$.emit({ scanResult: '',errMessage: err });
      });
    }
    if (this._hasCamera) {
      this._html5Qrcode.start(
        { facingMode: "environment" },this._scanConfig,
        (decodedText: string,decodedResult: Html5QrcodeResult) => {
          this._onScanSuccess(decodedText,decodedResult,this);
        },(errorMessage: string,error: Html5QrcodeError) => {
          this._onScanFailure(errorMessage,error,this);
        }
      );
    }
    // });
  }

  // private _statusMessage?: string;
  // private _scanResult?: string;
  private _onScanSuccess (decodedText: string,decodedResult: Html5QrcodeResult,that: BarcodeAndQrScannerService): void {
    // handle the scanned code as you like, for example:
    // console.log(`Code matched = ${decodedText}`, decodedResult);
    // that._scanResult = (decodedResult.result.format?.formatName || 'Unknow') + ': ' + decodedText;
    that._scanResultChange$.emit({ scanResult: decodedText,errMessage: '' });
    if (that._html5Qrcode != null) {
      that._html5Qrcode.stop();
      if (that._scanArea != null) {
        document.body.removeChild(that._scanArea);
      }
      that._html5Qrcode = undefined;
    }
  }

  private _onScanFailure (errorMessage: string,error: Html5QrcodeError,that: BarcodeAndQrScannerService): void {
    if (error.type == Html5QrcodeErrorTypes.UNKWOWN_ERROR) {
      // that._statusMessage = '请把需要扫描的条码或者二维码放到方框中';
      that._scanResultChange$.emit({ scanResult: '',errMessage: '请把需要扫描的条码或者二维码放到方框中' });
    } else {
      // that._statusMessage = errorMessage;
      that._scanResultChange$.emit({ scanResult: '',errMessage: errorMessage });
    }
  }

  private _initHtml5Qrcode (): void {
    const reader = document.getElementById('reader');
    // if (!reader) {
    //   this._scanArea = document.createElement('div');
    //   this._scanArea.id = 'reader';
    // }
    this._html5Qrcode = new Html5Qrcode("reader");
  }

  public setConfig (config: Html5QrcodeCameraScanConfig) {
    this._scanConfig = Object.assign(this._scanConfig,config);
  }
}
