import { PersistenceEntity } from 'src/model/persistence-entity';
import { BackendCasUser } from './cas-user.backend.model';
import { BackendCasGroup } from './cas-group.backend.model';
import { BackendCasRole } from './cas-role.backend.model';
import { BackendDataResource } from './data-resource.backend.model';
import { BackendDataResourceField } from './data-resource-field.backend.model';
import { BackendDataResourceOperation } from './data-resource-operation.backend.model';
import { BackendCasUserContactInfo } from './cas-user-contact-info.backend.model';
import { BackendFunctionality } from './functionality.backend.model';



export class BackendCasUserDetail extends PersistenceEntity {
  casUser?: BackendCasUser;
  groupList?: BackendCasGroup[];
  roleList?: BackendCasRole[];
  dataResourceList?: BackendDataResource[];
  excludedFieldList?: BackendDataResourceField[];
  dataResourceOperationList?: BackendDataResourceOperation[];
  contactInfo?: BackendCasUserContactInfo;
  birthday?: string;
  avatar?: string;
  /** This field is transient on the backend. */
  allDataResourceList?: BackendDataResource[];
  /** This field is transient on the backend. */
  allExcludedFieldList?: BackendDataResourceField[];
  /** This field is transient on the backend. */
  allDataResourceOperationList?: BackendDataResourceOperation[];
  /** This field is transient on the backend. */
  functionalityList?: BackendFunctionality[];
}
