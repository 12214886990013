import { Component, Input, SimpleChanges } from '@angular/core';
import { PjButtonComponent } from '../pj-button.component';

@Component({
  selector: 'pj-std-button',
  templateUrl: './pj-std-button.component.html',
  styleUrl: './pj-std-button.component.less'
})
export class PjStdButtonComponent extends PjButtonComponent {

  @Input()
  leftIconName?: string;

  @Input()
  rightIconName?: string;

  @Input()
  label: string = '';

  @Input()
  badgeLabel: string = '';

  @Input()
  badgeColor: string = 'primary';

  private _nzColor: string = '';

  ngOnInit(): void {
    this._nzColor = this._convertToNzColor();
  }

  override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (changes['badgeColor'] != null) {
      this._nzColor = this._convertToNzColor();
    }
  }
  getBadgeColor(): string {
    return this._nzColor;
  }

  getTagColor(): string {
    return this._nzColor;
  }

  onBtnClick(): void {

  }

  isDisabled(): boolean {
    return this.disabled;
  }

  getBtnLabel(): string {
    return this.label;
  }
  private _convertToNzColor(): string {
    let c: string = '';
    switch (this.badgeColor) {
      case 'primary': c = ''; break;
      case 'error': c = 'red'; break;
      case 'info': c = 'gold'; break;
      case 'success': c = 'green'; break;
      case 'warning': c = 'orange'; break;
    }
    return c;
  }
}
