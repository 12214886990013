<div nz-menu nzMode="inline" [nzInlineCollapsed]="isCollapsed()">
  <ng-container *ngFor="let item of getMenuItems()">
    <div *ngIf="!hasChildren(item)">
      <div nz-menu-item *ngIf="hasRouterLink(item)" [nzSelected]="isSelected(item)" [routerLink]="getRouterLink(item)"
        nzMatchRouter>
        <ng-container *ngTemplateOutlet="firstLevel"></ng-container>
      </div>
      <div nz-menu-item *ngIf="!hasRouterLink(item)" [nzSelected]="isSelected(item)" (click)="onMenuItemClick(item)">
        <ng-container *ngTemplateOutlet="firstLevel"></ng-container>
      </div>
    </div>
    <div *ngIf="hasChildren(item)" nz-submenu [nzOpen]="isOpened(item)" (nzOpenChange)="onOpenChanged($event, item)"
      [nzTitle]="firstLevel">
      <ng-container *ngFor="let subItem of getSubMenuItems(item)">
        <div nz-submenu *ngIf="hasChildren(subItem)" [nzTitle]="getMenuItemTitle(subItem)" [nzOpen]="isOpened(subItem)">
          <ng-container *ngFor="let thirdItem of getSubMenuItems(subItem)">
            <div nz-menu-item *ngIf="hasRouterLink(thirdItem)" [nzSelected]="isSelected(thirdItem)"
              [routerLink]="getRouterLink(thirdItem)" nzMatchRouter>
              {{thirdItem.label}}
            </div>
            <div nz-menu-item *ngIf="!hasRouterLink(thirdItem)" [nzSelected]="isSelected(thirdItem)"
              (click)="onMenuItemClick(thirdItem)">
              {{thirdItem.label}}
            </div>
          </ng-container>
        </div>
        <ng-container *ngIf="!hasChildren(subItem)">
          <div nz-menu-item *ngIf="hasRouterLink(subItem)" [nzSelected]="isSelected(subItem)"
            [routerLink]="getRouterLink(subItem)" nzMatchRouter>
            {{subItem.label}}
          </div>
          <div nz-menu-item *ngIf="!hasRouterLink(subItem)" [nzSelected]="isSelected(subItem)"
            (click)="onMenuItemClick(subItem)">
            {{subItem.label}}
          </div>
        </ng-container>
      </ng-container>
    </div>
    <ng-template #firstLevel>
      <div class="flex flex-row items-center gap-4">
        <pj-icon class="btnDefaultIcon" [iconName]="getMenuItemIconName(item)"></pj-icon>
        <div *ngIf="!isCollapsed()">{{getMenuItemTitle(item)}}</div>
      </div>
    </ng-template>
  </ng-container>
</div>