import { Component,inject } from '@angular/core';
import { FormBuilder,FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { NzMessageService } from 'ng-zorro-antd/message';
import { APPCONSTANT } from 'src/app/app.constant';
import { AuthService } from 'src/service/auth.service';
import { RoutingService } from 'src/service/routing.service';
import { StorageService } from 'src/service/storage.service';

@Component({ template: `` })
export abstract class PageLoginBaseComponent {
  validateForm: FormGroup;

  private _messageService = inject(NzMessageService);
  private _translateService = inject(TranslateService);
  protected _routingService: RoutingService = inject(RoutingService);
  protected _routInfo: ActivatedRoute = inject(ActivatedRoute);
  private _storageService = inject(StorageService);
  private _authService = inject(AuthService);

  private _redirectUrl: string = '';

  private _verifyCodeSended = false;
  protected _fb: FormBuilder = inject(FormBuilder);

  constructor() {
    this.validateForm = this._fb.group({});
  }
  ngOnInit (): void {
    this._routInfo.queryParams.subscribe(params => {
      this._redirectUrl = params[APPCONSTANT.URL_PARAMETER.REDIRECT_URL];
    });
  }

  getImageUrl (): string | undefined {
    return;
  }

  submitForm (): void {
    const { loginName,phoneNumber } = this.getUserLoginInfo();
    if (this.validateForm.valid) {
      const v = this.validateForm.value;
      this._authService.login(
        {
          username: loginName,
          phone: phoneNumber,
          password: v.password,verifyCode: v.verifyCode
        }
      ).subscribe(res => {
        if (res != null && res.id != null) {
          const casUser = res;
          this._storageService.setCasToken(casUser?.token);
          this._routingService.gotoUrlAfterLogin(this._redirectUrl);
        }
      });
    } else {
      console.error(this.validateForm.value);
    }
  }


  getTheValidateStatus (): string {
    return this.validateForm.controls['verifyCode'].valid ? 'sccess' : 'error';
  }

  getTitle (): string {
    return this._translateService.instant('auth.login.title') + this._translateService.instant('app.system');
  }

  // getVerificationCodeDisabled (): boolean {
  //   return this._verifyCodeSended || this.validateForm.controls['phone']?.valid;
  // }

  onFetchVerificationCodeBtnClick (): void {
    const { loginName,phoneNumber } = this.getUserLoginInfo();
    if (this.validateForm.valid) {
      let v = this.validateForm.value;
      const payload = _.omitBy({ username: loginName,phone: phoneNumber },(item) => {
        return !item;
      });
      this._authService.verifyCodeRequest(payload
      ).subscribe(res => {
        if (res) {
          console.log('Sending Verification Code.');
          this._verifyCodeSended = true;
        } else {
          console.log('Can not send Verification Code.');
        }
      });
    } else {
      console.error(this.validateForm.value);
    }
  }
  protected abstract getLoginName (): string;

  protected getUserLoginInfo (): {
    phoneNumber?: string,
    loginName?: string,
  } {
    const phoneNumber = this.validateForm.get('phoneNumber')?.value ?? '';
    const loginName = this.getLoginName();
    const nameNotValid = loginName == null || loginName.length == 0;
    const phoneNumberNotValid = phoneNumber == null || phoneNumber.length == 0;

    const isNotValid = [nameNotValid,phoneNumberNotValid].every(i => i);
    if (isNotValid) {
      return {};
    } else {
      return {
        phoneNumber,
        loginName
      };
    }
  }
}
