<div class="flex flex-col gap-2">
  <div class="flex flex-row gap-4" *ngFor="let rowBtns of getBtnRows()">
    <div *ngFor="let btn of rowBtns" [ngStyle]="getWidthBtnWidthStyle(rowBtns)">
      <pj-std-button [rightIconName]="btn.iconName" [label]="btn.title" [btnType]="getBtnType(btn)" [size]="size"
        (click)="onTabBtnClick(btn)" [badgeLabel]="btn.badge||''" [badgeColor]="btn.badgeColor || ''">
      </pj-std-button>
    </div>
  </div>
  <pj-divider></pj-divider>
  <ng-container *ngTemplateOutlet="getActivedTemplateRef()"></ng-container>
</div>