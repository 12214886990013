<nz-tabset [nzTabPosition]="position" [nzSelectedIndex]="getActiveIndex()" nzType="editable-card" [nzHideAdd]="true"
  (nzClose)="onTabClose($event.index)" [nzTabBarExtraContent]="tabAction">
  <nz-tab *ngFor="let tabItem of getTabItems(); index as i" [nzTitle]="tabItem.title" [nzDisabled]="tabItem.disabled"
    (nzSelect)="onSelectChange(tabItem)" (nzClick)="onTabItemClick(tabItem)" [nzClosable]="tabItem.closable">
    <div *ngIf="(getActiveIndex() == i)">
      <ng-container *ngTemplateOutlet="getTemplateRef(tabItem)"></ng-container>
    </div>
  </nz-tab>
</nz-tabset>

<ng-template #tabAction>
  <div class="flex flex-row items-center gap-2">
    <pj-std-button *ngFor="let action of actionItems" [label]="action.label" (click)="onActionBtnClick(action)"
      [disabled]="action.disabled||false" btnType="filled">
    </pj-std-button>
  </div>
</ng-template>