import { Component,EventEmitter,inject,Output } from '@angular/core';
import { HelperService } from 'src/service/helper.service';
import { BarcodeAndQrScannerService } from 'src/service/scanner.service';
import { PjStdButtonComponent } from '../pj-std-button/pj-std-button.component';

@Component({
  selector: 'pj-scan-button',
  templateUrl: '../pj-std-button/pj-std-button.component.html',
  styleUrl: '../pj-std-button/pj-std-button.component.less'
})
export class PjScanButtonComponent extends PjStdButtonComponent {

  @Output()
  scanResult: EventEmitter<string> = new EventEmitter();

  @Output()
  scannerStatusChangeEmitter = new EventEmitter<boolean>();
  protected _scanService: BarcodeAndQrScannerService = inject(BarcodeAndQrScannerService);
  private _scanning: boolean = false;

  override ngOnInit (): void {
    super.ngOnInit();
    this._scanService.scanResult().subscribe(result => {
      if (result.errMessage === 'Ignored' || !HelperService.hasStringValue(result.errMessage) ) {
        this._scanService.cancelScan();
        this.scanResult.emit(result.scanResult);
        this._scanning = false;
      }
    });
  }

  override isDisabled (): boolean {
    if (this.disabled) {
      return this.disabled;
    }
    return !this._scanService.hasCamera();
  }
  override getBtnLabel (): string {
    let a = this.label;
    if (this._scanning) {
      a = '取消扫描';
    }
    return a;
  }

  override onBtnClick (): void {
    if (this._scanning) {
      this._scanning = false;
      if (this._scanService.hasCamera()) {
        this._scanService.cancelScan();
        this.scannerStatusChangeEmitter.emit(false);
      }
    } else {
      this._scanning = true;
      if (this._scanService.hasCamera()) {
        this._scanService.scan();
        this.scannerStatusChangeEmitter.emit(true);
      }
    }
  }
}
