import { CommonModule } from '@angular/common';
import { Component,EventEmitter,Input,Output } from '@angular/core';
import { PjButtonModule } from "src/component/pj-button/pj-button.module";

@Component({
  selector: 'pj-m-full-container',
  standalone: true,
  imports: [CommonModule,PjButtonModule],
  templateUrl: './pj-m-full-container.component.html',
  styleUrl: './pj-m-full-container.component.less'
})
export class pjMFullContainerComponent {
  @Input()
  showContainer = false;
  @Output()
  backBtnEmitter = new EventEmitter();

  onBackBtnClick () {
    this.backBtnEmitter.emit();
  }
}
