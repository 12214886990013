import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { PjAction } from 'src/component/component.type';
import { PjButtonComponent } from '../pj-button.component';

@Component({
  selector: 'pj-button-group',
  templateUrl: './pj-button-group.component.html',
  styleUrl: './pj-button-group.component.less'
})
export class PjButtonGroupComponent extends PjButtonComponent {

  @Input()
  actions?: PjAction[];

  @Input()
  iconBtn: boolean = false;

  // 以按钮方式显示的数量，其余的显示为下拉菜单
  // 如果 actions.length <= shownAmount ， 则全部显示为按钮
  // 如果 actions.length > shownAmount ，则显示 shownAmount-1 个功能按钮和一个显示下拉菜单的按钮
  @Input()
  shownAmount: number = 4;

  @Input()
  moreIconName: string = 'more_vert';

  private _shownBtns: PjAction[] = [];
  private _hiddenBtns: PjAction[] = [];

  override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (changes['actions'] && this.actions != null && Array.isArray(this.actions)) {
      if (this.actions.length <= this.shownAmount) {
        this._shownBtns = [...this.actions];
      } else {
        this._shownBtns = [];
        this._hiddenBtns = [];
        for (let i = 0; i < (this.shownAmount - 1); i++) {
          this._shownBtns.push(this.actions[i]);
        }
        for (let i = (this.shownAmount - 1); i < this.actions.length; i++) {
          this._hiddenBtns?.push(this.actions[i]);
        }
      }
    }
  }

  hasMoreBtn(): boolean {
    return (this.actions?.length || 0) > this.shownAmount;
  }

  getShownActions(): PjAction[] {
    return this._shownBtns;
  }

  getHiddenActions(): PjAction[] {
    return this._hiddenBtns;
  }

  onBtnClick(action: PjAction, mouseEvent: MouseEvent): void {
    mouseEvent.stopPropagation();
    this.actionClick.emit(action);
  }

  onMoreBtnClick(mouseEvent: MouseEvent): void {
    mouseEvent.stopPropagation();
  }
  // onDropdownMenuClick(action: PjAction): void {
  //   this.actionClick.emit(action);
  // }
}
