import { PersistenceEntity } from 'src/model/persistence-entity';
import { BackendDataResource } from './data-resource.backend.model';



export class BackendDataResourceOperation extends PersistenceEntity {
  dataResource?: BackendDataResource;
  operationCode?: string;
  operationName?: string;
  iconName?: string;
  apiCode?: string;
  operationType?: string;
}
