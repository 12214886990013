import { BackendAttachment } from "./generated/attachment.backend.model";
import { BackendFileInfo } from "./generated/file-info.backend.model";

export class Attachment extends BackendAttachment {
  declare fileInfo?: FileInfo;
}

export class FileInfo extends BackendFileInfo {

  
  blobContent?: File;
}