import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { PjIconModule } from '../pj-icon/pj-icon.module';
import { PjButtonGroupComponent } from './pj-button-group/pj-button-group.component';
import { PjCountdownButtonComponent } from './pj-countdown-button/pj-countdown-button.component';
import { PjFavoriteButtonComponent } from './pj-favorite-button/pj-favorite-button.component';
import { PjIconButtonComponent } from './pj-icon-button/pj-icon-button.component';
import { PjScanButtonComponent } from './pj-scan-button/pj-scan-button.component';
import { PjStdButtonComponent } from './pj-std-button/pj-std-button.component';



@NgModule({
  declarations: [
    PjIconButtonComponent,
    PjStdButtonComponent,
    PjFavoriteButtonComponent,
    PjButtonGroupComponent,
    PjCountdownButtonComponent,
    PjScanButtonComponent
  ],
  imports: [
    CommonModule,

    NzButtonModule,
    NzToolTipModule,
    NzDropDownModule,
    NzTagModule,

    PjIconModule
  ],
  exports: [
    PjIconButtonComponent,
    PjStdButtonComponent,
    PjFavoriteButtonComponent,
    PjButtonGroupComponent,
    PjCountdownButtonComponent,
    PjScanButtonComponent
  ]
})
export class PjButtonModule { }
