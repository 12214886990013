import { PersistenceEntity } from 'src/model/persistence-entity';



export class BackendDataResource extends PersistenceEntity {
  moduleServiceCode?: string;
  domainCode?: string;
  domainName?: string;
  modelCode?: string;
  modelName?: string;
}
