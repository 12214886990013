import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { PjTextInputModule } from 'src/component-form/pj-text-input/pj-text-input.module';
import { PjVerificationCodeModule } from 'src/component-form/pj-verification-code/pj-verification-code.module';
import { PjButtonModule } from 'src/component/pj-button/pj-button.module';
import { PjIconModule } from 'src/component/pj-icon/pj-icon.module';
import { AuthService } from 'src/service/auth.service';
import { PathName } from 'src/service/routing.service';
import { PageForgotPasswordComponent } from './page-forgot-password/page-forgot-password.component';
import { PageLoginPswdOnlyComponent } from './page-login-pswd-only/page-login-pswd-only.component';
import { PageLoginComponent } from './page-login/page-login.component';
import { PageRegisterComponent } from './page-register/page-register.component';
import { PageResetPasswordComponent } from './page-reset-password/page-reset-password.component';


const routes: Routes = [
  { path: PathName.LOGIN, component: PageLoginComponent },
  { path: PathName.LOGIN_NAME, component: PageLoginPswdOnlyComponent },
  { path: PathName.REGISTER, component: PageRegisterComponent },
  { path: PathName.FORGOTPASSWORD, component: PageForgotPasswordComponent },
  { path: PathName.RESET_PASSWORD_LINK, component: PageResetPasswordComponent }
];
@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),

    FormsModule,
    ReactiveFormsModule,

    NzFormModule,
    NzCheckboxModule,
    NzTypographyModule,

    PjButtonModule,
    PjIconModule,
    PjTextInputModule,
    PjVerificationCodeModule
  ],
  declarations: [
    PageLoginComponent,
    PageRegisterComponent,
    PageForgotPasswordComponent,
    PageResetPasswordComponent,
    PageLoginPswdOnlyComponent
  ],
  providers: [
    AuthService,
    NzModalService
  ]
})
export class PageAuthModule { }
