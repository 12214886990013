<button *ngIf="!hasDropdownMenu()" class="iconBtn" nz-button [nzShape]="rounded?null:'circle'" [nzSize]="size"
  [disabled]="disabled" [nzType]="getNzType()">
  <pj-icon [ngClass]="getIconClass()" [iconName]="iconName" [nz-tooltip]="tooltip"></pj-icon>
</button>

<button *ngIf="hasDropdownMenu()" class="iconBtn" nz-button [nzShape]="rounded?null:'circle'" [nzSize]="size"
  [disabled]="disabled" [nzType]="getNzType()" (click)="onWithMenuBtnClick($event)">
  <pj-icon [ngClass]="getIconClass()" [iconName]="iconName" [nz-tooltip]="tooltip" nz-dropdown
    [nzDropdownMenu]="dropdownMenu">
  </pj-icon>
  <nz-dropdown-menu #dropdownMenu="nzDropdownMenu">
    <div nz-menu nzSelectable>
      <div nz-menu-item *ngFor="let actionItem of dropdownMenus" (click)="onDropdownMenuClick(actionItem)">
        {{actionItem.label}}
      </div>
    </div>
  </nz-dropdown-menu>
</button>