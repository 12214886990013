import { TemplateRef } from "@angular/core";

// 数据来自： https://ng.ant.design/components/table/zh
export class PjTableConfig {
  clickForSelection: boolean = false;
  enabledHead: boolean = true;
  headsConfig: PjTableHeadConfig[] = [];
  expandable: boolean = false;
  selectable: boolean = true;
  leftAction: boolean = true;
  rightAction: boolean = false;
  // [nzData]	数据数组	T[]	-	
  // [nzFrontPagination]	是否在前端对数据进行分页，如果在服务器分页数据或者需要在前端显示全部数据时传入 false	boolean	true	
  // [nzTotal]	当前总数据，在服务器渲染时需要传入	number	-	
  // [nzCustomColumn]	控制表格列的展示与排序，(开启后 nzWidthConfig 和 th 的 [nzWidth] 将不生效)	NzCustomColumn[]	-	
  // [nzPageIndex]	当前页码，可双向绑定	number	-	
  // [nzPageSize]	每页展示多少数据，可双向绑定	number	-	
  // [nzShowPagination]	是否显示分页器	boolean	true	
  // [nzPaginationPosition]	指定分页显示的位置	'top' | 'bottom' | 'both'	bottom	
  // [nzPaginationType]	指定分页显示的尺寸	'default' | 'small'	default	
  // [nzBordered]	是否展示外边框和列边框	boolean	false	✅
  // [nzOuterBordered]	是否显示外边框	boolean	false	-
  // [nzWidthConfig]	表头分组时指定每列宽度，与 th 的 [nzWidth] 不可混用	string[]	[]	
  // [nzSize]	正常或迷你类型	'middle' | 'small' | 'default'	'default'	✅
  // [nzLoading]	页面是否加载中	boolean	false	
  // [nzLoadingIndicator]	加载指示符	TemplateRef<void>	-	✅
  // [nzLoadingDelay]	延迟显示加载效果的时间（防止闪烁）	number	0	
  // [nzScroll]	横向或纵向支持滚动，也可用于指定滚动区域的宽高度：{ x: "300px", y: "300px" }	object	-	
  dataAreaWith: string = '300px';
  dataAreaHeight: string = '440px';
  // [nzTitle]	表格标题	string | TemplateRef<void>	-	
  title?: string | TemplateRef<void>;
  // [nzFooter]	表格尾部	string | TemplateRef<void>	-	
  footer?: string | TemplateRef<void>;
  // [nzNoResult]	无数据时显示内容	string | TemplateRef<void>	-	
  noDataMessage?: string | TemplateRef<void> = '没有数据！';
  // [nzPageSizeOptions]	页数选择器可选值	number[]	[ 10, 20, 30, 40, 50 ]	
  pageSizeOptions: number[] = [10, 25, 50];
  // [nzShowQuickJumper]	是否可以快速跳转至某页	boolean	false	✅
  // [nzShowSizeChanger]	是否可以改变 nzPageSize	boolean	false	✅
  // [nzShowTotal]	用于显示数据总量和当前数据范围，用法参照 Pagination 组件	TemplateRef<{ $implicit: number, range: [ number, number ] }>	-	
  elmForTotal: TemplateRef<{ $implicit: number, range: [number, number] }> | null = null;
  // [nzItemRender]	用于自定义页码的结构，用法参照 Pagination 组件	TemplateRef<{ $implicit: 'page' | 'prev' | 'next', page: number }>	-	
  // [nzHideOnSinglePage]	只有一页时是否隐藏分页器	boolean	false	✅
  // [nzSimple]	当添加该属性时，显示为简单分页	boolean	-	✅
  // [nzTemplateMode]	模板模式，无需将数据传递给 nzData	boolean	false	
  // [nzVirtualItemSize]	虚拟滚动时每一列的高度，与 cdk itemSize 相同	number	0	
  // [nzVirtualMaxBufferPx]	缓冲区最大像素高度，与 cdk maxBufferPx 相同	number	200	
  // [nzVirtualMinBufferPx]	缓冲区最小像素高度，低于该值时将加载新结构，与 cdk minBufferPx 相同	number	100	
  // [nzVirtualForTrackBy]	虚拟滚动数据 TrackByFunction 函数	TrackByFunction<T>	-	
  // (nzPageIndexChange)	当前页码改变时的回调函数	EventEmitter<number>	-	
  // (nzPageSizeChange)	页数改变时的回调函数	EventEmitter<number>	-	
  // (nzCurrentPageDataChange)	当前页面展示数据改变的回调函数	EventEmitter<T[]>	-	
  // (nzCustomColumnChange)	当表格重新排序后的回调	EventEmitter<NzCustomColumn[]>	-	
  // (nzQueryParams)	当服务端分页、筛选、排序时，用于获得参数，具体见示例	EventEmitter<NzTableQueryParams>	-
}

export class PjTableHeadConfig {
  header?: string; // 表格头
  fieldName: string = '';

  // [nzShowCheckbox]	是否添加 checkbox	boolean
  // [nzDisabled]	checkbox 是否禁用	boolean
  // [nzIndeterminate]	checkbox indeterminate 状态	boolean
  // [nzLabel]	checkbox 的可访问性标签	string
  // [nzChecked]	checkbox 是否被选中，可双向绑定	boolean
  // (nzCheckedChange)	选中的回调	EventEmitter<boolean>
  // [nzShowRowSelection]	是否显示下拉选择	boolean
  rowSelectionEnabled: boolean = false; // 暂时没有实现
  // [nzSelections]	下拉选择的内容 text 及回调函数 onSelect	Array<{ text: string, onSelect: any }>
  rowSelectionConfig: Array<{ text: string, onSelect: any }> = []; // 暂时没有实现
  // [nzShowSort]	是否显示排序	boolean
  // [nzSortFn]	排序函数，前端排序使用一个函数(参考 Array.sort 的 compareFunction)，服务端排序时传入 true	NzTableSortFn<T> | boolean
  // [nzSortDirections]	支持的排序方式，取值为 'ascend', 'descend', null	Array<'ascend' | 'descend' | null>
  // [nzSortOrder]	当前排序状态，可双向绑定	'descend'	'ascend'
  // (nzSortOrderChange)	排序状态改变回调	EventEmitter<'descend' | 'ascend' | null>

  // [nzShowFilter]	是否显示过滤	boolean
  // [nzFilterFn]	前端排序时，确定筛选的运行函数，服务端排序时，传入 true	NzTableFilterFn<T> | boolean
  // [nzFilters]	过滤器内容, 显示数据 text，回调函数传出 value，设置 byDefault 以默认应用过滤规则	Array<{ text: string; value: any; byDefault?: boolean }>
  // [nzFilterMultiple]	是否为多选过滤器	boolean true
  // (nzFilterChange)	过滤器内容选择的 value 数据回调	EventEmitter<any[] | any>

  // [nzWidth]	指定该列宽度，表头未分组时可用	string
  width: string = '200px';
  // [nzLeft]	左侧距离，用于固定左侧列，当为 true 时自动计算，为 false 时停止固定	string | boolean	false
  leftFixed: boolean = false;
  // [nzRight]	右侧距离，用于固定右侧列，当为 true 时自动计算，为 false 时停止固定	string | boolean	false
  rightFixed: boolean = false;
  // [nzAlign]	设置列内容的对齐方式	'left' | 'right' | 'center'
  colAlign: string = 'left';
  // [nzCellControl]	设置列的位置，该值为 NzCustomColumn 类型中 value 字段的值	string
  // [nzBreakWord]	是否折行显示	boolean	false
  // [nzEllipsis]	超过宽度将自动省略，暂不支持和排序筛选一起使用。仅当表格布局将为 nzTableLayout="fixed"时可用	boolean	false
  // [colSpan]	每单元格中扩展列的数量	number	null
  // [rowSpan]	每单元格中扩展行的数量	number	null

  // [nzColumnKey]	当前列的 key，用于服务端筛选和排序使用	string
}
export class PjTableRowConfig {
  // [nzExpand]	当前列是否展开，与 td 上的 nzExpand 属性配合使用	boolean
}

export class PjTableColConfig {
  // [nzShowCheckbox]	是否添加 checkbox	boolean	-
  // [nzDisabled]	checkbox 是否禁用	boolean	-
  // [nzIndeterminate]	checkbox indeterminate 状态	boolean	-
  // [nzLabel]	checkbox 的可访问性标签	string	-
  // [nzChecked]	checkbox 是否被选中，可双向绑定	boolean	-
  selected: boolean = false;
  // (nzCheckedChange)	选中的回调	EventEmitter<boolean>	-
  // [colSpan]	单元格可横跨的列数	number	null
  // [rowSpan]	单元格可横跨的行数	number	null

  // [nzShowExpand]	是否显示展开按钮	boolean	-
  // [nzExpand]	当前展开按钮状态，可双向绑定	boolean	-
  expanded: boolean = false;
  // [nzExpandIcon]	自定义展开图标	TemplateRef<void>	-
  iconElmForExpand: TemplateRef<void> | null = null;
  // (nzExpandChange)	当前展开按钮状态改变回调函数	EventEmitter<boolean>	-

  // [nzLeft]	左侧距离，用于固定左侧列，当为 true 时自动计算，为 false 时停止固定	string | boolean	false
  // [nzRight]	右侧距离，用于固定右侧列，当为 true 时自动计算，为 false 时停止固定	string | boolean	false
  // [nzAlign]	设置列内容的对齐方式	'left' | 'right' | 'center'	-
  // [nzCellControl]	设置列的位置，该值为 NzCustomColumn 类型中 value 字段的值	string	-
  // [nzBreakWord]	是否折行显示	boolean	false
  // [nzEllipsis]	超过宽度将自动省略，暂不支持和排序筛选一起使用。仅当表格布局将为 nzTableLayout="fixed"时可用	boolean	false

  // [nzIndentSize]	展示树形数据时，每层缩进的宽度，以 px 为单位	number
}
