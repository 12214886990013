<nz-layout class="page-cargo">
  <nz-sider nzCollapsible [nzTrigger]="null" [(nzCollapsed)]="isCollapsed" nzWidth="240px">
    <div class="flex flex-col">
      <div class="sideHeader flex flex-row items-center gap-4 px-6 py-3" (click)="onSideHeaderClick()">
        <img class="sideLogo" src="{{getImageSrc()}}" />
        <div class="sideTitle" *ngIf="!isCollapsed">Soar-Cargo</div>
      </div>
      <pj-side-menu class="sideMenu overflow-y-auto" [menuItems]="getMenuItems()" [collapsed]="isCollapsed"
        [singleOpend]="true" (itemChanged)="onMenuItemClick($event)">
      </pj-side-menu>
    </div>
  </nz-sider>
  <nz-layout>
    <nz-header>
      <div class="flex flex-row items-center justify-between">
        <pj-icon-button btnType="text" [iconName]="isCollapsed?'menu':'menu_open'"
          (click)="isCollapsed=!isCollapsed"></pj-icon-button>
        <div class="flex flex-row items-center gap-4">
          <pj-icon-button *ngIf="isDataManageEnabled()" btnType="text" iconName="bug_report" (click)="gotoTestPage()">
          </pj-icon-button>
          <pj-icon-button *ngIf="isDataManageEnabled()" btnType="text" iconName="storage"
            (click)="gotoDataManagePage()">
          </pj-icon-button>
          <pj-icon-button btnType="text" iconName="camera" (click)="toggleTheme()"></pj-icon-button>
          <pj-icon-button btnType="text" iconName="format_list_bulleted"></pj-icon-button>
          <pj-icon-button btnType="text" iconName="notifications.round"></pj-icon-button>
          <pj-icon-button btnType="text" iconName="account_circle"
            (click)="onOpenAccountPanelBtnClick()"></pj-icon-button>
        </div>
      </div>
    </nz-header>
    <nz-content class="p-4">
      <pj-tab-group [tabItems]="getTabItems()" (selectedItemChange)="onTabItemChanged($event)">
      </pj-tab-group>
      <router-outlet></router-outlet>
    </nz-content>
    <nz-footer>Footer</nz-footer>
  </nz-layout>
</nz-layout>

<nz-drawer [nzBodyStyle]="{ overflow: 'auto' }" [nzMaskClosable]="false" [nzWidth]="420"
  [nzVisible]="isAccountPanelVisible()" nzTitle="Create" [nzFooter]="footerTpl"
  (nzOnClose)="onCloseAccountPannelBtnClick()">

</nz-drawer>
<ng-template #footerTpl>
  <div style="float: right" class="flex flex-row items-center gap-2">
    <pj-std-button label="Cancel" (click)="onCloseAccountPannelBtnClick()"></pj-std-button>
    <pj-std-button label="Logout" (click)="onLgoutBtnClick()"></pj-std-button>
  </div>
</ng-template>