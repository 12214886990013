import { Injector } from "@angular/core";
import { filter,Observable } from "rxjs";
import { PjKeyValue } from "src/component/component.type";
import { FrontModelMetaData } from "src/model/front-model-metadata";
import { PersistenceEntity } from "src/model/persistence-entity";
import { DomainModelConstant } from "./app.config.service";
import { HttpCallParameter,PagingData,ResponseDataCategory } from "./httpcall.type";
import { ModelServiceHttpImpl } from "./model.service.httpimpl";

// FIXME: 实现一个工厂方法来生成和缓存模型的前后端数据服务
export class DomainModelServiceHttpImpl<T extends PersistenceEntity> {

  private static _serviceMap: PjKeyValue<DomainModelServiceHttpImpl<any>> = {};

  private _modelService: ModelServiceHttpImpl;// = inject(ModelServiceHttpImpl);

  private _domainCode: string = '';
  private _modelCode: string = '';

  private constructor(private _injector: Injector, private _modelSimpleName: string) {
    this._modelService = this._injector.get(ModelServiceHttpImpl)
    if (DomainModelConstant[_modelSimpleName] != null) {
      this._domainCode = DomainModelConstant[_modelSimpleName].domainCode || '';
      this._modelCode = DomainModelConstant[_modelSimpleName].modelCode || '';
    } else {
      console.log('No domain and model data.')
    }
  }

  getDomainAndModelCode(): { domainCode: string, modelCode: string } {
    return { domainCode: this._domainCode, modelCode: this._modelCode };
  }

  static generateDomainModelServiceWithDomainAndModelCode(
    injector: Injector, domainCode: string, modelCode: string
  ): DomainModelServiceHttpImpl<any> {
    const _modelSimpleName: string = modelCode.split('.').reverse()[0];
    if (DomainModelConstant[_modelSimpleName] == null) {
      DomainModelConstant[_modelSimpleName] = {};
      DomainModelConstant[_modelSimpleName].domainCode = domainCode;
      DomainModelConstant[_modelSimpleName].modelCode = modelCode;
    }
    let s = DomainModelServiceHttpImpl._serviceMap[_modelSimpleName];
    if (s == null) {
      s = new DomainModelServiceHttpImpl(injector, _modelSimpleName);
      DomainModelServiceHttpImpl._serviceMap[_modelSimpleName] = s;
    }
    return s;
  }

  static generateDomainModelService(injector: Injector, modelName: string): DomainModelServiceHttpImpl<any> {
    const _modelSimpleName: string = modelName.split('.').reverse()[0];
    let s = DomainModelServiceHttpImpl._serviceMap[_modelSimpleName];
    if (s == null) {
      s = new DomainModelServiceHttpImpl(injector, _modelSimpleName);
      DomainModelServiceHttpImpl._serviceMap[_modelSimpleName] = s;
    }
    return s;
  }

  protected generateHttpParam(methodCode: string): HttpCallParameter {
    return this._modelService.generateHttpParam(this._domainCode, this._modelCode, methodCode);
  }

  getMetaData(): Observable<FrontModelMetaData | null> {
    return this._modelService.getMetaData(this._domainCode, this._modelCode).pipe(filter(md => md != null));
  }

  // FIXME: 应该有PagingData返回值的版本？
  findAll(criteria?: T | undefined, field?: string | undefined, desc?: boolean | undefined, sorts?: string[] | undefined): Observable<Array<T>> {
    return this._modelService.findAll(this._domainCode, this._modelCode, criteria, field, desc, sorts);
  }

  pageQuery(criteria?: T | undefined, field?: string | undefined, desc?: boolean | undefined, sorts?: string[] | undefined): Observable<PagingData<T>> {
    return this._modelService.pageQuery(this._domainCode, this._modelCode, criteria, field, desc, sorts);
  }

  findById(id: number): Observable<T> {
    return this._modelService.findById(this._domainCode, this._modelCode, id);
  }
  findByIds(ids: number[]): Observable<PagingData<T>> {
    return this._modelService.findByIds(this._domainCode, this._modelCode, ids);
  }
  create(entity: T): Observable<T> {
    return this._modelService.save(this._domainCode, this._modelCode, entity);
  }
  createBatch(entities: T[]): Observable<T[]> {
    return this._modelService.createBatch(this._domainCode, this._modelCode, entities);
  }
  update(entity: T): Observable<T> {
    return this._modelService.save(this._domainCode, this._modelCode, entity);
  }
  save(entity: T): Observable<T> {
    return this._modelService.save(this._domainCode, this._modelCode, entity);
  }
  updateBatch(entities: T[]): Observable<T[]> {
    return this._modelService.updateBatch(this._domainCode, this._modelCode, entities);
  }
  deleteById(id: number): Observable<boolean> {
    return this._modelService.deleteById(this._domainCode, this._modelCode, id);
  }
  deleteByIds(ids: number[]): Observable<boolean[]> {
    return this._modelService.deleteByIds(this._domainCode, this._modelCode, ids);
  }
  deleteEntity(entity: T): Observable<boolean> {
    return this._modelService.deleteEntity(this._domainCode, this._modelCode, entity);
  }
  deleteEntities(entities: T[]): Observable<boolean[]> {
    return this._modelService.deleteEntities(this._domainCode, this._modelCode, entities);
  }
  downloadTemplate(): void {
    return this._modelService.downloadTemplate(this._domainCode, this._modelCode);
  }
  downloadById(fileName: string, id?: number | undefined): void {
    return this._modelService.downloadById(this._domainCode, this._modelCode, fileName, id);
  }
  getImgUrl(fileName: string, id?: number | undefined): Observable<string> {
    return this._modelService.getImgUrl(this._domainCode, this._modelCode, fileName, id);
  }
  downloadDataByCriteria(criteria: T, sortField?: string | undefined, sortDesc?: boolean | undefined, sorts?: string[] | undefined): void {
    this._modelService.downloadDataByCriteria(this._domainCode, this._modelCode, criteria, sortField, sortDesc, sorts);
  }
  downloadDataById(id: number): void {
    this._modelService.downloadDataById(this._domainCode, this._modelCode, id);
  }
  downloadDataByIds(ids: number[]): void {
    this._modelService.downloadDataByIds(this._domainCode, this._modelCode, ids);
  }
  uploadData(file: File): Observable<T[]> {
    return this._modelService.uploadData(this._domainCode, this._modelCode, file);
  }
  uploadAttachment(file: File): Observable<T[]> {
    return this._modelService.uploadAttachment(this._domainCode, this._modelCode, file);
  }
  copy(entity: T): T {
    return this._modelService.copy(entity);
  }
  printById(fileName: string, id?: number | undefined): void {
    this._modelService.printById(this._domainCode, this._modelCode, fileName, id);
  }
  printByIds(fileName: string, ids?: number[] | undefined): void {
    this._modelService.printByIds(this._domainCode, this._modelCode, fileName, ids);
  }
  assignEntitiesToNewOwner(ids: number[], newOwnerId: number): Observable<boolean> {
    return this._modelService.assignEntitiesToNewOwner(this._domainCode, this._modelCode, ids, newOwnerId);
  }
  assignEntityToNewOwner(id: number, newOwnerId: number): Observable<boolean> {
    return this._modelService.assignEntityToNewOwner(this._domainCode, this._modelCode, id, newOwnerId);
  }


  customizedPostCallWithDomainModelResponse(methodCode: string, para: any, responseDataMode?: ResponseDataCategory): Observable<any> {
    return this._modelService.makeHttpPostCall(this._domainCode, this._modelCode, methodCode, para, responseDataMode);
  }

  customizedGetCallWithDomainModelResponse(methodCode: string, responseDataMode?: ResponseDataCategory): Observable<any> {
    return this._modelService.makeHttpGetCall(this._domainCode, this._modelCode, methodCode, responseDataMode);
  }
}
