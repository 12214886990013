import { Component, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { APPCONSTANT } from 'src/app/app.constant';
import { AuthService } from 'src/service/auth.service';
import { RoutingService } from 'src/service/routing.service';
import { StorageService } from 'src/service/storage.service';

@Component({ template: `` })
export abstract class PageLoginBaseComponent {
  validateForm: FormGroup;

  private _messageService = inject(NzMessageService);
  private _translateService = inject(TranslateService);
  protected _routingService: RoutingService = inject(RoutingService);
  protected _routInfo: ActivatedRoute = inject(ActivatedRoute);
  private _storageService = inject(StorageService);
  private _authService = inject(AuthService);

  private _redirectUrl: string = '';
  protected _fb: FormBuilder = inject(FormBuilder);

  constructor() {
    this.validateForm = this._fb.group({});
  }
  ngOnInit(): void {
    this._routInfo.queryParams.subscribe(params => {
      this._redirectUrl = params[APPCONSTANT.URL_PARAMETER.REDIRECT_URL];
    });
  }

  getImageUrl(): string | undefined {
    return;
  }

  submitForm(): void {
    let loginName = this.getLoginName();
    if (loginName == null || loginName.length == 0) {
      return;
    }
    if (this.validateForm.valid) {
      let v = this.validateForm.value;
      this._authService.login(
        { username: loginName, password: v.password, verifyCode: v.verifyCode }
      ).subscribe(res => {
        if (res != null && res.id != null) {
          const casUser = res;
          this._storageService.setCasToken(casUser?.token);
          this._routingService.gotoUrlAfterLogin(this._redirectUrl);
        }
      });
    } else {
      console.error(this.validateForm.value);
    }
  }

  getTheValidateStatus(): string {
    return this.validateForm.controls['verifyCode'].valid ? 'sccess' : 'error';
  }

  getTitle(): string {
    return this._translateService.instant('auth.login.title') + this._translateService.instant('app.system');
  }

  onFetchVerificationCodeBtnClick(): void {
    let loginName = this.getLoginName();
    if (loginName == null || loginName.length == 0) {
      return;
    }
    if (this.validateForm.valid) {
      let v = this.validateForm.value;
      this._authService.verifyCodeRequest(
        { username: loginName, password: v.password, verifyCode: v.verifyCode }
      ).subscribe(res => {
        if(res) {
          console.log('Sending Verification Code.')
        } else {
          console.log('Can not send Verification Code.')
        }
      });
    } else {
      console.error(this.validateForm.value);
    }
  }
  protected abstract getLoginName(): string;
}
