import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PathName } from 'src/service/routing.service';
import { PageComingSoonComponent } from './page-coming-soon/page-coming-soon.component';
import { PageError404Component } from './page-error-404/page-error-404.component';
import { PageMaintenanceComponent } from './page-maintenance/page-maintenance.component';

const routes: Routes = [
  { path: PathName.NOT_FOUND, component: PageError404Component },
  { path: PathName.COMING_SOON, component: PageComingSoonComponent },
  { path: PathName.MAINTENANCE, component: PageMaintenanceComponent }
];
@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
  ],
  declarations: [
    PageError404Component,
    PageMaintenanceComponent,
    PageComingSoonComponent
  ],
  exports: [
    PageError404Component
  ]
})
export class PageUtilModule { }
