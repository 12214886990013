import { Component, inject, ViewChild } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { cloneDeep, isEmpty } from 'lodash';
import { interval, timer } from 'rxjs';
import { APP_RESOURCE } from 'src/app/app.constant';
import { ThemeService } from 'src/app/theme.service';
import { PjSideMenuItem } from 'src/component/pj-side-menu/pj-side-menu.type';
import { PjTabItemData } from 'src/component/pj-tab-group/pj-tab-item.type';
import { environment } from 'src/environments/environment';
import { Functionality, FunctionalityType } from 'src/model/authentication.model';
import { PjPageComponent } from 'src/page/page-component';
import { AuthService } from 'src/service/auth.service';
import { MetaDataHelper } from 'src/service/metadata.helper';
import { PathName } from 'src/service/routing.service';
import { Cargo_PathName, CargoRoutingService } from '../service/cargo.routing.service';
import { TableColumnConfigForField } from './page-cargo.table.type';

@Component({
  selector: 'page-cargo',
  templateUrl: './page-cargo.component.html',
  styleUrl: './page-cargo.component.less'
})
export class PageCargoComponent extends PjPageComponent {

  @ViewChild(RouterOutlet)
  private _subRouter?: RouterOutlet;

  private _authServie: AuthService = inject(AuthService);
  isCollapsed: boolean = false;

  private _sideMenuItems: PjSideMenuItem[] = []; //clone(PreDefinedSideMenuItems);
  private _cargoRoutingService: CargoRoutingService = inject(CargoRoutingService);

  private _themeService: ThemeService = inject(ThemeService);

  private _curUrlLocation: string = '';

  override ngOnInit(): void {
    super.ngOnInit();
    MetaDataHelper.setTableColumnConfigForFieldData(TableColumnConfigForField);
    interval(200).subscribe(() => {
      let urlLocation = location.pathname.substring(Cargo_PathName.CARGO.length + 2) + location.search;
      if (this._subRouter != null && this._subRouter.isActivated
        && this._subRouter.activatedRoute != null
        && this._curUrlLocation != urlLocation
      ) {
        this._curUrlLocation = urlLocation;
        this._generateTabItemFromUrl(urlLocation);
      }
    });
  }

  protected override pageInitForLoginUser(): void {
    if (this._sideMenuItems.length == 0) {
      this._generateSideMenuItemsFromLoginUser();
      this._generateTabItemFromUrl(this._curUrlLocation);
    }
  }

  private _generateSideMenuItemsFromLoginUser(): void {
    if (this._curLoginUser == null || this._curLoginUser.functionalityList == null
      || this._curLoginUser.functionalityList.length == 0
    ) {
      return;
    }
    let firstLevelMenus = this._curLoginUser.functionalityList.filter(
      menu => (menu.functionType == FunctionalityType.MENU && menu.parentMenu == null)
    ).sort((f1, f2) => Functionality.compare(f1, f2));
    if (isEmpty(firstLevelMenus)) {
      return;
    }
    this._sideMenuItems = [];
    firstLevelMenus.forEach(fun =>
      this._sideMenuItems.push(Functionality.convertFunctionalityToSideMenuItem(fun))
    );
    this._sideMenuItems.forEach(menu => {
      menu.children = this._generateSubMenus(menu);
      menu.children.forEach(subMenu => {
        subMenu.children = this._generateSubMenus(subMenu);
      });
    });
  }

  private _generateSubMenus(menu: PjSideMenuItem): PjSideMenuItem[] {
    let children: PjSideMenuItem[] = [];
    let secondLevelFunList = this._curLoginUser?.functionalityList?.filter(
      fun => (fun.functionType == FunctionalityType.MENU && fun.parentMenu != null && fun.parentMenu.id == menu.id)
    ).sort((f1, f2) => Functionality.compare(f1, f2));
    secondLevelFunList?.forEach(secondLevelFun =>
      children?.push(Functionality.convertFunctionalityToSideMenuItem(secondLevelFun, this._curLoginUser?.allDataResourceList))
    );
    return children;
  }

  onTabItemChanged(tabItem: PjTabItemData): void {
    if (tabItem.value == null) {
      return;
    }
    this._cargoRoutingService.gotoCargoPage(tabItem.value);
  }

  toggleTheme(): void {
    this._themeService.toggleTheme().then();
  }

  gotoTestPage(): void {
    this._cargoRoutingService.gotoUrl(PathName.TEST);
  }

  isDataManageEnabled(): boolean {
    return environment.enableMetaDataManager;
  }
  gotoDataManagePage(): void {
    this._cargoRoutingService.gotoUrl(PathName.DATA_MANAGE);
  }
  onSideHeaderClick(): void {
    this._cargoRoutingService.gotoCargoPage(Cargo_PathName.DASHBOARD);
  }

  getImageSrc(): string {
    return APP_RESOURCE.SMALL_LOGO;
  }

  getMenuItems(): PjSideMenuItem[] {
    return this._sideMenuItems;
  }

  onMenuItemClick(item: PjSideMenuItem): void {
    if (!isEmpty(item.actionString)) {
      this._cargoRoutingService.gotoCargoPage(item.actionString!);
    }
  }

  private _accountPanelVisible: boolean = false;
  isAccountPanelVisible(): boolean {
    return this._accountPanelVisible;
  }

  onOpenAccountPanelBtnClick(): void {
    this._accountPanelVisible = true;
  }

  onCloseAccountPannelBtnClick(): void {
    this._accountPanelVisible = false;
  }

  onLgoutBtnClick(): void {
    this._accountPanelVisible = false;
    this._authServie.logout();
  }

  private _tabItems: PjTabItemData[] = [{
    title: '首页', isActived: true, value: Cargo_PathName.DASHBOARD
  }];

  getTabItems(): PjTabItemData[] {
    return this._tabItems;
  }

  private _generateTabItemFromUrl(url: string): void {
    let selectedMenuItem: PjSideMenuItem | undefined = undefined;
    this._sideMenuItems.forEach(smi => {
      smi.expanded = false;
      let findSecondLevel: boolean = false;
      smi.children?.forEach(subMenuItem => {
        subMenuItem.focused = false;
        if (url == (subMenuItem.actionString || '---')) {
          findSecondLevel = true;
          selectedMenuItem = subMenuItem;
          smi.expanded = true && !this.isCollapsed;
          subMenuItem.focused = true;
        }
      });
      if (!findSecondLevel) {
        smi.children?.forEach(subMenuItem => {
          subMenuItem.children?.forEach(thirdItem => {
            thirdItem.focused = false;
            if (url == (thirdItem.actionString || '---')) {
              selectedMenuItem = thirdItem;
              subMenuItem.expanded = true && !this.isCollapsed;
              smi.expanded = true && !this.isCollapsed;
              thirdItem.focused = true;
            }
          });
        });
      }
    });
    if (selectedMenuItem != null && url != null) {
      timer(100).subscribe(() => {
        this._resetTabItems(selectedMenuItem!, url);
      });
    }
  }

  private _resetTabItems(selectedMenuItem: PjSideMenuItem, url: string): void {
    let ti: PjTabItemData | undefined = undefined;
    this._tabItems.forEach(_ti => {
      _ti.isActived = false;
      if (_ti.title == selectedMenuItem!.label) {
        ti = _ti;
        _ti.isActived = true;
      }
    });
    if (ti == null) {
      this._tabItems.push({
        title: (selectedMenuItem as PjSideMenuItem).label,
        isActived: true,
        value: url,
        closable: true
      });
    }
    this._tabItems = cloneDeep(this._tabItems);
  }
}
