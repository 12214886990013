import { Injectable } from '@angular/core';

const ThemeType = {
  default: 'default',
  dark: 'dark',
  aliyun: 'aliyun',
  compact: 'compact'
}

@Injectable({ providedIn: 'root' })
export class ThemeService {
  currentTheme = ThemeType.default;

  private reverseTheme(theme: string): string {
    let res: string = ThemeType.default;
    switch (theme) {
      case ThemeType.default: res = ThemeType.dark; break;
      case ThemeType.dark: res = ThemeType.aliyun; break;
      case ThemeType.aliyun: res = ThemeType.compact; break;
      case ThemeType.compact: res = ThemeType.default; break;
    }
    return res;
  }

  private removeUnusedTheme(theme: string): void {
    for (let key in ThemeType) {
      if (key != theme) {
        document.documentElement.classList.remove(key);
        const removedThemeStyle = document.getElementById(key);
        if (removedThemeStyle) {
          document.head.removeChild(removedThemeStyle);
        }
      }
    }
  }

  private loadCss(href: string, id: string): Promise<Event> {
    return new Promise((resolve, reject) => {
      const style = document.createElement('link');
      style.rel = 'stylesheet';
      style.href = href;
      style.id = id;
      style.onload = resolve;
      style.onerror = reject;
      document.head.append(style);
    });
  }

  public loadTheme(firstLoad = true): Promise<Event> {
    const theme = this.currentTheme;
    if (firstLoad) {
      document.documentElement.classList.add(theme);
    }
    return new Promise<Event>((resolve, reject) => {
      this.loadCss(`${theme}.css`, theme).then(
        (e) => {
          if (!firstLoad) {
            document.documentElement.classList.add(theme);
          }
          this.removeUnusedTheme(theme);
          resolve(e);
        },
        (e) => reject(e)
      );
    });
  }

  public toggleTheme(): Promise<Event> {
    this.currentTheme = this.reverseTheme(this.currentTheme);
    return this.loadTheme(false);
  }
}
