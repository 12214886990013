import { isEmpty } from "lodash";
import { PjSideMenuItem } from "src/component/pj-side-menu/pj-side-menu.type";
import { BackendCasGroup } from "./generated/cas-group.backend.model";
import { BackendCasRole } from "./generated/cas-role.backend.model";
import { BackendCasUserContactInfo } from "./generated/cas-user-contact-info.backend.model";
import { BackendCasUserDetail } from "./generated/cas-user-detail.backend.model";
import { BackendCasUser } from "./generated/cas-user.backend.model";
import { BackendDataResourceField } from "./generated/data-resource-field.backend.model";
import { BackendDataResourceOperation } from "./generated/data-resource-operation.backend.model";
import { BackendDataResource } from "./generated/data-resource.backend.model";
import { BackendFunctionality } from "./generated/functionality.backend.model";

export type Status = "ACTIVE" | "PENDING" | "LOCKED" | "DISABLED";

// 与后端的CasUser保持一致
export class CasUser extends BackendCasUser {
  // status?: Status;                 // TODO: 后端是不是应该定义枚举？

  nickname?: string;              // 是不是应该有昵称？
}

export class CasUserContactInfo extends BackendCasUserContactInfo {
}

export class CasUserDetail extends BackendCasUserDetail {
  // casUser?: CasUser;
  // groupList?: Array<CasGroup>;
  // roleList?: Array<CasRole>;
  // dataResourceList?: Array<DataResource>;
  // excludedFieldList?: Array<DataResourceField>;
  // dataResourceOperationList?: Array<DataResourceOperation>;
  // contactInfo?: CasUserContactInfo;
  // 后端为Transient，包括组及子组在内的可访问数据
  declare allDataResourceList?: Array<DataResource>;
  // 后端为Transient，包括组及父组在内的不能访问属性
  // allExcludedFieldList?: Array<DataResourceField>;
  // 后端为Transient
  declare allDataResourceOperationList?: Array<DataResourceOperation>;
  // 后端为Transient
  // functionalityList?: Array<Functionality>;
}

// unique: moduleServiceCode + modelCode
export class DataResource extends BackendDataResource {
  moduleServicePort?: string;
  // 领域名称， TODO: 是否需要国际化？
  // 领域模型名称， TODO: 是否需要国际化？

  // 保存领域模型/Controller所在的包全称，例如：com.polarj.core.base
  packageName?: string;
  // 保存该数据模型的可使用操作
  operationList?: Array<DataResourceOperation>;
}
export class DataResourceField extends BackendDataResourceField {
  // 领域模型的属性名称， TODO: 是否需要国际化？
}

export const DataResourceOperationTypeString = { INSTANCE: 'INSTANCE', CLASS: 'CLASS', OTHER: '' };
export const DataResourceOperationTypeConst = Object.keys(DataResourceOperationTypeString);
export type DataResourceOperationType = keyof typeof DataResourceOperationTypeString;
// export const PreDefinedClassOperation: PjKeyValue<DataResourceOperation> = {
//   CREATE: { apiCode: BackendEntityOperation.create }
// };
// export const PreDefinedInstanceOperation = {

// };

export class DataResourceOperation extends BackendDataResourceOperation {

  static generateDataResourceClassOperation(domainCode: string, modelCode: string,
    loginUserDataResList: DataResource[], requiredOpList: string[]
  ): DataResourceOperation[] {
    return DataResourceOperation.generateDataResourceOperation(
      DataResourceOperationTypeString.CLASS as DataResourceOperationType,
      domainCode, modelCode, loginUserDataResList, requiredOpList);
  }

  static generateDataResourceInstanceOperation(domainCode: string, modelCode: string,
    loginUserDataResList: DataResource[], requiredOpList: string[]
  ): DataResourceOperation[] {
    return DataResourceOperation.generateDataResourceOperation(
      DataResourceOperationTypeString.INSTANCE as DataResourceOperationType,
      domainCode, modelCode, loginUserDataResList, requiredOpList);
  }

  private static generateDataResourceOperation(type: DataResourceOperationType, domainCode: string,
    modelCode: string, loginUserDataResList: DataResource[], requiredOpList: string[]
  ): DataResourceOperation[] {
    let dataResOpList: DataResourceOperation[] = [];
    let dataRes = loginUserDataResList.find(dataRes => {
      let b = (dataRes.domainCode == domainCode);
      b = b && (dataRes.modelCode == modelCode);
      return b;
    });
    requiredOpList.forEach(reqOp => {
      let foundOp = dataRes?.operationList?.find(op => {
        let pos = op.apiCode?.lastIndexOf('.') || -1;
        let requiredOp = op.apiCode?.substring(pos == -1 ? 0 : (pos + 1));
        return (requiredOp == reqOp) && (type == op.operationType);
      });
      if (foundOp != null) {
        dataResOpList.push(foundOp);
      }
    });
    return dataResOpList;
  }
}
export class Functionality extends BackendFunctionality {

  // TODO: 后端枚举FunctionalityType
  // parentMenu?: Functionality;
  // TODO: 对应DataResourceOperation.code？

  static compare(fun1: Functionality, fun2: Functionality): number {
    return (fun1.position || 0) - (fun2.position || 0);
  }

  static convertFunctionalityToSideMenuItem(functionality: Functionality, loginUserDataResources?: DataResource[]): PjSideMenuItem {
    let smi: PjSideMenuItem = {
      id: functionality.id,
      label: functionality.functionName || '---',
      iconName: functionality.iconName,
      actionString: Functionality._fromOperationCodeToActionString(functionality.operationCode, loginUserDataResources)
    };
    return smi;
  }

  private static _fromOperationCodeToActionString(opCode?: string, dataResrcList?: DataResource[]): string | undefined {
    // let dataResrcList = this._curLoginUser?.allDataResourceList;
    let domainCode: string | undefined = undefined;
    let modelCode: string | undefined = undefined;
    dataResrcList?.forEach(dataResrc => {
      let op = dataResrc.operationList?.find(op => op.operationCode == opCode);
      if (op != null) {
        domainCode = dataResrc.domainCode;
        modelCode = dataResrc.modelCode;
      }
    });
    if (isEmpty(domainCode) || isEmpty(modelCode)) {
      return;
    }
    let menuItemUrl = `foundation?domainCode=${domainCode}&modelCode=${modelCode}`;
    // if (modelCode != null) {
    //   switch (modelCode) {
    //     case 'com.polarj.oms.model.BookingOrder':
    //       menuItemUrl = Cargo_PathName.BOOKING_ORDER;
    //       break;
    //     case 'com.polarj.crm.model.CustomerInfo':
    //       menuItemUrl = Cargo_PathName.CUSTOMER;
    //       break;
    //   }
    // }
    return menuItemUrl;
  }
}

export class CasGroup extends BackendCasGroup {
  // status?: Status; // 激活状态
}

export class CasRole extends BackendCasRole {
}

export enum FunctionalityType {
  MENU = 'MENU',
  BUTTON = 'BUTTON'
}