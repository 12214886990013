<nz-input-group [nzPrefix]="prefixIcon" [nzSuffix]="suffixIcon" [nzSize]="size">
  <input [type]="getInputType()" nz-input [ngModel]="textValue" [placeholder]="placeHolder"
    (ngModelChange)="onNgModelChange($event)" (keypress)="onKeyPress($event)" [disabled]="disabled" (blur)="onBlur()" />
</nz-input-group>

<ng-template #prefixIcon>
  <pj-icon *ngIf="iconName.length>0" class="icon" [iconName]="iconName"></pj-icon>
</ng-template>

<ng-template #suffixIcon>
  <pj-icon *ngIf="scannable" class="icon" iconName="fullscreen" (click)="onScanBtnClick()"></pj-icon>
</ng-template>