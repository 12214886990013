import { Directive, Input, TemplateRef } from "@angular/core";

@Directive({ selector: "[tabItemTemplateName]" })
export class PjTabItemContentDirective {
  @Input()
  tabItemTemplateName?: string;
  @Input()
  isActived: boolean = false;
  @Input()
  preLoad: boolean = false;
  constructor(private _templateRef: TemplateRef<any>) { }
  getTemplateRef(): TemplateRef<any> {
    return this._templateRef;
  }
}
