
// 领域模型属性的表格列属性的设置数据
export const TableColumnConfigForField: any = {
  'com.polarj.cargo.model.AirRoute': {
    airRoute: { width: 150, align: 'left' },
    airlineCode: { width: 100, align: 'center' },
    departPort: { width: 100, align: 'center' },
    arrivingPort: { width: 100, align: 'center' },
    transferBillingType: { width: 250, align: 'left' },
  },
  'com.polarj.crm.model.CustomerContact': {
    'type': { width: 100, align: 'center' },
    'name': { width: 60, align: 'center' },
    'mobile': { width: 100, align: 'center' },
    'phone': { width: 120, align: 'center' },
    'email': { width: 150, align: 'left' },
  }
}