import { FileInfo } from "src/model/attachment.model";

export type PjSize  = 'large' | 'default' | 'small';

export type PjColor = 'primary' | 'error' | 'warning' | 'info' | 'success';

export class PjFileInfo extends FileInfo {
  index?: number;
}

export interface PjLabelValueItem {
  label: string;
  value: any;
}

// 可用于各种需要组合选择的组件中：dropdown, selection, radio, checkbox, ...
export class PjSelectionItem implements PjLabelValueItem {
  iconName?: string; // 图标，显示在 label+description 左边
  imageName?: string; // 被设置了宽度和高度的图片， 显示在 label+description 左边
  position?: number; // item的排序参数
  label: string; // Item显示的内容
  value: any; // 作为下拉选项，该选项的值
  description?: string; // Label下显示的内容
  badgeLabel?: string; // 在 Item 右边显示的徽章
  groupName?: string; // 有相同group name的 toggleable item 只能选其中之一
  groupMadatory?: boolean; // 在同一组中必须选一个
  toggleable?: boolean; // 可（选/不选）的项
  selected?: boolean; // 该项（toggleable=true）被选中
  divider?: boolean; // 仅仅是个分割符，该值为真，则其他值都没有意义
  actionString?: string; // 如果是菜单项目，单击该项的操作
  disabled?: boolean; // 是否禁止点击的项
  subMenus?: Array<PjSelectionItem>; // 下一级下拉项

  constructor(s: PjSelectionItem) {
    this.label = s.label;
    this.value = s.value;
  }

  static compare(s1: PjSelectionItem, s2: PjSelectionItem): number {
    return (s1.position || 0) - (s2.position || 0);
  }
}


export class PjAction {
  sn?: number; // which position this action should be.
  iconName?: string;
  imageSrc?: string;
  imageWidth?: number;
  imageType?: string;
  label: string = 'Action';
  description?: string;
  actionLabel?: string;
  actionIcon?: string;
  actionString?: string;
  selected?: boolean;
  disabled?: boolean;
  cb?: Function;
}

export enum PjDirectionEnum {
  H = 'horizontal',
  V = 'vertical',
}

export type PjDirectionType = 'horizontal' | 'vertical';

export class PjActionEvent {
  actionString: string;
  para?: PjKeyValue<any>;

  constructor(entity: PjActionEvent) {
    this.actionString = entity.actionString;
    this.para = entity.para;
  }

  static isPathAction(action: PjActionEvent): boolean {
    if (action.actionString?.startsWith('action_')) {
      return false;
    }
    return true;
  }
}

export interface PjProperty {
  [key: string]: string;
}

export interface PjKeyValue<T> {
  [key: string]: T;
}

export const PJ_ACTION = {
  UNKNOW: 'action_unknow',
  CONFIRM: 'action_confirm',
  SAVE: 'action_save',
  CLOSE: 'action_close',
  DETAIL: 'action_detail',
  EDIT: 'action_edit',
  CANCEL: 'action_cancel',
  REMOVE: 'action_remove',
  CLEAR: 'action_clear',
  REFRESH: 'action_refresh',
  REMOVE_ALL: 'action_remove_all',
  GO_HOME: 'action_goto_homepage',
  GO_BACK: 'action_goto_back',
  LOGIN_SWITCH_EMAIL: 'action_login_switch_email',
  LOGIN_EMAIL_VERIFY: 'action_login_email_verify',
  LOGIN_PASSWORD_VERIFY: 'action_login_password_verify',
  LOGIN_FORGET_PASSWORD: 'action_login_forget_password',
  LOGIN_RESEND_CODE: 'action_login_resend_code',
  LOGIN_ONE_TIME_LINK: 'action_login_one_time_link',
  LOGIN_CONTINUE_RESET_PASSWORD: 'action_continue_reset',
  LOGOUT: 'action_logout',

  LOGIN: 'action_login',
  CREATION: 'action_creation',
  REGISTER: 'action_register',
  VERIFICATION: 'action_verify',
  CAPTCHA_CHECKED: 'action_captcha_checked',
  CAPTCHA_UNCHECKED: 'action_captcha_unchecked',
  PASSWORD_RESET_OPTION_EMAIL: 'action_reset_by_email',
  PASSWORD_RESET_OPTION_PHONE: 'action_reset_by_phone',
  PASSWORD_RESET_OPTION_QUESTION: 'action_reset_by_question',
  SIGN_UP: 'action_sign_up',
  CONTINUE: 'action_continue',

  LEGAL_DOC: 'action_legal_doc',
  ADDRESS: 'action_address',

  SEARCH_ASYNC_KEYWORD_CHANGED: 'action_async_keyword_changed',
  SEARCH_KEYWORD_CHANGED: 'action_keyword_changed',
  SEARCH_BACK_BTN_CLICK: 'action_back_btn_click',

  AUTO_COMPLETE_KEYWORD_CHANGED: 'action_async_keyword_changed',
  AUTO_COMPLETE_VALUE_SETTING: 'action_value_setting',
};

export const PjActionStringConst = {
  SAVE: 'action-save',
  CANCEL: 'action-cancel',
  OK: 'action-ok',
  BACK: 'action-back',
  CLICK: 'action-click'
};
export const PjActionConst = {
  SAVE: { label: 'Save', actionString: PjActionStringConst.SAVE, iconName: 'save' },
  CANCEL: { label: 'Cancel', actionString: PjActionStringConst.CANCEL, iconName: 'cancel' },
  OK: { label: 'OK', actionString: PjActionStringConst.OK, iconName: 'thumb_up' },
  CONFIRM: { label: 'Confirm', actionString: PjActionStringConst.OK, iconName: 'done' },
  BACK: { label: 'Back', actionString: PjActionStringConst.BACK, iconName: 'arrow_back' },
}
