<div class="flex flex-row items-center gap-2">
  <div *ngIf="iconBtn" class="flex flex-row items-center gap-2">
    <pj-icon-button [btnType]="btnType" *ngFor="let action of getShownActions()" [iconName]="action.iconName || 'home'"
      (click)="onBtnClick(action, $event)">
    </pj-icon-button>
  </div>
  <div *ngIf="!iconBtn" class="flex flex-row items-center gap-2">
    <pj-std-button [btnType]="btnType" *ngFor="let action of getShownActions()" [label]="action.label"
      (click)="onBtnClick(action, $event)">
    </pj-std-button>
  </div>
  <pj-icon-button *ngIf="hasMoreBtn()" [btnType]="btnType" [iconName]="moreIconName"
    [dropdownMenus]="getHiddenActions()" (actionClick)="onDropdownMenuClick($event)">
  </pj-icon-button>
</div>