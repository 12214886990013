import { PjKeyValue } from "src/component/component.type";
import { BackendSelectionData } from "./generated/selection-data.backend.model";

export class SelectionData extends BackendSelectionData {

  static convertToLocalSelectionDataMap(selectionDataList?: SelectionData[]): LocalSelectionDataMap {
    let sdMap: LocalSelectionDataMap = {
      timestamp: (new Date()).getTime(),
      selectionDataMap: {}
    }
    selectionDataList?.forEach(selectionData => {
      if (selectionData.enumerationName != null && selectionData.enumerationName.length > 0) {
        if (sdMap.selectionDataMap[selectionData.enumerationName] == null) {
          sdMap.selectionDataMap[selectionData.enumerationName] = [];
        }
        sdMap.selectionDataMap[selectionData.enumerationName].push({
          label: selectionData.label, value: selectionData.value
        });
      }
    });
    return sdMap;
  }
}

export interface LocalSelectionDataMap {
  timestamp: number;
  selectionDataMap: PjKeyValue<SelectionData[]>;
}