import { isEmpty } from "lodash";

export enum ServerError {
  'Authentication' = 'Authentication.Error',
  'Authorization' = 'Authorization.Error',
  'Web' = 'Web.Error'
}

export enum ResponseDataCategory {
  SingleModel = 'SingleModel', ListOfModel = 'ListOfModel', PageOfModel = 'PageOfModel'
}

export interface ModuleServiceConfig {
  moduleServiceCode: string;
  protocal: string;
  hostName: string;
  port: string;
}

export class HttpCallParameter {
  loading?: boolean; // 访问后端时是否需要正在加载的弹窗

  moduleServiceConfig: ModuleServiceConfig = {
    moduleServiceCode: '',
    protocal: '',
    hostName: '',
    port: ''
  };
  modelCode: string = ''; // 模型文档中 Controller 的第一个path

  requestMappingString?: string = ''; //url参数

  urlPara?: string; // ？+ 后面的拼接参数/dockinfos?id=1
  operationCode?: string; // 方法对应的操作编号，用于后端鉴权
  captchaResp?: string;

  // 是否前端自动访问后端的操作，缺省值为不是，相当于假定访问服务器数据的操作都是用户主动的操作，
  // 前端保存访问的时间，作为用户在线的标志，该标志影响替匿名用户保存的数据。
  // 登录的用户其使用数据在登出时自动删除，如果没有主动登出，则在下次登录时主动删除？
  // 每次登录时从服务器获取相关的需要本地暂存的数据？
  // 周期性（指定分钟数）更新登录用户被修改过的本地保存数据？
  operateAutomatically?: boolean;

  // contextUrl: string = '';            
  // https://[host]:[port]/[moduleServiceCode]/[contextUrl] TODO: 应该从服务器获取业务模型所在的module service name？
  enableErrorMsg?: boolean;
  pageData?: boolean;
  singleData?: boolean;
  listData?: boolean;
  static isValid(para: HttpCallParameter): boolean {
    if (
      isEmpty(para.moduleServiceConfig.moduleServiceCode) ||
      isEmpty(para.modelCode)
    ) {
      return false;
    }
    return true;
  }
}

export class PostCallParameter extends HttpCallParameter {
  para?: any;
}

export class GetCallParameter extends HttpCallParameter { }

export class DeleteCallParameter extends HttpCallParameter { }

// 对应后端的com.polarj.common.web.model.ClientRequest<T>
export class ClientRequest {
  nonceToken?: string;
  data: any;
  captchaResp?: string;
}

// 对应后端的com.polarj.common.StatusInfo
class StatusInfo {
  referenceNo?: string;
  level?: string;
  code?: string;
  desc?: string;
}
export enum StatusLevel {
  INFO = 'INFO', WARN = 'WARN', ERROR = 'ERROR'
}
//发送数据

export interface PagingInfo {
  totalRecords?: number;
  totalPages?: number;
  pageIndex?: number; // 从 1 开始
  pageSize?: number;
}

export class PagingData<T> implements PagingInfo {

  totalRecords?: number;
  totalPages?: number;
  pageIndex?: number;
  pageSize?: number;

  dataList: Array<T>;

  constructor(entity?: PagingData<T>) {
    this.dataList = entity?.dataList ?? [];
  }

  static convertDataListToPagingData(dataList: any[]): PagingData<any> {
    return {
      totalRecords: dataList.length,
      dataList: dataList,
      totalPages: 1,
      pageSize: dataList.length,
      pageIndex: 1,
    };
  }
}
export const EmptyPagingData: PagingData<any> = {
  totalRecords: 0, totalPages: 0, pageIndex: 1,
  pageSize: 25, dataList: []
}

export class ResponseBase<T> {
  statusList?: Array<StatusInfo>;
  dataList: Array<T>;

  constructor(entity?: ResponseBase<T>) {
    this.dataList = entity?.dataList ?? [];
  }

}

export class ServerResponse<T> extends ResponseBase<T> {
  nonceToken?: string;
  totalRecords?: number;
  totalPages?: number;
  currentPageIndex?: number;  // 服务器数据从 0 开始
  pageSize?: number;

  addErrorMessage(errCode: string, errMsg: string): void {
    if (!this.statusList) {
      this.statusList = new Array<StatusInfo>();
    }
    const statInfo = new StatusInfo();
    statInfo.level = StatusLevel.ERROR;
    statInfo.desc = errMsg;
    statInfo.code = errCode;
    this.statusList?.push(statInfo);
  }

  static hasErrorMessage(response: any): boolean {
    let b: boolean = false;
    if (response == null || response.statusList == null || response.statusList.length == 0) {
      return false;
    }
    (response as ServerResponse<any>).statusList?.forEach(stat => {
      b = b || (stat.level == StatusLevel.ERROR);
    })
    return b;
  }

  static getFirstErrorMessage(response: ServerResponse<any> | undefined): string | undefined {
    let res: string | undefined = undefined;
    if (response != null && response.statusList != null && response.statusList.length > 0) {
      if (response.statusList[0].level == StatusLevel.ERROR) {
        res = response.statusList[0].desc;
      }
    }
    return res;
  }
}
