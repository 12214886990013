import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PjButtonComponent } from '../pj-button.component';

@Component({
  selector: 'pj-favorite-button',
  templateUrl: './pj-favorite-button.component.html',
  styleUrl: './pj-favorite-button.component.less'
})
export class PjFavoriteButtonComponent extends PjButtonComponent {

  @Input()
  favorited: boolean = false;

  @Output()
  favoritedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  getIconName(): string {
    return this.favorited ? 'favorite' : 'favorite_border';
  }

  onClick(): void {
    this.favorited = !this.favorited;
    this.favoritedChange.emit(this.favorited);
  }
}
