import { EventEmitter, inject } from "@angular/core";
import { NzMessageService } from "ng-zorro-antd/message";
import { NzModalService } from "ng-zorro-antd/modal";
import { throttleTime } from "rxjs";
import { APPCONSTANT } from "src/app/app.constant";
import { ServerError, ServerResponse, StatusLevel } from "./httpcall.type";
import { RestfulService } from "./restful.service";
import { RoutingService } from "./routing.service";
import { StorageService } from "./storage.service";

export abstract class PolarJService {
  private _modalWinService: NzModalService = inject(NzModalService);
  private _messageService = inject(NzMessageService);
  protected _storageService = inject(StorageService);
  protected _restfulService = inject(RestfulService);
  private _routingService = inject(RoutingService);

  private _errorMsg$: EventEmitter<string> = new EventEmitter();
  private _logoutErrorMsg$: EventEmitter<string> = new EventEmitter();
  constructor() {
    this._errorMsg$.pipe(
      throttleTime(500)
    ).subscribe(errMsg => this._messageService.error(errMsg));
    this._logoutErrorMsg$.pipe(
      throttleTime(500)
    ).subscribe(errMsg => this._modalWinService.error({
      nzTitle: '<b>ERROR</b>',
      nzContent: errMsg,
      nzOkText: 'Yes',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => {
        this._clearLocalDataAndGotoLogin();
        this._routingService.gotoLoginPage();
      },
      nzCancelDisabled: true
    }));
  }

  protected handlePossibleError(serverResp: any): void {
    if (serverResp == null) {
      this._logoutErrorMsg$.emit('Unknow system error, please contact system support!')
      return;
    }
    const serResp: ServerResponse<any> = serverResp as ServerResponse<any>;
    if (serResp.statusList != null) {
      if (serResp.statusList.length > 0) {
        serResp.statusList.forEach(statusInfo => {
          if (statusInfo.level == StatusLevel.ERROR) {
            if (statusInfo.code == ServerError.Authentication) {
              this._logoutErrorMsg$.emit(statusInfo.desc || 'Unknow system error');
            } else if (statusInfo.code == ServerError.Authorization) {
              this._errorMsg$.emit(statusInfo.desc);
            } else {
              this._errorMsg$.emit(`${statusInfo.referenceNo || 0}: ${statusInfo.desc}`);
            }
          }
        })
      } else {
        this._logoutErrorMsg$.emit('Unknow system error, please contact system support!')
      }
    }
  }

  protected _clearLocalDataAndGotoLogin(): void {
    this._removeLocalCredential();
    this._removeLocalMeta();
    this._routingService.gotoLoginPage();
  }
  private _removeLocalCredential(): void {
    this._storageService.removeLocalItem(
      APPCONSTANT.LOCAL_STORAGE_ITEM_NAME.LOGIN_USER,
    );
    this._storageService.removeCasToken();
  }

  private _removeLocalMeta(): void {
    // 获取所有领域，删除元数据?
    // for (let moduleName in MODULE_SERVICE_NAME) {
    //   this._storageService.removeLocalItem(
    //     APPCONSTANT.LOCAL_STORAGE_ITEM_NAME.METADATA + moduleName.toLowerCase()
    //   );
    // }
  }
} 