import { Injectable } from "@angular/core";
import { cloneDeep, isEmpty } from "lodash";
import { Observable, filter, map, of, tap } from "rxjs";
import { APPCONSTANT } from "src/app/app.constant";
import { PjKeyValue } from "src/component/component.type";
import { CASConfig } from "src/environments/service.config";
import { CasUserDetail } from "src/model/authentication.model";
import { FrontModelMetaData } from "src/model/front-model-metadata";
import { PersistenceEntity } from "src/model/persistence-entity";
import { DeleteCallParameter, GetCallParameter, HttpCallParameter, ModuleServiceConfig, PagingData, PostCallParameter, ResponseDataCategory } from "./httpcall.type";
import { PolarJService } from "./polarj.service";





@Injectable({ providedIn: "root" })
export class ModelServiceHttpImpl extends PolarJService {

  // 保存每一个可能要访问的领域模型接口的访问参数, key为domainCode-modelCode
  private _modelModuleServiceConfigMap: PjKeyValue<ModuleServiceConfig> = {};
  // 保存每个方法的operationCode，key为domainCode-modelCode-methodName，methodName是每个访问后端数据的方法固定的，用常数保存
  private _methedOperationCodeMap: PjKeyValue<string> = {};
  // private _loginUser: CasUserDetail = new CasUserDetail();
  constructor() {
    super()
    this._storageService.storageChanged.subscribe(key => {
      if (key === '' || key === APPCONSTANT.LOCAL_STORAGE_ITEM_NAME.LOGIN_USER
        || key === APPCONSTANT.LOCAL_STORAGE_ITEM_NAME.MODULE_SERVICE_INFO) {
        this._initServiceData();
      }
    });
    this._initServiceData();
  }

  getAllMetaData() {
    const keys = this._storageService.getLocalKeys();
    const metaKeys = keys.filter((key) => key.startsWith(APPCONSTANT.LOCAL_STORAGE_ITEM_NAME.METADATA_PRRFIX));
    const metaData = metaKeys.map((key) => this._storageService.getLocalItem(key));
    return metaData;
  }

  // 后端对应方法名：getModelMetadata
  getMetaData(domainCode: string, modelCode: string): Observable<FrontModelMetaData | null> {
    const _modelContextPathCode = this._generateBackendControllerContextPath(modelCode);
    const localMeta = this._storageService.getModelMeta(_modelContextPathCode);
    const parama: GetCallParameter = {
      ...this.generateHttpParam(domainCode, modelCode, ModelRequestMappingAndMethod.METADATA.method),
      operateAutomatically: false,
      captchaResp: '',
      requestMappingString: ModelRequestMappingAndMethod.METADATA.requestMapping,
      urlPara: localMeta?.lastUpdateTimestamp === undefined ? undefined : "lastUpdateTimestamp=" + localMeta.lastUpdateTimestamp
    }
    // FIXME: 从服务器获取元数据的机制需要讨论！
    if (localMeta?.lastUpdateTimestamp != null) {
      return of(localMeta);
    }
    return this._makeHttpCall('get', parama).pipe(
      filter(m => m != null),
      // 如果数据为空数组，代表元数据没有更新，可以直接从本地读取
      map(meta => {
        if (meta.length !== 0) {
          // 存储元数据信息
          this._saveMetaData(meta);
        }
        const allMetaData = this.getAllMetaData();
        // const metaData = ModelMetaDataUtil.formatMetaDatas(allMetaData);
        const target = allMetaData.find(_meta => modelCode === _meta.modelName);
        return target ?? null;
      }),
    );
  }

  pageQuery<T extends PersistenceEntity>(domainCode: string, modelCode: string, criteria?: T,
    field?: string, desc?: boolean, sorts?: string[]): Observable<PagingData<any>> {
    const fieldValue = field ? `field=${field}` : "";
    const descValue = field && desc !== undefined ? `&desc=${desc}` : "";
    const parama: PostCallParameter = {
      ...this.generateHttpParam(domainCode, modelCode, ModelRequestMappingAndMethod.PAGE_QUERY.method),
      operateAutomatically: false,
      captchaResp: '',
      para: criteria || {},
      requestMappingString: ModelRequestMappingAndMethod.PAGE_QUERY.requestMapping,
      urlPara: fieldValue + descValue,
      pageData: true,
    };
    return this._makeHttpCall('post', parama);
  }
  findAll<T extends PersistenceEntity>(domainCode: string, modelCode: string, criteria?: T,
    field?: string, desc?: boolean, sorts?: string[]): Observable<Array<any>> {
    const fieldValue = field ? `field=${field}` : "";
    const descValue = field && desc !== undefined ? `&desc=${desc}` : "";
    const parama: PostCallParameter = {
      ...this.generateHttpParam(domainCode, modelCode, ModelRequestMappingAndMethod.QUERY.method),
      operateAutomatically: false,
      captchaResp: '',
      para: criteria || {},
      requestMappingString: ModelRequestMappingAndMethod.QUERY.requestMapping,
      urlPara: fieldValue + descValue,
      listData: true,
    };
    return this._makeHttpCall('post', parama);
  }

  findById<T extends PersistenceEntity>(domainCode: string, modelCode: string, id: number): Observable<T> {
    const parama: GetCallParameter = {
      ...this.generateHttpParam(domainCode, modelCode, ModelRequestMappingAndMethod.FIND_BY_ID.method),
      requestMappingString: `${id}`,
      loading: true,
      singleData: true,
    };
    return this._makeHttpCall('get', parama);
  }

  findByIds<T extends PersistenceEntity>(domainCode: string, modelCode: string, ids: number[]): Observable<PagingData<T>> {
    throw new Error("Method not implemented.");
  }

  save<T extends PersistenceEntity>(domainCode: string, modelCode: string, entity: T): Observable<T> {
    if (entity.id != null) {
      return this.update(domainCode, modelCode, entity);
    } else {
      return this.create(domainCode, modelCode, entity);
    }
  }
  private create<T extends PersistenceEntity>(domainCode: string, modelCode: string, entity: T): Observable<T> {
    PersistenceEntity.removeUselessFieldForLocalStorageAndSubmission(entity);
    const parama: PostCallParameter = {
      ...this.generateHttpParam(domainCode, modelCode, ModelRequestMappingAndMethod.CREATE.method),
      requestMappingString: ModelRequestMappingAndMethod.CREATE.requestMapping,
      para: entity, singleData: true
    };
    return this._makeHttpCall('post', parama);
  }

  private update<T extends PersistenceEntity>(domainCode: string, modelCode: string, entity: T): Observable<T> {
    PersistenceEntity.removeUselessFieldForLocalStorageAndSubmission(entity);
    entity.valueRemovedFields = []; // FIXME: 所有数据都保留的方式进行数据更新。
    const parama: PostCallParameter = {
      ...this.generateHttpParam(domainCode, modelCode, ModelRequestMappingAndMethod.UPDATE.method),
      requestMappingString: ModelRequestMappingAndMethod.UPDATE.requestMapping,
      para: entity,
      singleData: true,
    };
    return this._makeHttpCall('post', parama);
  }
  createBatch<T extends PersistenceEntity>(domainCode: string, modelCode: string, entities: T[]): Observable<T[]> {
    entities?.forEach(entity => PersistenceEntity.removeUselessFieldForLocalStorageAndSubmission(entity));
    const parama: PostCallParameter = {
      ...this.generateHttpParam(domainCode, modelCode, ModelRequestMappingAndMethod.CREATE_BATCH.method),
      requestMappingString: ModelRequestMappingAndMethod.CREATE_BATCH.requestMapping,
      para: entities,
      listData: true,
    };
    return this._makeHttpCall('post', parama);
  }
  updateBatch<T extends PersistenceEntity>(domainCode: string, modelCode: string, entities: T[]): Observable<T[]> {
    entities?.forEach(entity => PersistenceEntity.removeUselessFieldForLocalStorageAndSubmission(entity));
    throw new Error("Method not implemented.");
  }
  deleteById(domainCode: string, modelCode: string, id: number): Observable<boolean> {
    const parama: DeleteCallParameter = {
      ...this.generateHttpParam(domainCode, modelCode, ModelRequestMappingAndMethod.DELETE_BY_ID.method),
      requestMappingString: `${id}`,
      singleData: true,
    };
    return this._makeHttpCall('delete', parama);
  }
  deleteByIds(domainCode: string, modelCode: string, ids: number[]): Observable<boolean[]> {
    const parama: PostCallParameter = {
      ...this.generateHttpParam(domainCode, modelCode, ModelRequestMappingAndMethod.DELETE_BY_IDS.method),
      requestMappingString: ModelRequestMappingAndMethod.DELETE_BY_IDS.requestMapping,
      para: ids,
    };
    return this._makeHttpCall('post', parama);
  }
  deleteEntity<T extends PersistenceEntity>(domainCode: string, modelCode: string, entity: T): Observable<boolean> {
    const parama: PostCallParameter = {
      ...this.generateHttpParam(domainCode, modelCode, ModelRequestMappingAndMethod.DELETE.method),
      requestMappingString: ModelRequestMappingAndMethod.DELETE.requestMapping,
      para: entity,
    };
    return this._makeHttpCall('post', parama);
  }
  deleteEntities<T extends PersistenceEntity>(domainCode: string, modelCode: string, entities: T[]): Observable<boolean[]> {
    let idList: number[] = [];
    entities.forEach(entity => {
      if (entity.id != null) {
        idList.push(entity.id);
      }
    });
    return this.deleteByIds(domainCode, modelCode, idList);
  }
  downloadTemplate(domainCode: string, modelCode: string): void {
    const parama: GetCallParameter = {
      ...this.generateHttpParam(domainCode, modelCode, ModelRequestMappingAndMethod.DOWNLOAD_TEMPLATE.method),
      requestMappingString: ModelRequestMappingAndMethod.DOWNLOAD_TEMPLATE.requestMapping,
    };
    if (parama.moduleServiceConfig) {
      this._restfulService.downloadCall(parama, this._generateBackendControllerContextPath(modelCode) + '-template.xlsx');
    }
  }

  downloadById(domainCode: string, modelCode: string, fileName: string, id?: number): void {
    const parama: GetCallParameter = {
      ...this.generateHttpParam(domainCode, modelCode, ModelRequestMappingAndMethod.DOWNLOAD_FILE.method),
      requestMappingString: ModelRequestMappingAndMethod.DOWNLOAD_FILE.requestMapping,
      urlPara: `fileName=${fileName}${id !== undefined ? `&id=${id}` : ''}`,
    };
    if (parama.moduleServiceConfig) {
      this._restfulService.downloadCall(parama);
    }
  }
  getImgUrl(domainCode: string, modelCode: string, fileName: string, id?: number): Observable<string> {
    const parama: GetCallParameter = {
      ...this.generateHttpParam(domainCode, modelCode, ModelRequestMappingAndMethod.GET_IMG_URL.method),
      requestMappingString: ModelRequestMappingAndMethod.GET_IMG_URL.requestMapping,
      urlPara: `fileName=${fileName}${id !== undefined ? `&id=${id}` : ''}`,
    };
    return this._makeHttpCall('get', parama);
  }

  downloadDataByCriteria<T extends PersistenceEntity>(domainCode: string, modelCode: string, criteria?: T, sortField?: string, sortDesc?: boolean, sorts?: string[]): void {
    const fieldValue = sortField ? `field=${sortField}` : '';
    const descValue = sortField && sortDesc !== undefined ? `&desc=${sortDesc}` : "";

    const parama: PostCallParameter = {
      ...this.generateHttpParam(domainCode, modelCode, ModelRequestMappingAndMethod.DOWNLOAD_BY_CRITERIA.method),
      requestMappingString: ModelRequestMappingAndMethod.DOWNLOAD_BY_CRITERIA.requestMapping,
      para: criteria || {},
      urlPara: fieldValue + descValue,
    };
    if (parama.moduleServiceConfig) {
      // this._restfulService.downloadPostCall(parama);
      this._restfulService.downloadPostCall(parama, parama.para);
    }
  }
  downloadDataById(domainCode: string, modelCode: string, id: number): void {
    this.downloadDataByIds(domainCode, modelCode, [id]);
  }
  downloadDataByIds(domainCode: string, modelCode: string, ids: number[]): void {
    const parama: GetCallParameter = {
      ...this.generateHttpParam(domainCode, modelCode, ModelRequestMappingAndMethod.DOWNLOAD_BY_IDS.method),
      requestMappingString: ModelRequestMappingAndMethod.DOWNLOAD_BY_IDS.requestMapping,
      urlPara: 'ids=' + ids.join(',')
    };
    if (parama.moduleServiceConfig) {
      this._restfulService.downloadCall(parama);
    }
  }
  uploadData<T extends PersistenceEntity>(domainCode: string, modelCode: string, file: File): Observable<T[]> {
    const parama: PostCallParameter = {
      ...this.generateHttpParam(domainCode, modelCode, ModelRequestMappingAndMethod.UPLOAD_MODEL_DATA.method),
      requestMappingString: ModelRequestMappingAndMethod.UPLOAD_MODEL_DATA.requestMapping
    }
    let httpCall$: Observable<any> = of();
    if (parama.moduleServiceConfig) {
      httpCall$ = this._restfulService.uploadCall(parama, [file]);
    }
    return httpCall$;
  }
  uploadAttachment<T extends PersistenceEntity>(domainCode: string, modelCode: string, file: File): Observable<T[]> {
    const parama: PostCallParameter = {
      ...this.generateHttpParam(domainCode, modelCode, ModelRequestMappingAndMethod.UPLOAD_ATTACHMENT.method),
      requestMappingString: ModelRequestMappingAndMethod.UPLOAD_ATTACHMENT.requestMapping,
    };
    let httpCall$: Observable<any> = of();
    if (parama.moduleServiceConfig) {
      httpCall$ = this._restfulService.uploadCall(parama, [file]);
    }
    return httpCall$;
  }
  copy<T extends PersistenceEntity>(entity: T): T {
    return cloneDeep(entity);
  }
  printById(domainCode: string, modelCode: string, fileName: string, id?: number): void {
    throw new Error("Method not implemented.");
  }
  printByIds(domainCode: string, modelCode: string, fileName: string, ids?: number[]): void {
    throw new Error("Method not implemented.");
  }
  assignEntitiesToNewOwner(domainCode: string, modelCode: string, ids: number[], newOwnerId: number): Observable<boolean> {
    throw new Error("Method not implemented.");
  }
  assignEntityToNewOwner(domainCode: string, modelCode: string, id: number, newOwnerId: number): Observable<boolean> {
    throw new Error("Method not implemented.");
  }

  private _initServiceData(): void {
    let _loginUser: CasUserDetail = this._storageService.getLocalItem(APPCONSTANT.LOCAL_STORAGE_ITEM_NAME.LOGIN_USER);
    this._modelModuleServiceConfigMap = {};
    if (_loginUser == null) {
      _loginUser = new CasUserDetail();
    }
    let moduleServiceConfig: ModuleServiceConfig[] =
      this._storageService.getLocalItem(APPCONSTANT.LOCAL_STORAGE_ITEM_NAME.MODULE_SERVICE_INFO) as ModuleServiceConfig[];
    if (!isEmpty(_loginUser.allDataResourceList) && (typeof moduleServiceConfig != 'string')
      && moduleServiceConfig != null && moduleServiceConfig.length > 0) {
      _loginUser.allDataResourceList?.forEach(dataResrc => {
        let mmsc = moduleServiceConfig.filter(msc => dataResrc.moduleServiceCode === msc.moduleServiceCode);
        if (mmsc.length == 1) {
          mmsc[0].protocal = CASConfig.protocal;
          this._modelModuleServiceConfigMap[`${dataResrc.domainCode}-${dataResrc.modelCode}`] = mmsc[0];
        }

        dataResrc.operationList?.forEach(dataResrcOper => {
          let methodCode = dataResrcOper.apiCode?.substring(dataResrcOper.apiCode.lastIndexOf('.') + 1);
          this._methedOperationCodeMap[`${dataResrc.domainCode}-${dataResrc.modelCode}-${methodCode}`] = dataResrcOper.operationCode || '';
        });

      });
    }
  }

  private _saveMetaData(meta: FrontModelMetaData /** TODO 这里从请求结果上来看必然是一个数组啊 */) {
    let modelMetaList: Array<FrontModelMetaData>;
    if (Array.isArray(meta)) {
      modelMetaList = meta;
    } else {
      modelMetaList = [meta];
    }
    modelMetaList.forEach(modelMD => this._storageService.setModelMeta(modelMD.modelName!, modelMD));
  }

  generateHttpParam(domainCode: string, modelCode: string, methodCode: string): HttpCallParameter {
    return {
      moduleServiceConfig: this._getModelModuleServiceConfig(domainCode, modelCode),
      modelCode: this._generateBackendControllerContextPath(modelCode),
      operationCode: this._getOperationCode(domainCode, modelCode, methodCode),
    };
  }
  private _getOperationCode(domainCode: string, modelCode: string, methodCode: string): string {
    let opCode = this._methedOperationCodeMap[`${domainCode}-${modelCode}-${methodCode}`];
    if (opCode == null) {
      let key = Object.keys(this._methedOperationCodeMap).find(mmsCfg => mmsCfg.includes(`-${modelCode}-${methodCode}`));
      if (key != null) {
        opCode = this._methedOperationCodeMap[key];
      }
    }
    return opCode;
  }
  private _generateBackendControllerContextPath(modelCode: string): string {
    return (modelCode.substring(modelCode.lastIndexOf('.') + 1) + 's').toLowerCase();
  }
  private _getModelModuleServiceConfig(domainCode: string, modelCode: string): ModuleServiceConfig {
    let msCfg = this._modelModuleServiceConfigMap[`${domainCode}-${modelCode}`];

    if (msCfg == null) {
      let key = Object.keys(this._modelModuleServiceConfigMap).find(mmsCfg => mmsCfg.includes(`-${modelCode}`));
      if (key != null) {
        msCfg = this._modelModuleServiceConfigMap[key];
      }
    }
    return msCfg;
  }

  makeHttpPostCall(
    domainCode: string, modelCode: string, methodCode: string,
    para: any, responseDataMode?: ResponseDataCategory
  ): Observable<any> {
    if (responseDataMode == null) {
      responseDataMode = ResponseDataCategory.SingleModel;
    }
    const parama: PostCallParameter = {
      ...this.generateHttpParam(domainCode, modelCode, methodCode),
      requestMappingString: methodCode,
      para: para,
      singleData: responseDataMode == ResponseDataCategory.SingleModel,
      listData: responseDataMode == ResponseDataCategory.ListOfModel,
      pageData: responseDataMode == ResponseDataCategory.PageOfModel,
    };
    return this._makeHttpCall('post', parama);
  }

  makeHttpGetCall(
    domainCode: string, modelCode: string, methodCode: string, responseDataMode?: ResponseDataCategory
  ): Observable<any> {
    if (responseDataMode == null) {
      responseDataMode = ResponseDataCategory.SingleModel;
    }
    const param: GetCallParameter = {
      ...this.generateHttpParam(domainCode, modelCode, methodCode),
      requestMappingString: methodCode,
      singleData: responseDataMode == ResponseDataCategory.SingleModel,
      listData: responseDataMode == ResponseDataCategory.ListOfModel,
      pageData: responseDataMode == ResponseDataCategory.PageOfModel,
    };
    return this._makeHttpCall('get', param);
  }

  private _makeHttpCall(httpMethod: 'get' | 'post' | 'delete', httpCallParam: HttpCallParameter): Observable<any> {
    let httpCall$: Observable<any> = of();
    if (httpCallParam.moduleServiceConfig == null) {
      httpCallParam.moduleServiceConfig = CASConfig;
    }
    if (httpCallParam.moduleServiceConfig) {
      if (httpMethod == 'post') {
        httpCall$ = this._restfulService[`${httpMethod}Call`](httpCallParam, (httpCallParam as PostCallParameter).para);
      } else {
        httpCall$ = this._restfulService[`${httpMethod}Call`](httpCallParam);
      }
    } else {
      console.error('httpCallParam.moduleServiceConfig is null');
      // console.log(httpCallParam);
    }
    return httpCall$.pipe(tap(res => this.handlePossibleError(res)));
  }

}

const ModelRequestMappingAndMethod = {
  METADATA: { requestMapping: 'metadata', method: 'getModelMetadata' },
  PAGE_QUERY: { requestMapping: 'pageQuery', method: 'pageQuery' },
  QUERY: { requestMapping: 'query', method: 'query' },
  FIND_BY_ID: { requestMapping: '', method: 'getById' },
  CREATE: { requestMapping: 'create', method: 'create' },
  CREATE_BATCH: { requestMapping: 'createBatch', method: 'createBatch' },
  UPDATE: { requestMapping: 'update', method: 'update' },
  DELETE: { requestMapping: 'delete', method: 'delete' },
  DELETE_BY_ID: { requestMapping: '', method: 'deleteById' },
  DELETE_BY_IDS: { requestMapping: 'deleteBatch', method: 'deleteBatch' },
  DOWNLOAD_TEMPLATE: { requestMapping: 'downloadTemplate', method: 'downloadTemplate' },
  DOWNLOAD_FILE: { requestMapping: 'downloadFile', method: 'downloadFile' },
  GET_IMG_URL: { requestMapping: 'getImgUrl', method: 'getImgUrl' },
  DOWNLOAD_BY_CRITERIA: { requestMapping: 'downloadAllData', method: 'downloadAllData' },
  DOWNLOAD_BY_IDS: { requestMapping: 'downloadSelectedData', method: 'downloadSelectedData' },
  UPLOAD_ATTACHMENT: { requestMapping: 'uploadAttachment', method: 'uploadAttachment' },
  UPLOAD_MODEL_DATA: { requestMapping: 'uploadModel', method: 'uploadModel' }
}
