import { Injectable } from "@angular/core";
import { APPCONSTANT } from "src/app/app.constant";
import { RoutingService } from "src/service/routing.service";
import { WarehouseOrder } from "../model/cargo.wms.model";

@Injectable({ providedIn: "root" })
export class CargoRoutingService extends RoutingService {

  gotoCargoPage (path: string,withBackUrl?: boolean): void {
    let _backUrlParam: string = '';
    if (withBackUrl) {
      _backUrlParam = this._generateBackUrl();
    }
    let url: string;
    if (path.includes('?')) {
      url = `${Cargo_PathName.CARGO}/${path}${_backUrlParam.length != 0 ? ('&' + _backUrlParam) : ''}`;
    } else {
      url = `${Cargo_PathName.CARGO}/${path}${_backUrlParam.length != 0 ? ('?' + _backUrlParam) : ''}`;
    }
    this.gotoUrl(url);
  }

  gotoBookingOrderPage (path: string,withBackUrl?: boolean): void {
    this.gotoCargoPage(`${Cargo_PathName.BOOKING_ORDER}/${path}`,withBackUrl);
  }

  gotoCustomerPager (path: string,withBackUrl?: boolean): void {
    this.gotoCargoPage(`${Cargo_PathName.CUSTOMER}/${path}`,withBackUrl);
  }

  gotoBookingOrderStepPage (path: string,orderNumber: string): void {
    this.gotoCargoPage(`${Cargo_PathName.BO_STEP}/${path}?orderNumber=${orderNumber}`,true);
  }

  gotoWarehousePage (path: string,withBackUrl?: boolean): void {
    this.gotoCargoPage(`${Cargo_PathName.WAREHOUSE}/${path}`,withBackUrl);
  }

  gotoInsideOperationPage (taskName: string,whOrd: WarehouseOrder): void {
    console.log(taskName);
    console.log(whOrd);
    let pathUrl = `${Cargo_PathName.OPERATION}?whOrdId=${whOrd.id}&whOrderNumber=${whOrd.orderNumber}&tabName=${taskName}`;
    this.gotoDevicePage(pathUrl,true);
  }
  gotoDevicePage (path: string,withBackUrl?: boolean): void {
    let _backUrlParam: string = '';
    // let urlLocation = location.pathname + location.search;
    if (withBackUrl) {
      _backUrlParam = this._generateBackUrl();
    } else {
      let currentBackUrlPos = location.search.indexOf(APPCONSTANT.URL_PARAMETER.BACK_URL);
      if (currentBackUrlPos != null) {
        _backUrlParam = location.search.substring(currentBackUrlPos);
        if (path == Cargo_PathName.DASHBOARD) {
          _backUrlParam = '';
        }
      }
    }
    let url: string;
    if (path.includes('?')) {
      url = `m/${path}${_backUrlParam.length != 0 ? ('&' + _backUrlParam) : ''}`;
    } else {
      url = `m/${path}${_backUrlParam.length != 0 ? ('?' + _backUrlParam) : ''}`;
    }
    this.gotoUrl(url);
  }

}

export const Cargo_PathName = {
  CARGO: 'cargo',
  DASHBOARD: 'dashboard',
  FOUNDATION: 'foundation',

  BOOKING_ORDER: 'bookingorder',
  BO_DETAIL: 'detail',
  BO_LIST: 'list',
  BO_DRAFT_CREATION: 'create',
  BO_DRAFT_EDIT: 'draft-edit',

  CUSTOMER: 'customer',
  CUSTOMER_DETAIL: 'customerDetail',
  CUSTOMER_CREATE: 'customerCreate',
  CUSTOMER_EDIT: 'customerEdit',

  BO_STEP: 'bookingorder-step',
  BO_STEP_BOOKING: 'booking',
  BO_STEP_SO_EMAIL: 'so-email',
  BO_STEP_SO_GROUND: 'so-ground',
  BO_STEP_SO_PICKUP: 'so-pickup',
  BO_STEP_SO_APPRAISAL: 'so-appraisal',
  BO_STEP_SO_DECLARE: 'so-declare',
  BO_STEP_SO_WAREHOUSE: 'so-warehouse',
  BO_STEP_INBOUND: 'inbound',
  BO_STEP_OUTBOUND: 'outbound',
  BO_STEP_LADING: 'lading',
  BO_STEP_DOCUMENT: 'document',
  BO_STEP_ALMS_INFO: 'alms-info',
  BO_STEP_CUSTOMS_AFFAIRS: 'customs-affairs',
  BO_STEP_LABELLING: 'labelling',


  WAREHOUSE: 'warehouse',            // 仓库模块
  WAREHOUSE_RESERVATION: 'reservation', // 送货预约
  WAREHOUSE_REGISTER: 'register',    // 仓库窗口司机登记
  WAREHOUSE_REGISTER_DETAIL: 'register-detail',    // 仓库窗口司机登记
  WAREHOUSE_REGISTER_CREATION: 'register-creation', // 没预约的司机，现场录入相关信息收货
  WAREHOUSE_CHECKOUT: 'checkout',    // 仓库出库安排
  WAREHOUSE_OUTBOUND_CREATION: 'outbound_creation',
  WAREHOUSE_OUTBOUND_UPDATE: 'outbound_update',
  WAREHOUSE_PRINT_DOCUMENTS: 'print-documents',

  MOBILE_RESERVATION: 'reservation',
  ACCOUNT: 'account',
  INBOUND: 'inbound',
  CHECK: 'inbound-check',
  MEASURE: 'inbound-measure',
  STATION: 'station',
  QUICK_SCANNER: 'quick-scanner',

  OUTBOUND: 'outbound',
  OUTBOUND_DETAIL: 'outbound-detail',

  INSIDE: 'inside',
  OPERATION: 'inside-operation'
};
