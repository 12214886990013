import { PersistenceEntity } from 'src/model/persistence-entity';
import { BackendDataResource } from './data-resource.backend.model';



export class BackendDataResourceField extends PersistenceEntity {
  dataResource?: BackendDataResource;
  fieldCode?: string;
  fieldName?: string;
}
