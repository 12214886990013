import { Injectable } from '@angular/core';
import { Observable, of, tap } from 'rxjs';
import { APPCONSTANT } from 'src/app/app.constant';
import { PjKeyValue } from 'src/component/component.type';
import { CASConfig } from 'src/environments/service.config';
import { CasUser, CasUserDetail } from 'src/model/authentication.model';
import { PersistenceEntity } from 'src/model/persistence-entity';
import { LocalSelectionDataMap, SelectionData } from 'src/model/selection-data.local.model';
import { ModuleServiceConfig, PostCallParameter, ServerResponse } from './httpcall.type';
import { PolarJService } from './polarj.service';

@Injectable()
export class AuthService extends PolarJService {

  constructor() {
    super();
    this._getAllSelectionData();
  }

  login(para: CasUser): Observable<CasUser> {
    // : Observable<ResponseBase<CasUser> 原来为<CasLoginUser>
    const params: PostCallParameter = {
      moduleServiceConfig: CASConfig,
      modelCode: '',
      requestMappingString: 'authentications/login',
      para: para,
      singleData: true
    };
    return this._restfulService.postCall(params, params.para).pipe(tap(res => this.handlePossibleError(res)));
  }

  verifyCodeRequest(para: CasUser): Observable<boolean> {
    const params: PostCallParameter = {
      moduleServiceConfig: CASConfig,
      modelCode: '',
      requestMappingString: 'authentications/sendLoginVerifyCode',
      para: para,
      singleData: true
    };
    return this._restfulService.postCall(params, params.para).pipe(tap(res => this.handlePossibleError(res)));
  }
  // TODO: 当登出出错时候，也需要删除credential的相关信息。
  logout(clearLocalData?: boolean): void {
    // 关闭 pjWindowsModel
    // 在登出之前，先保存登录用户最后的访问链接
    const params: PostCallParameter = {
      moduleServiceConfig: CASConfig,
      modelCode: '',
      requestMappingString: 'authentications/logout',
      enableErrorMsg: true,
      singleData: true,
    }
    this._restfulService.postCall(params, params.para).pipe(
      tap(res => this.handlePossibleError(res))
    ).subscribe(res => {
      if(clearLocalData) {
        this._storageService.clearLocalItems();
      }
    });
    this._clearLocalDataAndGotoLogin();
  }

  currentLogin(): Observable<CasUserDetail | null> {
    let res$: Observable<CasUserDetail | null>;
    let loginUser = this._storageService.getLocalItem(APPCONSTANT.LOCAL_STORAGE_ITEM_NAME.LOGIN_USER);
    let moduleServices = this._storageService.getLocalItem(APPCONSTANT.LOCAL_STORAGE_ITEM_NAME.MODULE_SERVICE_INFO);
    if (loginUser != null) {
      if (this.isAuth()) {
        if (!this._hasModuleServiceConfig(moduleServices)) {
          this.listModuleServiceInfo().subscribe();
        }
        res$ = of(loginUser);
      } else {
        res$ = of(null);
      }
    } else {
      if (this.isAuth()) {
        if (!this._hasModuleServiceConfig(moduleServices)) {
          this.listModuleServiceInfo().subscribe();
        }
        // FIXME: 没有处理服务器错误？
        res$ = this.getCurrentLoginUserDetail().pipe(
          tap(loginUser => {
            if (loginUser != null) {
              loginUser.dataResourceList = undefined;
              loginUser.dataResourceOperationList = undefined;
              loginUser.excludedFieldList = undefined;
              this._resetDataResourceAndOperation(loginUser);
              this._storageService.setLocalItem(APPCONSTANT.LOCAL_STORAGE_ITEM_NAME.LOGIN_USER, loginUser);
            }
          })
        );
      } else {
        res$ = of(null);
      }
    }
    return res$;
  }

  isAuth(): boolean {
    const curToken = this._storageService.getCasToken();
    return curToken != null && curToken.length != 0;
  }

  getCurrentLoginUserDetail(): Observable<CasUserDetail> {
    const params: PostCallParameter = {
      moduleServiceConfig: CASConfig,
      modelCode: '',
      requestMappingString: "authentications/getCurrentLoginUserDetail",
      singleData: true
    };
    return this._restfulService.postCall(params, params.para).pipe(
      tap(res => this.handlePossibleError(res))
    );
  }

  // 重新组织数据与操作权限
  private _resetDataResourceAndOperation(loginUser: CasUserDetail): void {

    // TODO: 用于列举所有的可使用领域模型数据
    let domainModelMapping: PjKeyValue<{ domainCode: string, modelCode: string }> = {};
    loginUser.allDataResourceList?.forEach(dataResource => {
      if (dataResource.modelCode != null && dataResource.domainCode != null) {
        domainModelMapping[dataResource.modelCode.substring(dataResource.modelCode.lastIndexOf('.') + 1)] = {
          domainCode: dataResource.domainCode,
          modelCode: dataResource.modelCode
        }
        // DomainModelConstant[dataResource.modelCode.substring(dataResource.modelCode.lastIndexOf('.') + 1)] = {
        //   domainCode: dataResource.domainCode,
        //   modelCode: dataResource.modelCode
        // };
      }
    });
    // console.log(domainModelMapping);
    // TODO: 用于列举所有的可使用领域模型数据

    loginUser.allDataResourceList?.forEach(dataResource => {
      PersistenceEntity.removeUselessFieldForLocalStorageAndSubmission(dataResource);
      let ops = loginUser.allDataResourceOperationList?.filter(dataResrcOp => dataResrcOp.dataResource?.id === dataResource.id);
      ops?.forEach(op => {
        op.dataResource = undefined;
        PersistenceEntity.removeUselessFieldForLocalStorageAndSubmission(op);
      });
      dataResource.operationList = ops;
    });
    loginUser.allDataResourceOperationList = undefined;

    PersistenceEntity.removeUselessFieldForLocalStorageAndSubmission(loginUser);
    PersistenceEntity.removeUselessFieldForLocalStorageAndSubmission(loginUser.casUser);
    PersistenceEntity.removeUselessFieldForLocalStorageAndSubmission(loginUser.contactInfo);
  }

  // QUES: 匿名用户是不是可以访问该接口？
  private listModuleServiceInfo(): Observable<ServerResponse<ModuleServiceConfig>> {
    const params: PostCallParameter = {
      moduleServiceConfig: CASConfig,
      modelCode: '',
      requestMappingString: "authentications/listModuleServiceInfo",
      listData: true,
    };
    return this._restfulService.postCall(params, params.para).pipe(
      tap(res => this.handlePossibleError(res)),
      tap(res => this._storageService.setLocalItem(APPCONSTANT.LOCAL_STORAGE_ITEM_NAME.MODULE_SERVICE_INFO, res))
    ) as unknown as Observable<ServerResponse<ModuleServiceConfig>>;
  }

  private _hasModuleServiceConfig(moduleServices: Array<ModuleServiceConfig>): boolean {
    if (moduleServices == null) {
      return false;
    }
    if ((typeof moduleServices) == 'string') {
      return false;
    }
    if (moduleServices.length == null || moduleServices.length == 0) {
      return false;
    }
    return true;
  }

  private _getAllSelectionData(): void {
    let localSDMap = this._storageService.getLocalItem(APPCONSTANT.LOCAL_STORAGE_ITEM_NAME.SELECTION_DATA);
    let timestamp: number = 0;
    if (localSDMap != null) {
      timestamp = (localSDMap as LocalSelectionDataMap).timestamp;
    }
    this._restfulService.getCall({
      moduleServiceConfig: CASConfig,
      modelCode: '',
      requestMappingString: 'listAllSelectionData',
      urlPara: 'lastUpdateTimestamp=' + timestamp
    }).pipe(
      tap(res => this.handlePossibleError(res))
    ).subscribe(selData => {
      if (Array.isArray(selData) && selData.length > 0) {
        let localSDMap = SelectionData.convertToLocalSelectionDataMap(selData);
        this._storageService.setLocalItem(APPCONSTANT.LOCAL_STORAGE_ITEM_NAME.SELECTION_DATA, localSDMap);
      }
    });
  }
}