// 此代码为自动生成，保留以作参考
// 被注释的代码是为了不显示错误而手动添加的
import { BackendI18nResource } from './i18n-resource.backend.model';
// import { BackendCoreCasUser } from './core-cas-user.backend.model';
// import { BackendRangeValueForSearching } from './range-value-for-searching.backend.model';
// import { BackendEnumValuesForSearching } from './enum-values-for-searching.backend.model';



export class BackendPersistenceEntity {
  id?: number;
  createdDate?: Date;
  createdById?: number;
  createdBy?: string;
  updatedDate?: Date;
  updatedById?: number;
  updatedBy?: string;
  deleted?: boolean;
  deletedById?: number;
  deletedDate?: Date;
  /** This field is transient on the backend. */
  languageId?: string;
  /** This field is transient on the backend. */
  pageIndex?: number;
  /** This field is transient on the backend. */
  pageSize?: number;
  /** This field is transient on the backend. */
  sorts?: string[];
  /** This field is transient on the backend. */
  smartSearchText?: string;
  /** This field is transient on the backend. */
  i18nResources?: BackendI18nResource[];
  /** This field is transient on the backend. */
  // operator?: BackendCoreCasUser;
  /** This field is transient on the backend. */
  searchOptionMap?: Map<string, string>;
  /** This field is transient on the backend. */
  // rangeValues?: BackendRangeValueForSearching[];
  /** This field is transient on the backend. */
  // enumValuesForSearching?: BackendEnumValuesForSearching[];
  /** This field is transient on the backend. */
  valueRemovedFields?: string[];
  /** This field is transient on the backend. */
  businessValidation?: boolean;
}
