import { Component } from '@angular/core';

@Component({
  selector: 'page-coming-soon',
  templateUrl: './page-coming-soon.component.html',
  styleUrl: './page-coming-soon.component.less'
})
export class PageComingSoonComponent {

}
