<button nz-button [nzType]="getNzType()" [disabled]="isDisabled()" nzShape="round" nzBlock [nzSize]="size"
  (click)="onBtnClick()">
  <div class="flex flex-col items-center">
    <div class="flex flex-row items-center gap-1">
      <pj-icon [ngClass]="getIconClass()" *ngIf="leftIconName!=null" [iconName]="leftIconName"></pj-icon>
      <div [ngClass]="getLabelClass()">{{getBtnLabel()}}</div>
      <pj-icon [ngClass]="getIconClass()" *ngIf="rightIconName!=null" [iconName]="rightIconName"></pj-icon>
      <div *ngIf="badgeLabel.length>0">
        <nz-tag [nzColor]="getBadgeColor()" [nzBordered]="false">{{badgeLabel}}</nz-tag>
      </div>
    </div>
  </div>
</button>