export const APP_RESOURCE = {
  SMALL_LOGO: 'assets/image/logo/logo.png',
  LARGE_LOGO: 'assets/image/logo/logo-text-horizontal.png',
};

export const APPCONSTANT = {
  LOCAL_STORAGE_ITEM_NAME: {
    MODULE_SERVICE_INFO: 'module-service-info',
    METADATA_PRRFIX: 'meta-',
    LOGIN_USER: 'loginUser',
    CAS_TOKEN: 'Authorization',
    LAST_USED_URL: 'lastUsedUrl', // 用户最后一次访问服务器时使用的页面
    LAST_ACCESS_TIME: 'lastAccessTime', // 用户最后一次主动访问服务器的时间
    SELECTION_DATA: 'selectionData',
  },
  emptyImage: 'assets/image/empty_asset.png',
  URL_PARAMETER: {
    BACK_URL: 'backUrl', // 当前页面有back 或者 返回等回到上一页时，需要用到该url参数，以方便回到上一页
    REDIRECT_URL: 'redirectUrl', // 如果登录页面有此参数，在成功登录之后去该页面
  },
  API_URL: {
    GEOLOCATION_CITY:
      'https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=#{latitude}&longitude=#{longitude}&localityLanguage=en',
    IP_ADDRESS: 'https://api.bigdatacloud.net/data/client-info',
  },
  HTTP_STATUS: {
    // http response status状态码，对应国际化文件中的http部分
    200: 'http.200',
    300: 'http.300',
    401: 'http.401',
    403: 'http.403',
    404: 'http.404',
    500: 'http.500',
    UNKNOWN: 'http.unknown',
  },
}