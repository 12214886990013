import { Component } from '@angular/core';

@Component({
  selector: 'page-error-404',
  templateUrl: './page-error-404.component.html',
  styleUrl: './page-error-404.component.less'
})
export class PageError404Component {

}
