export const serverContextPath: string = '';
export const CASConfig = {
  moduleServiceCode: 'wms',
  protocal: 'http://',
  hostName: 'localhost',
  // hostName: '192.168.11.203',
  // hostName: '192.168.11.171',
  // hostName: '192.168.11.71',
  port: '8099'
};
