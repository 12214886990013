import { Component, Input, SimpleChanges, TemplateRef } from '@angular/core';
import { PjKeyValue, PjSize } from 'src/component/component.type';
import { PjButtonType } from 'src/component/pj-button/pj-button.type';
import { PjTabGroupBaseComponent } from '../pj-tab-group-base.component';
import { PjTabItemData } from '../pj-tab-item.type';

@Component({
  selector: 'pj-btn-tab-group',
  templateUrl: './pj-btn-tab-group.component.html',
  styleUrl: './pj-btn-tab-group.component.less'
})
export class PjBtnTabGroupComponent extends PjTabGroupBaseComponent {

  @Input()
  size: PjSize = 'large';

  @Input()
  tabsPerRow: number = 0; // 每行有几个tabs，缺省等于传入的 tabItems 的数组长度

  private _btnsPerRow?: number;
  private _btnItems: PjTabItemData[][] = [];

  ngOnInit(): void {
    if (this.tabItems == null || this.tabItems.length == 0) {
      return;
    }
    this._selectedBtn = this.tabItems.find(item => item.isActived);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tabItems'] != null && Array.isArray(this.tabItems) || changes['tabsPerRow'] != null) {
      this._selectedBtn = this.tabItems.find(item => item.isActived);
      if (this.tabsPerRow != 0) {
        this._btnsPerRow = this.tabsPerRow;
        this._btnItems = [];
        for (let i = 0; i < this.tabItems.length / this._btnsPerRow; i++) {
          let rowBtns: PjTabItemData[] = [];
          for (let j = 0; j < this._btnsPerRow; j++) {
            let ind = (i * this._btnsPerRow) + j;
            if (ind < this.tabItems.length) {
              rowBtns.push(this.tabItems[ind]);
            }
          }
          this._btnItems.push(rowBtns);
        }
      } else {
        this._btnsPerRow = this.tabItems.length;
        this._btnItems = [this.tabItems];
      }
    }
  }

  getBtnRows(): PjTabItemData[][] {
    return this._btnItems;
  }

  private _style = { width: '' };
  getWidthBtnWidthStyle(btns: PjTabItemData[]): PjKeyValue<string> {
    let width = (100 / btns.length).toFixed(0);
    this._style.width = width + '%';
    return this._style;
  }

  private _selectedBtn?: PjTabItemData;
  getBtnType(btn: PjTabItemData): PjButtonType {
    return btn.isActived ? 'filled' : 'outlined';
  }

  onTabBtnClick(btn: PjTabItemData): void {
    this._selectedBtn = btn;
    this.tabItems.forEach(b => b.isActived = false);
    btn.isActived = true;
    this.selectedItemChange.emit(btn);
  }

  isBtnAvailable(btn: PjTabItemData): boolean {
    return this._selectedBtn?.contentTemplateName == btn.contentTemplateName;
  }

  getActivedTemplateRef(): TemplateRef<any> | null {
    let templateRef = null;
    if (this.tabItemContents != null && this.tabItemContents.length > 0) {
      let acd = this.tabItemContents.find(
        a => this._selectedBtn?.contentTemplateName == a.tabItemTemplateName
      );
      if (acd != null) {
        templateRef = acd?.getTemplateRef();
      }
    }
    return templateRef;
  }
}
