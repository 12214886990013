// 应用在运行过程中的全局动态数据，包括但不限于当前登录用户，从服务器获取的枚举数据。。。
// 是不是考虑把这里的数据当缓存用？
// 只要刷新网页，所有这里的数据都清空，并重新从服务器获取？
import { Injectable } from "@angular/core";
import { Observable, of, Subject } from "rxjs";
import { PjKeyValue } from "src/component/component.type";
import { CasUserDetail } from "src/model/authentication.model";
import { PersistenceEntity } from "src/model/persistence-entity";
import { ModelServiceHttpImpl } from "./model.service.httpimpl";

export const DomainModelConstant: any = {}

@Injectable({ providedIn: 'root' })
export class AppConfigService {

  // 保存autocomplete中 pageSelection=false 的数据缓存
  private _searchSelectionOptionItemsMap: PjKeyValue<PersistenceEntity[]> = {};
  // private _modelService: ModelServiceHttpImpl = inject(ModelServiceHttpImpl);

  private _loginUser$: Subject<CasUserDetail | null> = new Subject<CasUserDetail | null>();

  loginUser(): Observable<CasUserDetail | null> {
    return this._loginUser$;
  }

  updateLoginUser(u: CasUserDetail | null): void {
    if (u != null) {
      this._resetDataResource(u);
    }
    this._loginUser$.next(u == null ? null : u);
  }

  getOptionItems(modelService: ModelServiceHttpImpl, domainCode: string, modelCode: string, keywords: string): Observable<PersistenceEntity[]> {
    return of();
  }

  private _resetDataResource(loginUser: CasUserDetail): void {
    loginUser.allDataResourceList?.forEach(dataResource => {
      if (dataResource.modelCode != null && dataResource.domainCode != null) {
        DomainModelConstant[dataResource.modelCode.substring(dataResource.modelCode.lastIndexOf('.') + 1)] = {
          domainCode: dataResource.domainCode,
          modelCode: dataResource.modelCode
        };
      }
    });
  }
}