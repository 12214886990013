import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PjTextInputComponent } from './pj-text-input.component';
import { FormsModule } from '@angular/forms';
import { NzInputModule } from 'ng-zorro-antd/input';
import { PjIconModule } from 'src/component/pj-icon/pj-icon.module';



@NgModule({
  declarations: [
    PjTextInputComponent
  ],
  imports: [
    CommonModule,

    FormsModule,

    NzInputModule,

    PjIconModule
  ],
  exports: [
    PjTextInputComponent
  ]
})
export class PjTextInputModule { }
