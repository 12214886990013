import { Component } from '@angular/core';

@Component({
  selector: 'page-register',
  templateUrl: './page-register.component.html',
  styleUrl: './page-register.component.less'
})
export class PageRegisterComponent {

}
