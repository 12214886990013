import { Component } from '@angular/core';
import { Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { HelperService } from 'src/service/helper.service';
import { PageLoginBaseComponent } from '../page-login-base.component';

@Component({
  selector: 'page-login-pswd-only',
  templateUrl: './page-login-pswd-only.component.html',
  styleUrl: './page-login-pswd-only.component.less'
})
export class PageLoginPswdOnlyComponent extends PageLoginBaseComponent {
  private _loginName: string | null = null;
  constructor() {
    super();
    this.validateForm = this._fb.group({
      password: [environment.hardCodePassword, [Validators.required]],
      verifyCode: [environment.hardCodeVerificationCode, [Validators.required]],
    });
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this._routInfo.paramMap.subscribe(paramMap => {
      let _nameFromUrl: string = paramMap.get('loginName') || '';
      if (_nameFromUrl.includes('@')) {
        this._loginName = _nameFromUrl;
      } else {
        this._loginName = HelperService.decodeFromBase64(_nameFromUrl);
      }
      if (this._loginName == null || this._loginName.length == 0) {
        this._routingService.gotoLoginPage();
      }
    });
  }

  protected override getLoginName(): string {
    return this._loginName || '';
  };

}
