export class BackendFileInfo {
  fileType?: string;
  fileName?: string;
  originalFileName?: string;
  base64Content?: string;
  base64ContentForFrontend?: string;
  /** This field is transient on the backend. */
  content?: string;
  /** This field is transient on the backend. */
  byteContent?: string;
}
