import { Component, ContentChildren, EventEmitter, Input, Output, QueryList, TemplateRef } from '@angular/core';
import { PjTabItemContentDirective } from './pj-tab-item.directive';
import { PjTabItemData } from './pj-tab-item.type';

@Component({ template: `` })
export abstract class PjTabGroupBaseComponent {
  @ContentChildren(PjTabItemContentDirective)
  tabItemContents?: QueryList<PjTabItemContentDirective>;

  @Input()
  tabItems: PjTabItemData[] = [];

  @Output()
  selectedItemChange: EventEmitter<PjTabItemData> = new EventEmitter<PjTabItemData>();

  onSelectChange(tabItem: PjTabItemData): void {
    this.selectedItemChange.emit(tabItem);
  }

  onTabItemClick(tabItem: PjTabItemData): void {
    if (tabItem.isActived) {
      this.onSelectChange(tabItem);
    }
  }
  getTabItems(): PjTabItemData[] {
    return this.tabItems;
  }

  getTemplateRef(tabItem: PjTabItemData): TemplateRef<any> | null {
    let templateRef = null;
    if (this.tabItemContents != null && this.tabItemContents.length > 0) {
      let acd = this.tabItemContents.find(
        a => tabItem.contentTemplateName == a.tabItemTemplateName
      );
      if (acd != null) {
        templateRef = acd?.getTemplateRef();
      }
    }
    return templateRef;
  }

}