// 处理基本的本地存储服务
// 非基础项目需要继承这个类
import { Injectable } from "@angular/core";
import * as pako from "pako";
import { BehaviorSubject } from "rxjs";
import { APPCONSTANT } from "src/app/app.constant";
import { PjKeyValue } from "src/component/component.type";
import { environment } from "src/environments/environment";
import { FrontModelMetaData } from "src/model/front-model-metadata";
import { PersistenceEntity } from "src/model/persistence-entity";
import { LocalSelectionDataMap, SelectionData } from "src/model/selection-data.local.model";
import { HelperService } from "src/service/helper.service";

@Injectable({ providedIn: "root" })
export class StorageService {
  storageChanged = new BehaviorSubject<string>('');

  getLocalKeys(): string[] {
    const keys = new Array<string>();
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key != null && key.length > 0) {
        keys.push(key);
      }
    }
    return keys;
  }

  hasLocalItem(key: string): boolean {
    const v = localStorage.getItem(key);
    return v != null && v.length > 0;
  }

  setLocalItem(key: string, value: any): void {
    const s = typeof (value);
    let data: string;
    if (s == "object") {
      data = JSON.stringify(value);
    } else {
      data = value + '';
    }
    if (environment.enableGZipForLocalStorage) {
      let compressData = pako.gzip(data);
      let base64Data = HelperService.encodeToBase64(String.fromCharCode.apply(null, Array.from(compressData)));
      localStorage.setItem(key, base64Data);
    } else {
      localStorage.setItem(key, data);
    }
    this.storageChanged.next(key);
  }

  getSelectionDataByName(selectionModelName: string): SelectionData[] | undefined {
    let localSDMap = this.getLocalItem(APPCONSTANT.LOCAL_STORAGE_ITEM_NAME.SELECTION_DATA);
    let selectionMap: PjKeyValue<SelectionData[]> = {};
    if (localSDMap != null) {
      selectionMap = (localSDMap as LocalSelectionDataMap).selectionDataMap;
    }
    return selectionMap[selectionModelName];
  }

  getModelMeta(modelCode: string): any {
    return this.getLocalItem(APPCONSTANT.LOCAL_STORAGE_ITEM_NAME.METADATA_PRRFIX + modelCode);
  }

  // modelName： 领域模型全名
  setModelMeta(modelName: string, modelMeta: FrontModelMetaData): void {
    PersistenceEntity.removeUselessFieldForLocalStorageAndSubmission(modelMeta);
    modelMeta?.fieldSpecs?.forEach(fieldMD => PersistenceEntity.removeUselessFieldForLocalStorageAndSubmission(fieldMD));
    let modelCode = (modelName.substring(modelName.lastIndexOf('.') + 1) + 's').toLowerCase();
    this.setLocalItem(APPCONSTANT.LOCAL_STORAGE_ITEM_NAME.METADATA_PRRFIX + modelCode, modelMeta);
  }

  removeModelMeta(modelCode: string): void {
    return this.removeLocalItem(APPCONSTANT.LOCAL_STORAGE_ITEM_NAME.METADATA_PRRFIX + modelCode);
  }

  getCasToken(): any {
    return this.getLocalItem(APPCONSTANT.LOCAL_STORAGE_ITEM_NAME.CAS_TOKEN);
  }

  setCasToken(casTokenValue: any): void {
    this.setLocalItem(
      APPCONSTANT.LOCAL_STORAGE_ITEM_NAME.CAS_TOKEN,
      casTokenValue,
    );
  }

  // getModuleService(): PjModuleService[] {
  //   return this.getLocalItem(
  //     APPCONSTANT.LOCAL_STORAGE_ITEM_NAME.MODULE_SERVICE,
  //   );
  // }

  // setModuleService(moduleService: PjModuleService[]): void {
  //   this.setLocalItem(
  //     APPCONSTANT.LOCAL_STORAGE_ITEM_NAME.MODULE_SERVICE,
  //     moduleService,
  //   );
  // }

  removeCasToken(): void {
    this.removeLocalItem(APPCONSTANT.LOCAL_STORAGE_ITEM_NAME.CAS_TOKEN);
  }

  getLocalItem(key: string): any {
    let data: string | null = null;
    if (environment.enableGZipForLocalStorage) {
      const s = localStorage.getItem(key);
      const compressedData = new Uint8Array(HelperService.decodeFromBase64(s || '').split('').map(char => char.charCodeAt(0)));
      data = pako.ungzip(compressedData, { to: 'string' });
    } else {
      data = localStorage.getItem(key);
    }
    if (data != null && (data.startsWith("{") || data.startsWith("["))) {
      return JSON.parse(data);
    }
    return data;
  }

  clearLocalItems(): void {
    localStorage.clear();
    this.storageChanged.next('');
  }

  removeLocalItem(key: string): void {
    localStorage.removeItem(key);
    this.storageChanged.next(key);
  }

  getSessionKeys(): string[] {
    const keys = new Array<string>();
    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i);
      if (key != null && key.length > 0) {
        keys.push(key);
      }
    }
    return keys;
  }

  hasSessionItem(key: string): boolean {
    const v = sessionStorage.getItem(key);
    return v != null && v.length > 0;
  }

  setSessionItem(key: string, value: any): void {
    const s = typeof value;
    if (s == "object") {
      sessionStorage.setItem(key, JSON.stringify(value));
    } else {
      sessionStorage.setItem(key, value);
    }
    this.storageChanged.next(key);
  }

  getSessionItem(key: string): any {
    const s = sessionStorage.getItem(key);
    if (s != null && (s.startsWith("{") || s.startsWith("["))) {
      return JSON.parse(s);
    }
    return s;
  }

  clearSessionItems(): void {
    sessionStorage.clear();
    this.storageChanged.next('');
  }

  removeSessionItem(key: string): void {
    sessionStorage.removeItem(key);
    this.storageChanged.next(key);
  }
}
