import { PersistenceEntity } from 'src/model/persistence-entity';
import { BackendFileInfo } from './file-info.backend.model';



export class BackendAttachment extends PersistenceEntity {
  uploadTime?: Date;
  modelClassFullName?: string;
  modelInstanceId?: number;
  category?: string;
  description?: string;
  uri?: string;
  permanentUri?: string;
  uploaded?: boolean;
  uploadError?: string;
  /** This field is transient on the backend. */
  originalFileName?: string;
  fileInfo?: BackendFileInfo;
}
