import { PersistenceEntity } from 'src/model/persistence-entity';



export class BackendSelectionData extends PersistenceEntity {
  enumerationName?: string;
  value?: string;
  label?: string;
  fullName?: string;
  position?: number;
}
