import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, CanDeactivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { APPCONSTANT } from 'src/app/app.constant';
import { HelperService } from './helper.service';
import { PathName } from './routing.service';
import { StorageService } from './storage.service';

export const activeGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot, state: RouterStateSnapshot
) => {
  const storageService = inject(StorageService);
  const router = inject(Router);
  const curToken: string = storageService.getCasToken();
  let redirectUrl = state.url || '';
  if (!!curToken) {
    return true;
  }
  let loginUrl = `/${PathName.LOGIN}`;
  if (redirectUrl.length > 0) {
    loginUrl = `/${PathName.LOGIN}?${APPCONSTANT.URL_PARAMETER.REDIRECT_URL}=${HelperService.encodeToBase64(redirectUrl)}`;
  }
  return router.parseUrl(loginUrl);
};


export const deactivateGuard: CanDeactivateFn<any> = (
  component: any, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState: RouterStateSnapshot
): Observable<boolean> | boolean => {
  const storageService = inject(StorageService);
  let path = currentState.url;
  storageService.setLocalItem(APPCONSTANT.LOCAL_STORAGE_ITEM_NAME.LAST_USED_URL, path);
  return true;
}