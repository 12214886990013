import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { timer } from 'rxjs';
import { PjAction, PjActionEvent } from '../component.type';
import { PjTabGroupBaseComponent } from './pj-tab-group-base.component';
import { PjTabItemData } from './pj-tab-item.type';

@Component({
  selector: 'pj-tab-group',
  templateUrl: './pj-tab-group.component.html',
  styleUrl: './pj-tab-group.component.less'
})
export class PjTabGroupComponent extends PjTabGroupBaseComponent {
  @Input()
  actionItems?: PjAction[];

  @Input()
  position: 'top' | 'left' = 'top';

  @Output()
  actionEvent: EventEmitter<PjActionEvent> = new EventEmitter<PjActionEvent>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tabItems'] != null && this.tabItems != null) {
      this._setActivedIndex();
    }
  }
  private _activedIndex: number = 0;

  override onSelectChange(tabItem: PjTabItemData): void {
    this._setActivedIndex(tabItem);
    super.onSelectChange(tabItem);
  }
  onTabClose(index: number): void {
    this.tabItems.splice(index, 1);
  }

  getActiveIndex(): number {
    return this._activedIndex;
  }

  private _setActivedIndex(activeItem?: PjTabItemData): void {
    if (activeItem != null) {
      this.tabItems.forEach(ti => ti.isActived = false);
      activeItem.isActived = true;
    }
    let i = 0;
    for (let index = 0; index < this.tabItems.length; index++) {
      if (this.tabItems[index].isActived) {
        i = index;
      }
    }
    timer(200).subscribe(() => this._activedIndex = i);
  }

  onActionBtnClick(action: PjAction): void {
    if (action.actionString != null) {
      this.actionEvent.emit({ actionString: action.actionString })
    }
  }
}
