import { Component } from '@angular/core';

@Component({
  selector: 'page-forgot-password',
  templateUrl: './page-forgot-password.component.html',
  styleUrl: './page-forgot-password.component.less'
})
export class PageForgotPasswordComponent {

}
