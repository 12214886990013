import { PersistenceEntity } from 'src/model/persistence-entity';



export class BackendCasUserContactInfo extends PersistenceEntity {
  firstName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
  addressLine1?: string;
  addressLine2?: string;
  postcode?: string;
  city?: string;
  province?: string;
  country?: string;
}
