import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { enableProdMode } from '@angular/core';

function disableBackButton() {
  window.addEventListener('popstate', function (event) {
    history.pushState(null, '', location.href);
  });
}

disableBackButton();

if (environment.production) {
  enableProdMode();
}
if (!environment.enableLog) {
  console.log = function (message?: any, ...params: any[]): void {
  }
}

platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.error(err));
// 启动时监控localStorage的使用情况
(function () {
  let size = 0;
  for (let item in window.localStorage) {
    if (window.localStorage.hasOwnProperty(item)) {
      size += window.localStorage.getItem(item)?.length || 0;
    }
  }
  console.log('Used: ' + (size / 1024).toFixed(2) + 'KB');
})();
