import { Component, EventEmitter, Input, Output } from '@angular/core';
import { isEmpty } from 'lodash';
import { PjSideMenuItem } from './pj-side-menu.type';

@Component({
  selector: 'pj-side-menu',
  templateUrl: './pj-side-menu.component.html',
  styleUrl: './pj-side-menu.component.less'
})
export class PjSideMenuComponent {

  @Input()
  menuItems: PjSideMenuItem[] = [];

  @Input()
  collapsed: boolean = false;

  @Input()
  singleOpend: boolean = false;

  @Input()
  isUrlAction: boolean = false;

  @Output()
  itemChanged = new EventEmitter<PjSideMenuItem>();

  isCollapsed(): boolean {
    return this.collapsed;
  }

  getMenuItems(): PjSideMenuItem[] {
    return this.menuItems;
  }

  isOpened(item: PjSideMenuItem): boolean {
    return item.expanded || false;
  }

  onOpenChanged(expanded: boolean, item: PjSideMenuItem): void {
    if (this.singleOpend) {
      this.menuItems.forEach(mi => mi.expanded = false);
    }
    item.expanded = expanded;
  }
  getMenuItemTitle(item: PjSideMenuItem): string | null {
    return item.label || null;
  }

  getMenuItemIconName(item: PjSideMenuItem): string {
    return item.iconName || '';
  }

  hasChildren(item: PjSideMenuItem): boolean {
    return !isEmpty(item.children);
  }

  getSubMenuItems(item: PjSideMenuItem): PjSideMenuItem[] {
    return item.children || [];
  }

  isSelected(item: PjSideMenuItem): boolean {
    return item.focused || false;
  }

  onMenuItemClick(item: PjSideMenuItem): void {
    this.itemChanged.emit(item);
  }

  hasRouterLink(item: PjSideMenuItem): boolean {
    return this.isUrlAction && !isEmpty(item.actionString);
  }

  getRouterLink(item: PjSideMenuItem): string | undefined {
    return item.actionString;
  }
}
