import { PersistenceEntity } from 'src/model/persistence-entity';



export class BackendFunctionality extends PersistenceEntity {
  functionCode?: string;
  functionName?: string;
  iconName?: string;
  functionType?: string;
  parentMenu?: BackendFunctionality;
  /** This field is transient on the backend. */
  subMenu?: BackendFunctionality[];
  operationCode?: string;
  position?: number;
}
