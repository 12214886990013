import { Component, Input } from '@angular/core';
import { PjDirectionEnum, PjDirectionType, PjProperty } from '../component.type';
import { PjDividerType } from './p-divider.type';

@Component({
  selector: 'pj-divider',
  templateUrl: './pj-divider.component.html',
  styleUrl: './pj-divider.component.less'
})
export class PjDividerComponent {
  @Input()
  width: number = 1;

  @Input()
  label = "";

  @Input()
  direction: PjDirectionType = 'horizontal';

  @Input()
  dividerType: PjDividerType = "strong";

  private _dividerClass: string = "";
  private _dividerStyle: PjProperty = {};
  private _directionClass: string = "";

  constructor() { }

  ngOnInit(): void {
    if (this.direction == PjDirectionEnum.H) {
      this._directionClass = "flex-row";
      this._dividerClass = this.dividerType + " " + PjDirectionEnum.H;
      this._dividerStyle = { "border-bottom-width": this.width + "px" };
    } else {
      this._directionClass = "flex-col vertical";
      this._dividerClass = this.dividerType + " " + PjDirectionEnum.V;
      this._dividerStyle = { "border-left-width": this.width + "px" };
    }
  }

  getDividerStyle(): PjProperty {
    return this._dividerStyle;
  }
  getDividerClass(): string {
    return this._dividerClass;
  }
  hasLabel(): boolean {
    return this.label != null && this.label.length > 0;
  }

  getDirectionClass(): string {
    return this._directionClass;
  }

}
