import { PersistenceEntity } from 'src/model/persistence-entity';



export class BackendCasGroup extends PersistenceEntity {
  groupCode?: string;
  groupName?: string;
  description?: string;
  status?: string;
}
