import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { PjButtonModule } from 'src/component/pj-button/pj-button.module';
import { PjSideMenuModule } from 'src/component/pj-side-menu/pj-side-menu.module';
import { PjTabGroupModule } from 'src/component/pj-tab-group/pj-tab-group.module';
import { activeGuard, deactivateGuard } from 'src/service/polarj.guard';
import { Cargo_PathName } from '../service/cargo.routing.service';
import { PageCargoComponent } from './page-cargo.component';

const routes: Routes = [{
  path: Cargo_PathName.CARGO, component: PageCargoComponent, canActivate: [activeGuard], canDeactivate: [deactivateGuard],
  children: [{
    path: '', redirectTo: Cargo_PathName.DASHBOARD, pathMatch: 'full'
  }, {
    path: Cargo_PathName.DASHBOARD, loadChildren: () => import('src/cargo/page/page-dashboard/page-dashboard.module').then(
      m => m.PageDashboardModule
    )
  }, {
    path: Cargo_PathName.FOUNDATION, loadChildren: () => import('src/cargo/page/page-dm-foundation/page-dm-foundation.module').then(
      m => m.PageDmFoundationModule
    )
  }, {
    path: Cargo_PathName.BOOKING_ORDER, loadChildren: () => import('src/cargo/page/page-bookingorder/page-bookingorder.module').then(
      m => m.PageBookingorderModule
    )
  }, {
    path: Cargo_PathName.CUSTOMER, loadChildren: () => import('src/cargo/page/page-customer/page-customer.module').then(
      m => m.PageCustomerModule
    )
  }, {
    path: Cargo_PathName.BO_STEP, loadChildren: () => import('src/cargo/page/page-bookingorder-step/page-bookingorder-step.module').then(
      m => m.PageBookingorderStepModule
    )
  }, {
    path: Cargo_PathName.WAREHOUSE, loadChildren: () => import('src/cargo/page/page-warehouse/page-warehouse.module').then(
      m => m.PageWarehouseModule
    )
  }]
}, {
  path: 'm', loadChildren: () => import('src/cargo/page/page-mobile/page-mobile.module').then(m => m.PageMobileModule)
}];
@NgModule({
  declarations: [
    PageCargoComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),

    NzLayoutModule,
    NzDrawerModule,

    PjButtonModule,
    PjSideMenuModule,
    PjTabGroupModule,

  ],
  exports: [
    PageCargoComponent
  ]
})
export class PageCargoModule { }
