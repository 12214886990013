import { inject, Injectable } from "@angular/core";
import { ActivatedRoute, NavigationStart, Params, Router } from "@angular/router";
import { isBoolean, isEmpty } from "lodash";
import { filter, map, Observable, take } from "rxjs";
import { APPCONSTANT } from "src/app/app.constant";
import { HelperService } from "./helper.service";
import { StorageService } from "./storage.service";


@Injectable({ providedIn: "root" })
export class RoutingService {
  private _localStorage = inject(StorageService);
  private _router: Router = inject(Router);

  navigationUrl(): Observable<any> {
    return this._router.events.pipe(
      map((event) => {
        return event instanceof NavigationStart ? event : null;
      }),
      filter((v) => v != null),
      map((event) => {
        return event?.url;
      }),
      take(1),
    );
  }

  gotoHomePage(): void {
    this.gotoUrl(PathName.HOME);
  }

  gotoLoginPage(redirectUrl?: string): void {
    let url = PathName.LOGIN;
    if (redirectUrl != null && !isEmpty(redirectUrl)) {
      url = url + `?${APPCONSTANT.URL_PARAMETER.REDIRECT_URL}=${HelperService.encodeToBase64(redirectUrl)}`
    }
    this.gotoUrl(url);
  }

  gotoUrlAfterLogin(redirectUrl: string): void {
    if (!isEmpty(redirectUrl)) {
      this._router.navigateByUrl(HelperService.decodeFromBase64(redirectUrl));
    } else {
      let url = this._localStorage.getLocalItem(APPCONSTANT.LOCAL_STORAGE_ITEM_NAME.LAST_USED_URL);
      if (!isEmpty(url)) {
        this._router.navigateByUrl(url);
      } else {
        if(HelperService.isMobile()) {
          this._router.navigateByUrl('m');
        } else {
          this._router.navigateByUrl('');
        }
      }
    }
  }
  gotoRegisterPage(redirectUrl?: string): void {
    if (!isEmpty(redirectUrl)) {
      this._router.navigate([
        PathName.REGISTER,
        { redirectUrl: HelperService.encodeToBase64(redirectUrl!) },
      ]);
    } else {
      this.gotoUrl(PathName.REGISTER);
    }
  }

  gotoResetPasswordPage(loginName: string): void {
    this.gotoUrl(PathName.RESET_PASSWORD.replace(":loginName", loginName));
  }

  gotoComingSoonPage(): void {
    this.gotoUrl(PathName.COMING_SOON);
  }

  gotoDocumentationPage(componentName: string): void {
    this.gotoUrl(PathName.PJ_DOCUMENT + "/" + componentName);
  }

  gotoUrl(url: string): void {
    this._router.navigateByUrl(url);
  }

  gotoDataManageUrl(url: string): void {
    let _url = PathName.DATA_MANAGE;
    if (!isEmpty(url)) {
      _url = `${PathName.DATA_MANAGE}/${url}`;
    }
    this._router.navigateByUrl(_url);
  }
  /**
   * 绝对路径跳转, 参数单独传
   */
  navigateToAbsoluteUrl(url: string, params: Params = {}): void {
    let curUrl = url;
    const paramsKey = Object.keys(params);
    if (paramsKey.length > 0) {
      curUrl += "?";
      curUrl += paramsKey.map((key) => `${key}=${params[key]}`).join("&");
    }
    this._router.navigateByUrl(curUrl);
  }

  goToRelativeUrl(
    commands: any[],
    relativeTo: ActivatedRoute,
    queryParams: Params = {},
  ): void {
    this._router.navigate(commands, { queryParams, relativeTo });
  }

  protected currentLink(): string {
    return location.href.substring(
      document.getElementsByTagName("base")[0].href.length,
    );
  }

  protected _generateBackUrl(): string {
    let urlLocation = this.currentLink();//location.pathname + location.search;
    let _backUrlParam = `${APPCONSTANT.URL_PARAMETER.BACK_URL}=${HelperService.encodeToBase64(urlLocation)}`;
    return _backUrlParam;
  }

  gotoDataManagePage(requestCode: string, domainCode: string, modelCode: string, id?: number, refresh?: boolean): void {
    let _backUrlParam = this._generateBackUrl();

    let url = `${PathName.DATA_MANAGE}/${requestCode}?domainCode=${domainCode}&modelCode=${modelCode}`;
    if (id != null) {
      url = `${url}&modelId=${id}`;
    }
    if (isBoolean(refresh) && refresh) {
      url = `${url}&refresh=true`;
    }
    url = `${url}&${_backUrlParam}`;
    this._router.navigateByUrl(url);
  }
}

export const PathName = {
  HOME: "",
  LOGIN: "login",
  LOGIN_NAME: "login/:loginName",
  EMAIL_LOGIN: "email-login",
  REGISTER: "register",
  RESET_PASSWORD_LINK: "reset-password",
  FORGOTPASSWORD: "forgot-password",
  RESET_PASSWORD: "reset-password/:loginName",
  NOT_FOUND: "error404",
  COMING_SOON: "coming-soon",
  MAINTENANCE: "maintenance",
  TEST: "test",
  PJ_DOCUMENT: "pjdoc",
  ERROR404: "**",
  // PJ_DOCUMENT_COMPONENT: "component/:componentName",

  // 数据管理的链接 QUES: 是否需要把所有的参数都base64化？
  // http://host:port/dm: 显示所有领域模型的侧边菜单，使用领域作为第一层菜单
  DATA_MANAGE: 'dm',
  // http://host:port/dm/list?domainCode=[模型所属领域的代码]&modelCode=[领域模型在后端的类全名]
  DATA_MAHAGE_LIST: 'list',
  // http://host:port/dm/detail?id=[领域模型的第几个数据]&domainCode=[模型所属领域的代码]&modelCode=[领域模型在后端的类全名]
  DATA_MANAGE_DETAIL: 'detail',
  // http://host:port/dm/edit?id=[领域模型的第几个数据]&domainCode=[模型所属领域的代码]&modelCode=[领域模型在后端的类全名]
  DATA_MANAGE_EDIT: 'edit',
  // http://host:port/dm/create?id=[领域模型的第几个数据]&domainCode=[模型所属领域的代码]&modelCode=[领域模型在后端的类全名]
  // 当id值存在的时候，使用该值获取对应的模型数据后用以复制后创建数据
  DATA_MANAGE_CREATE: 'create'
};

