import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule, provideHttpClient } from '@angular/common/http';
import zh from '@angular/common/locales/zh';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { RouterModule, Routes } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NZ_I18N, zh_CN } from 'ng-zorro-antd/i18n';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { environment } from 'src/environments/environment';
import { PageAuthModule } from 'src/page/page-auth/page-auth.module';
import { PageError404Component } from 'src/page/page-util/page-error-404/page-error-404.component';
import { PageUtilModule } from 'src/page/page-util/page-util.module';
import { activeGuard, deactivateGuard } from 'src/service/polarj.guard';
import { PathName } from 'src/service/routing.service';
import { AppComponent } from './app.component';

export function httpLoaderFactory(httpClient: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}
registerLocaleData(zh);
const routes: Routes = [{
  path: '', pathMatch: 'full', redirectTo: environment.mainComponentPath
}, {
  path: PathName.TEST, canActivate: [activeGuard], canDeactivate: [deactivateGuard],
  loadChildren: () => import('src/page/page-test/page-test.module').then(m => m.PageTestModule)
}, {
  path: PathName.DATA_MANAGE, canActivate: [activeGuard], canDeactivate: [deactivateGuard],
  loadChildren: () => import('src/page/page-md-dm/page-md-dm.module').then(m => m.PageMdDmModule)
}, {
  path: PathName.ERROR404, component: PageError404Component
}];
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
    }),

    NzTypographyModule,

    PageAuthModule,

    PageUtilModule,

    environment.projectModule
  ],
  providers: [
    { provide: NZ_I18N, useValue: zh_CN },
    provideHttpClient(),
    provideAnimationsAsync(),
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
