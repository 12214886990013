import { isBoolean } from "lodash";
import { NzMessageService } from "ng-zorro-antd/message";
import { NzModalService } from "ng-zorro-antd/modal";
import { DomainModelServiceHttpImpl } from "src/service/domainmodel.service.httpimpl";
import { HelperService } from "src/service/helper.service";
import { ServerResponse } from "src/service/httpcall.type";
import { DataResourceOperation } from "./authentication.model";
import { I18nResource } from "./i18nresource.model";
import { BackendPersistenceEntity } from "./generated/persistence-entity.backend.model";

// FIXME: 应该在元数据中旧设置为编辑隐藏？
export const FieldsRemovedForForm: string[] = [
  'id', 'createdDate', 'createdById', 'createdBy', 'updatedDate', 'updatedById', 'updatedBy',
  'deleted', 'deletedById', 'deletedDate', 'pageIndex', 'pageSize', 'sorts', 'smartSearchText',
  'i18nResources', 'operator', 'searchOptionMap', 'simpleUpdate'
];
// FIXME: 应该在元数据中旧设置为显示隐藏？
export const FieldsRemovedForDetail: string[] = [
  'id', 'createdDate', 'createdById', 'createdBy', 'updatedDate', 'updatedById', 'updatedBy',
  'deleted', 'deletedById', 'deletedDate', 'pageIndex', 'pageSize', 'sorts', 'smartSearchText',
  'i18nResources', 'operator', 'searchOptionMap', 'simpleUpdate'
];
// FIXME: 应该在元数据中旧设置为表格隐藏？
export const FieldsRemovedForTable: string[] = [
  'id', 'createdDate', 'createdById', 'createdBy', 'updatedDate', 'updatedById', 'updatedBy',
  'deleted', 'deletedById', 'deletedDate', 'pageIndex', 'pageSize', 'sorts', 'smartSearchText',
  'i18nResources', 'operator', 'searchOptionMap', 'simpleUpdate'
];

export class EnumValuesForSearching {
  fieldName?: string;
  values?: string;
}

export class RangeValueForSearching {
  fieldName?: string;
  fromValue?: string;
  toValue?: string;
}

export class PersistenceEntity extends BackendPersistenceEntity{

  // constructor() { }
  // id?: number;
  // createdDate?: Date;
  // createdById?: number;
  // createdBy?: string;
  // updatedDate?: Date;
  // updatedById?: number;
  // updatedBy?: string;
  // deleted?: boolean;
  // deletedById?: number;
  // deletedDate?: Date;
  // languageId?: string;
  // pageIndex?: number;
  // pageSize?: number;
  // sorts?: string[];
  // smartSearchText?: string;
  // i18nResources?: I18nResource[];
  // operator?: BackendCoreCasUser;
  operator?: any;
  // searchOptionMap?: Map<string, string>;   // <字段名， 查询类型<LIKE, MATCH>>
  simpleUpdate?: boolean;

  rangeValues?: RangeValueForSearching[];
  enumValuesForSearching?: EnumValuesForSearching[];
  // valueRemovedFields?: string[];

  // TODO: 从已有的代码中迁移过来，暂时不知道存在原因
  orderByField?: string;
  desc?: boolean;
  // TODO: 从已有的代码中迁移过来，暂时不知道存在原因

  //保存到本地和上传修改内容的时候，如下内容是不必要的。
  static removeUselessFieldForLocalStorageAndSubmission(entity?: PersistenceEntity): boolean {
    if (entity == null) {
      return true;
    }
    delete entity.pageIndex;
    delete entity.pageSize;
    delete entity.createdBy;
    delete entity.createdById;
    delete entity.createdDate;
    delete entity.updatedBy;
    delete entity.updatedById;
    delete entity.updatedDate;
    delete entity.deletedById;
    delete entity.deletedDate;
    return PersistenceEntity.removeFieldWithoutValue(entity);
  }

  // 删除属性值为 undefined 的属性。FIXME: 数组部分还没有测试
  static removeFieldWithoutValue(data: any): boolean {
    let totalField: number = 0;
    let totalDeleted: number = 0;
    for (let field in data) {
      totalField = totalField + 1;
      if (!HelperService.isPrimitive(data[field]) && !HelperService.isDate(data[field])) {
        if (Array.isArray(data[field])) {
          let willKeptData = [];
          for (let elm of data[field]) {
            if (!PersistenceEntity.removeUselessFieldForLocalStorageAndSubmission(elm)) {
              willKeptData.push(elm);
            }
          }
          if (willKeptData.length == 0) {
            totalDeleted = totalDeleted + 1;
          } else {
            data[field] = willKeptData;
          }
        } else {
          if (PersistenceEntity.removeUselessFieldForLocalStorageAndSubmission(data[field])) {
            delete data[field];
            totalDeleted = totalDeleted + 1;
          }
        }
      } else {
        if (data[field] === undefined) {
          delete data[field];
          totalDeleted = totalDeleted + 1;
        }
      }
    }
    return (totalDeleted == totalField) ? true : false;
  }

  static removeBasicAttrValue(entity?: PersistenceEntity): PersistenceEntity | undefined {
    if (entity != null) {
      delete entity.pageIndex;
      delete entity.pageSize;
      delete entity.createdDate;
      delete entity.updatedDate;
      delete entity.deletedDate;
    }
    return entity;
  }

  static isPredefinedOperation(operationCode: string): boolean {
    let keys = Object.keys(EntityActionString);
    let preDefined = false;
    keys.forEach(key => {
      if ((EntityActionString as any)[key] == `action-${operationCode}`) {
        preDefined = true;
      }
    });
    return preDefined;
  }

  static actionForDelete<T extends PersistenceEntity>(
    modelService: DomainModelServiceHttpImpl<T>, modelId: number,
    modalWinService: NzModalService, confirmDesc: string | undefined,
    messageService: NzMessageService, successMessage: string | undefined, errorMessage: string | undefined,
    processAfterDeletion?: () => void): void {
    modalWinService.confirm({
      nzTitle: '<b>Delete</b>',
      nzContent: confirmDesc || 'Remove the data.',
      nzOkText: 'Yes',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => {
        modelService.deleteById(modelId).pipe(
        ).subscribe(res => {
          if (!Object.prototype.toString.call(res).includes('Boolean')) {
            let errorMsg = ServerResponse.getFirstErrorMessage(res as unknown as ServerResponse<any>);
            if (errorMsg != null) {
              messageService.error(errorMsg || errorMessage || 'Has System error, Can not remove the data.');
            }
          } else {
            if (isBoolean(res) && res) {
              messageService.success(successMessage || 'Remove the data successfully.');
            } else {
              messageService.error(errorMessage || 'Can not remove the data.');
            }
            if (processAfterDeletion != null) {
              processAfterDeletion();
            }
          }
        });
      },
      nzCancelText: 'No',
      nzOnCancel: () => console.log('Cancel')
    });
  }

  static actionForSaving<T extends PersistenceEntity>(modelService: DomainModelServiceHttpImpl<T>, entity: T,
    modalWinService: NzModalService, confirmDesc: string | undefined,
    messageService: NzMessageService, successMessage: string | undefined, errorMessage: string | undefined,
    processAfterSaving?: (savedEntity?: T) => void): void {
    modalWinService.confirm({
      nzTitle: '<b>Confirmation</b>',
      nzContent: confirmDesc || 'All updates will be saved.',
      nzOkText: 'Yes',
      nzOkType: 'primary',
      nzOnOk: () => {
        modelService.save(entity).pipe(
        ).subscribe(entity => {
          if (entity == null || entity.id == null) {
            // 说明保存有错
            messageService.error(errorMessage || 'Has System error, Can not save the data.');
            console.log(entity);
          } else {
            messageService.success(successMessage || 'Save the data successfully.');
          }
          if (processAfterSaving != null) {
            processAfterSaving(entity);
          }
        });
      },
      nzCancelText: 'No',
      nzOnCancel: () => console.log('Cancel')
    });
  }
}

export const EntityCRUDConstMap = {
  list: 'list', detail: 'detail', create: 'create', edit: 'edit'
}
export type EntityCRUDType = keyof typeof EntityCRUDConstMap;
export const EntityCRUDTypeConst = Object.keys(EntityCRUDConstMap);

export const EntityActionString = {
  EDIT: 'action-edit',
  DELETE: 'action-delete',
  VIEW: 'action-view',
  PRINT: 'action-print',
  REASSIGN_OWNER: 'action-reassign-owner',

  CREATE: 'action-create',
  DOWNLOAD_TEMPLATE: 'action-download-template',
  DELETE_SELECTED: 'action-delete-selected',
  PRINT_SELECTED: 'action-print-selected',
  DOWNLOAD_ALL: 'action-download-all',
  DOWNLOAD_SELECTED: 'action-download-selected',
  UPLOAD: 'action-upload',

  ADVANCE_SEARCH: 'action-advance-search',
  SMART_SEARCH: 'action-smart-search',
};

export const EntityAction = {
  CREATE: { label: '新增', actionString: EntityActionString.CREATE, iconName: 'add' },
  ADD: { label: '新增', actionString: EntityActionString.CREATE, iconName: 'add' },
  NEW: { label: '新增', actionString: EntityActionString.CREATE, iconName: 'add_box' },
  EDIT: { label: '修改', actionString: EntityActionString.EDIT, iconName: 'edit' },
  UPDATE: { label: '修改', actionString: EntityActionString.EDIT, iconName: 'update' },
  DELETE: { label: '删除', actionString: EntityActionString.DELETE, iconName: 'delete' },
  REMOVE: { label: '删除', actionString: EntityActionString.DELETE, iconName: 'remove' },
  VIEW: { label: '查看', actionString: EntityActionString.VIEW, iconName: 'info' },
  DETAIL: { label: '详情', actionString: EntityActionString.VIEW, iconName: 'info_i' },

  PRINT: { label: '打印', actionString: EntityActionString.PRINT, iconName: 'print' },

  PRINT_SELECTED: { label: '打印选中数据', actionString: EntityActionString.PRINT_SELECTED, iconName: 'print' },
  DELETE_SELECTED: { label: '删除选中数据', actionString: EntityActionString.DELETE_SELECTED, iconName: 'delete' },
  DOWNLOAD_TEMPLATE: { label: '下载数据模板', actionString: EntityActionString.DOWNLOAD_TEMPLATE, iconName: 'download' },
  DOWNLOAD_ALL: { label: '下载当前页全部数据', actionString: EntityActionString.DOWNLOAD_ALL, iconName: 'cloud_download' },
  DOWNLOAD_SELECTED: { label: '下载选中数据', actionString: EntityActionString.DOWNLOAD_SELECTED, iconName: 'cloud_download' },
  UPLOAD: { label: '使用模板上传', actionString: EntityActionString.UPLOAD, iconName: 'cloud_upload' },
  REASSIGN_OWNER: { label: '重置数据所有人', actionString: EntityActionString.UPLOAD, iconName: 'assignment_ind' },
};

export interface EntityManagementConfig {
  tableFields: string[],
  creatableFields: string[],
  updatableFields: string[],
  viewableFields: string[],
  classOp?: DataResourceOperation[],
  instanceOp?: DataResourceOperation[]
}