import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { PjButtonModule } from '../pj-button/pj-button.module';
import { PjDividerModule } from '../pj-divider/pj-divider.module';
import { PjBtnTabGroupComponent } from './pj-btn-tab-group/pj-btn-tab-group.component';
import { PjTabGroupComponent } from './pj-tab-group.component';
import { PjTabItemContentDirective } from './pj-tab-item.directive';


@NgModule({
  declarations: [
    PjTabGroupComponent,
    PjBtnTabGroupComponent,
    PjTabItemContentDirective
  ],
  imports: [
    CommonModule,

    NzTabsModule,
    PjButtonModule,
    PjDividerModule
  ],
  exports: [
    PjTabGroupComponent,
    PjBtnTabGroupComponent,
    PjTabItemContentDirective
  ]
})
export class PjTabGroupModule { }
