import { Component, Input } from '@angular/core';
import { interval } from 'rxjs';
import { PjStdButtonComponent } from '../pj-std-button/pj-std-button.component';

@Component({
  selector: 'pj-countdown-button',
  templateUrl: './pj-countdown-button.component.html',
  styleUrl: './pj-countdown-button.component.less'
})
export class PjCountdownButtonComponent extends PjStdButtonComponent {

  @Input()
  intervalTime: number = 60;

  @Input()
  disabledAtBegin: boolean = true;

  private _label?: string;

  override ngOnInit(): void {
    this._label = this.label;
    if(this.disabledAtBegin) {
      this.onBtnClick();
    }
  }

  override onBtnClick(): void {
    let count: number = 0;
    this.disabled = true;
    this._label = (this.intervalTime - count) + '';
    let subscription = interval(1000).pipe(
    ).subscribe(() => {
      count ++;
      this._label = (this.intervalTime - count) + '';
      if(count >= this.intervalTime) {
        subscription.unsubscribe();
        this.disabled = false;
        this._label = this.label;
      }
    });
  }

  getLabel(): string | undefined {
    return this._label;
  }
}
