import { PjKeyValue } from 'src/component/component.type';
import { PjTabItemData } from 'src/component/pj-tab-group/pj-tab-item.type';
import { FrontModelMetaData } from './front-model-metadata';
import { BackendFieldFrontendSpecification } from './generated/field-frontend-specification.backend.model';

export enum BackendSearchOptionEnum {
  EQUALS = "EQUALS",
  LIKE = "LIKE",
  IN = "IN",
  BEFORE = "BEFORE",
  AFTER = "AFTER",
  LT = "LT",
  GT = "GT",
}

export enum SearchTypeEnum {
  VALUE = 'value',
  RANGE = 'range',
}

export enum BackendDataTypeEnum {
  'string' = 'String',
  'boolean' = 'Boolean',
  'number' = 'Integer',
  'date' = 'Date',
  'decimal' = 'Double',
  'object' = 'Object',
  'array' = 'Array',
  'none' = 'Void'
}
// 对应后端的 FieldFrontendDataType 自定义枚举类
// 数据类型主要是为了输入和显示的正确性
// 针对的是基础类型下的分类（主要是字符串和日期的分类）
// 
export enum DataTypeEnum {
  // Java的Boolean类型自动对应boolean
  'boolean' = 'boolean',

  // Java的所有数字类型自动对应number
  'number' = 'number',

  // 如果是Java的String类型，可以选以下数据类型之一，缺省为string
  'string' = 'string',
  'password' = 'password',
  'phone' = 'phone',
  'email' = 'email',

  // 如果是Java的Date类型，必须是如下三种数据类型之一
  'date' = 'date',
  'time' = 'time',
  'dateTime' = 'dateTime',

  'object' = 'object',
  'file' = 'file',
  'image' = 'image',
  // 以下是object根据 genericType 确定的数据类型
  // 'money' = 'money',
  // 'numberRange' = 'numberRange',
  // 如果是嵌入式类型DateRange，需要在dataType属性中
  'dateRange' = 'dateRange',
  'timeRange' = 'timeRange',
  'dateTimeRange' = 'dateTimeRange',

  // Java的List类型，自动对应array，genericType必填
  'array' = 'array',
  'none' = 'none'
}

export type DataType = keyof typeof DataTypeEnum;

export type Formula = 'product' | 'sum'; //

export enum FormatterType {
  pluck = 'pluck',
  mask = 'mask',
  datetime = 'datetime',
  number = 'number',
}

export const FormatterTypeConst: string[] = [FormatterType.pluck, FormatterType.mask, FormatterType.datetime, FormatterType.number];

// field (字段) 元数据，描述前端应该如何渲染这个字段
export class FrontFieldMetaData extends BackendFieldFrontendSpecification {
  children?: PjKeyValue<FrontFieldMetaData>; // 子 field 元数据
  modelMetaData?: FrontModelMetaData; // 该字段的模型元数据

  // 上层属性的fieldName（该元数据是属性的属性的元数据）
  // 如果为空，则是模型的第一层属性，格式为 fieldName.fieldName.
  // 拼接当前的fieldName得到访问相应属性值的键值
  // 该值在获取属性元数据时填写
  parentFieldName?: string;

  // 搜索选择框是一次性读取所有供选择的项，还是分页读取供选择的内容？ 
  // FIXME: 没有在已有的元数据中找到相应的属性
  // pageSelection?: boolean;

  // 该属性是boolean值，当其为真时，booleanForField 指定的属性才需要编辑。
  booleanForField?: string;


  searchInputComponent?: string;

  static fieldMFSort(fMD1: FrontFieldMetaData, fMD2: FrontFieldMetaData): number {
    if (fMD1 == null || fMD2 == null) {
      return 0;
    }
    if (fMD1.position == null || fMD2.position == null) {
      return 0;
    }
    return fMD1.position - fMD2.position;
  }
}

export class FieldMetaAndValue extends FrontFieldMetaData {
  value: any;
}

export interface PjTabItemDataForMD extends PjTabItemData {
  fieldName: string;
  fieldMD?: FrontFieldMetaData;
  modelMD?: FrontModelMetaData;
  fieldSpecs: FrontFieldMetaData[];
}
