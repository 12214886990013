import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NzInputModule } from 'ng-zorro-antd/input';
import { PjVerificationCodeComponent } from './pj-verification-code.component';
import { PjIconModule } from 'src/component/pj-icon/pj-icon.module';
import { PjButtonModule } from 'src/component/pj-button/pj-button.module';



@NgModule({
  declarations: [
    PjVerificationCodeComponent
  ],
  imports: [
    CommonModule,

    FormsModule,

    NzInputModule,

    PjIconModule,
    PjButtonModule
  ],
  exports: [
    PjVerificationCodeComponent
  ]
})
export class PjVerificationCodeModule { }
