<div class="flex flex-col items-center gap-2 justify-center">
  <div class="p-4 inline-flex">
    <img style="width: 382px" [src]="getImageUrl()" />
  </div>
  <div class="flex flex-col gap-4 p-8 shadow-lg border-solid rounded-lg border">
    <h3 nz-typography class="self-start">{{getTitle()}}</h3>
    <form nz-form [formGroup]="validateForm" class="flex flex-col gap-2 w-[320px]">
      <nz-form-item>
        <nz-form-control nzErrorTip="Please input your username!">
          <pj-text-input iconName="person" placeHolder="Username" formControlName="username">
          </pj-text-input>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control nzErrorTip="Please input your Password!">
          <pj-text-input [isPassword]="true" iconName="lock" placeHolder="Password" formControlName="password">
          </pj-text-input>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control nzErrorTip="Please input your verification code!" [nzValidateStatus]="getTheValidateStatus()">
          <pj-verification-code formControlName="verifyCode" (codeRequest)="onFetchVerificationCodeBtnClick()">
          </pj-verification-code>
        </nz-form-control>
      </nz-form-item>
      <div class="flex flex-row items-center justify-between">
        <label nz-checkbox formControlName="remember">
          <span>Remember me</span>
        </label>
        <pj-std-button btnType="link" label="Forgot password"></pj-std-button>
      </div>
      <pj-std-button class="self-center w-1/2" label="Log in" btnType="filled" (click)="submitForm()"></pj-std-button>
      <div class="self-center w-1/2 flex flex-row items-center">
        <div>Or</div>
        <pj-std-button btnType="link" label="Register now!"></pj-std-button>
      </div>
    </form>
  </div>
</div>

<ng-template #passwordIcon>
  <pj-icon class="icon" iconName="lock"></pj-icon>
</ng-template>