import { Component, Input } from '@angular/core';

@Component({
  selector: 'pj-icon',
  templateUrl: './pj-icon.component.html',
  styleUrl: './pj-icon.component.less'
})
export class PjIconComponent {
  @Input()
  iconName: string = '';

  private _iconName: string = '';
  private _iconType: 'round' | 'outlined' = 'outlined';

  ngOnChanges(): void {
    if (this.iconName.includes('.')) {
      let dotPos = this.iconName.indexOf('.');
      this._iconName = this.iconName.substring(0, dotPos);
      let iconType = this.iconName.substring(dotPos + 1);
      this._iconType = 'outlined';
      if (iconType != 'outlined') {
        this._iconType = 'round';
      }
    } else {
      this._iconName = this.iconName;
      this._iconType = 'outlined';
    }
  }
  getIconName(): string {
    return this._iconName;
  }

  getIconType(): string {
    return this._iconType;
  }
}
