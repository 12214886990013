import { PageCargoModule } from "src/cargo/page/page-cargo.module";
import { Cargo_PathName } from "src/cargo/service/cargo.routing.service";
import { Environment } from "src/environments/environment.interface";

export const environment: Environment = {
  projectModule: PageCargoModule,
  mainComponentPath: `${Cargo_PathName.CARGO}/${Cargo_PathName.DASHBOARD}`,

  production: true, // 开启生产模式
  enableLog: true, // 是否使用console.log显示调试信息
  enableNav: true, // 允许 导航栏
  enableFooter: false, // 允许 底边栏
  // 是否允许匿名用户使用，如果允许匿名用户，直接进到首页，否则去到登录界面
  enableAnonymous: true,
  loadLocalData: true,
  enableCaptcha: false,

  enableGZipForLocalStorage: false,

  logo: '',

  enablePolarJComponentDoc: false,
  enableMetaDataManager: true,

  hardCodeEmail: 'jinzhu.liang@lucky-way.com',
  hardCodePassword: 'asdasd123',
  hardCodeVerificationCode: 'asd'
};
