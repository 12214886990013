import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PjDividerComponent } from './pj-divider.component';



@NgModule({
  declarations: [
    PjDividerComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    PjDividerComponent
  ]
})
export class PjDividerModule { }
