import { PersistenceEntity } from 'src/model/persistence-entity';



export class BackendCasRole extends PersistenceEntity {
  roleCode?: string;
  roleName?: string;
  description?: string;
  status?: string;
}
