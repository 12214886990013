import { PersistenceEntity } from 'src/model/persistence-entity';



export class BackendCasUser extends PersistenceEntity {
  userCode?: string;
  username?: string;
  email?: string;
  phone?: string;
  passwordHash?: string;
  salt?: string;
  status?: string;
  systemUser?: boolean;
  /** This field is transient on the backend. */
  token?: string;
  /** This field is transient on the backend. */
  verifyCode?: string;
  /** This field is transient on the backend. */
  encryptedEmail?: string;
  /** This field is transient on the backend. */
  encryptedVerifyCode?: string;
  /** This field is transient on the backend. */
  password?: string;
  /** This field is transient on the backend. */
  newPassword?: string;
}
